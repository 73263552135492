import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import { updateList } from "helpers/ListHelpers";
import { EstimatorsReducer } from "./estimators-reducer";
import {
  ESTIMATES_REQUEST, ESTIMATES_REQUEST_SUCCESS, ALL_ESTIMATES_REQUEST_SUCCESS,
  ESTIMATES_REQUEST_FAILURE, SELECT_ESTIMATE, RESET_SELECTED_ESTIMATE
} from '../actions/estimates-actions';
import {
  UPDATE_JOB_REQUEST, UPDATE_JOB_REQUEST_SUCCESS, UPDATE_JOB_REQUEST_FAILURE
} from "../actions/jobs-actions";


const INITIAL_STATE = { list: [], all: [], selected: {}, error: null, loading: false };

export const EstimatesReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case ESTIMATES_REQUEST:
      return {...state, selected: {}, loading: true};
    case ESTIMATES_REQUEST_SUCCESS:
      return {...state, list: action.payload.jobs, loading: false};
    case ALL_ESTIMATES_REQUEST_SUCCESS:
      return {...state, all: action.payload.jobs, loading: false};
    case ESTIMATES_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message}; //2nd one is network or server down errors
      return {...state, estimates: {...state.estimates, error: error, loading: false}};

    case SELECT_ESTIMATE:
      return {...state, selected: action.payload};
    case RESET_SELECTED_ESTIMATE:
      return {...state, selected: {}};

    case UPDATE_JOB_REQUEST:
      return {...state};
    case UPDATE_JOB_REQUEST_SUCCESS:
      return {...state, list: updateList(state.list, action.payload), error:null, loading: false};
    case UPDATE_JOB_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};

    default:
      return state;
  }
};

const EstimatesComponentReducer = combineReducers({
  estimates: EstimatesReducer,
  sidebarData: EstimatorsReducer,
  form: reduxFormReducer
});

export default EstimatesComponentReducer

