import {
  OPEN_USER_UNAVAILABLE_MODAL, CLOSE_USER_UNAVAILABLE_MODAL, USERS_UNAVAILABLE_LIST_REQUEST,
  USERS_UNAVAILABLE_LIST_REQUEST_SUCCESS, USERS_UNAVAILABLE_LIST_REQUEST_FAILURE,
  ADD_USER_UNAVAILABLE_REQUEST, ADD_USER_UNAVAILABLE_SUCCESS, ADD_USER_UNAVAILABLE_FAILURE
} from "actions/user-not-available";
import { addElementToList } from "helpers/ListHelpers";

const INITIAL_STATE = { list: [], showModal: false, error: null, loading: false };

export const UserUnavailableReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case OPEN_USER_UNAVAILABLE_MODAL:
      return { ...state, showModal: true };
    case CLOSE_USER_UNAVAILABLE_MODAL:
      return { ...state, showModal: false };

    case USERS_UNAVAILABLE_LIST_REQUEST:
      return { ...state, loading: true };
    case USERS_UNAVAILABLE_LIST_REQUEST_SUCCESS:
      return { ...state, list: action.payload };
    case USERS_UNAVAILABLE_LIST_REQUEST_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: error, loading: false };

    case ADD_USER_UNAVAILABLE_REQUEST:
      return { ...state, loading: true };
    case ADD_USER_UNAVAILABLE_SUCCESS:
      return { ...state, list: addElementToList(state.list, action.payload), showModal: false, loading: false };
    case ADD_USER_UNAVAILABLE_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: error, showModal: true, loading: false };

    default:
      return state;
  }
};
