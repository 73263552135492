export const validate = values => {
  const required = 'Required';
  const errors = {};
  if (!values.estimator) {
    errors.estimator = required
  }
  if (!values.note) {
    errors.note = required
  }
  if (!values.appointmentDate) {
    errors.appointmentDate = required
  }
  if (!values.timeFrom) {
    errors.timeFrom = required
  }
  if (!values.timeTo ) {
    errors.timeTo = required
  }

  if (values.follow_up) {
    if (!values.follow_up_estimator) {
      errors.follow_up_estimator = required
    }
    if (!values.follow_up_note) {
      errors.follow_up_note = required
    }
    if (!values.follow_up_appointmentDate) {
      errors.follow_up_appointmentDate = required
    }
    if (!values.follow_up_timeFrom) {
      errors.follow_up_timeFrom = required
    }
    if (!values.follow_up_timeTo ) {
      errors.follow_up_timeTo = required
    }

  }
  return errors
};
