import { createStore, compose } from "redux"

import CalendarReducer from "reducers/calendar-reducers"
import configureMiddleware from "store/configure-middleware";

const configureCalendarStore = preloadedState => {
  return createStore(
    CalendarReducer,
    preloadedState,
    compose(
      configureMiddleware()
    )
  );
};

export default configureCalendarStore
