import React from "react";
import PropTypes from 'prop-types';
import { Row, Col, Alert, Well } from 'react-bootstrap';

import { renderAppointmentTime, convertDateTimeToStringFormat, timeFromNow } from "helpers/DateTimeHelpers";
import { BorderedWrapper } from "shared/bordered-wrapper";
import { AddressMapComponent } from 'shared/map-component'
import { DescriptionRow } from "shared/description-row";
import { JobHeader } from "./job-header";
import { Address } from "./address";
import { SoldDetails } from "job-show/sold-details";
import { selectOptionOrOther } from "helpers/ViewHelpers";
import { googleCalendarEventEditPath, convertStringAsUrlPart, URL_NEW_LINE_SIGN } from "helpers/PathHelpers";
import { CallbackActive } from "job-show/callback-active";
import { addressToString } from "actions/addresses";

const CHARP_REPLACE_SIGN = '+';

export class JobInfo extends React.Component {

  generateJobDescription(job) {
    const typeOfBuilding = selectOptionOrOther('building_type', job);
    const numberOfStories = job.stories_count && job.stories_count.name;
    const roofAccess = selectOptionOrOther('roof_access', job);
    const typeOfRoof = selectOptionOrOther('roof_type', job);
    const typeOfJob = selectOptionOrOther('job_type', job);

    return (
      <div>
        <b>{typeOfJob}</b> job on <b>{typeOfRoof}</b> roof in <b>{typeOfBuilding}</b> building with <b>{numberOfStories}</b> stories (<b>{roofAccess}</b> roof access)
      </div>
    );
  }

  generateReplacedCharpSignString(str, newSign = CHARP_REPLACE_SIGN) {
    return str.replace(/\#/g, newSign);
  }

  generateGoogleCalendarURLDetailsValue(notes) {
    if (!notes) return '';

    return notes.map((note) => {
      return `${this.generateReplacedCharpSignString(note.note)}+(${note.note_type}+${note.created_at})${URL_NEW_LINE_SIGN}${URL_NEW_LINE_SIGN}`;
    }).join('')
  }

  generateURLForGoogleCalendar(job, notes) {
    const address = job.address;
    const date = new Date(job.date_estimated).getTime();
    const text = `${job.client.name}+estimate`;
    const location = convertStringAsUrlPart(addressToString(address));

    return `${googleCalendarEventEditPath(text, date, location, this.generateGoogleCalendarURLDetailsValue(notes))}`;
  }

  renderJobDescription(job, notes) {
    return(
      <Row>
        <Col md={12}>
          {
            job.roof_access && job.roof_access.note &&
            <Col md={12}>
              <Well>
                <Alert bsStyle="warning">
                  <b>{job.roof_access.note}</b>
                </Alert>
              </Well>
            </Col>
          }
          <p>
            {
              job.callback && (
                <Well>
                  <div>
                    Original job called in {job.job && convertDateTimeToStringFormat(job.job.created_at)}
                  </div>
                </Well>)
            }
          </p>
          <p>{ job.lost_reason && (
            <Well>
              <div>
                Lost reason <b>{job.lost_reason.name}</b>
              </div>
            </Well>)
          }
          </p>
        </Col>

        <Col md={12}>
          <Well>
            <div className='main-job-description'>
              { this.generateJobDescription(job) }
            </div>
          </Well>
        </Col>

        <Col md={12}>
          <Well>
            <Address
              address={job.address_same_as_contact_location ? job.client.address : job.address}
              asContactLocation={job.address_same_as_contact_location}
              showInline
            />
          </Well>
        </Col>

        <Col md={12}>
          <Well>
            <p>Called in <b>{ convertDateTimeToStringFormat(job.created_at) }</b> ({ timeFromNow(job.created_at) })</p>
            { job.user &&  <p>Estimator { job.user.name }</p> }
            { job.appointment_date && <p>Appointment for <b>{ convertDateTimeToStringFormat(renderAppointmentTime(job)) }</b></p> }
            {
              job.date_estimated &&
              <div>
                Estimate sent on <b>{convertDateTimeToStringFormat(job.date_estimated)}</b> <p>
                  <a target='_blank' href={this.generateURLForGoogleCalendar(job, notes)}>
                    Add to Google Calendar
                  </a>
                </p>
              </div>
            }
          </Well>
        </Col>
      </Row>
    )
  }

  render() {
    const { job, className, jobSold, notesData } = this.props;
    const notes = notesData.notes.list;
    const soldDetails = jobSold.soldDetails;

    return (
      <BorderedWrapper className={className}>
        <Row>
          <Col md={12}>
            <JobHeader {...this.props} />
          </Col>
        </Row>
        <CallbackActive {...this.props} />
        <Row>
          <Col md={6}>
            {this.renderJobDescription(job, notes)}
          </Col>
          <Col md={6}>
            {
              job.address && (
                <AddressMapComponent
                  lat={job.address.lat}
                  lng={job.address.lng}
                />
              )
            }
          </Col>
        </Row>
        { soldDetails &&
          <SoldDetails soldDetails={ soldDetails } /> }
      </BorderedWrapper>
    )
  }
}

JobInfo.propTypes = {
  job: PropTypes.object.isRequired
};
