import React from "react";
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'

import { renderTimePicker } from "./render-field";

import { DEFAULT_TIME_INTERVAL } from "helpers/DateTimeHelpers";

export const AppointmentTimeInputs = props => {
  const { disableFields, namePrefix } = props;
  const size = props.size || 6;
  const timeToField = `${namePrefix}timeTo`
  return (
   <div>
     <Col md={size}>
       <Field
          label="Time"
          name={`${namePrefix}timeFrom`}
          disabled={disableFields}
          onChange={(_, value) => props.appointmentTimeFrame(value, timeToField)}
          step={DEFAULT_TIME_INTERVAL}
          component={renderTimePicker}
       />
     </Col>
     <Col md={size}>
       <Field label="To" name={timeToField} disabled={disableFields} component={renderTimePicker} />
     </Col>
   </div>
  )
};


AppointmentTimeInputs.defaultProps = {
  namePrefix: '',
  disableFields: false
};


