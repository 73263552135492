import React from "react";
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { ELASTICSEARCH_MAX_RESPONSE, PER_PAGE_DEFAULT } from "helpers/AppConstants";

export class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
  }

  onPageChange(data) {
    const { onPageChange } = this.props;
    const page = data.selected + 1;
    onPageChange(page)
  }

  totalPages(paginationData) {
    if (paginationData.total_count <= ELASTICSEARCH_MAX_RESPONSE) return paginationData.total_pages;
    return Math.floor(ELASTICSEARCH_MAX_RESPONSE / paginationData.per_page)
  }

  render() {
    const { paginationData, hide } = this.props;
    const showPagination = paginationData.total_pages > 1 && !hide;
    const paginationComponent = (
      <div className="text-center">
        <ReactPaginate
          breakLabel={<a href="javascript:void(0)">...</a>}
          pageCount={this.totalPages(paginationData)}
          forcePage={paginationData.current_page - 1}
          marginPagesDisplayed={4}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName={"pagination margin-top-0"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
    return showPagination && paginationComponent
  }
}

Pagination.defaultProps = {
  hide: false,
  paginationData: {
    total_count: 0,
    total_pages: 1,
    current_page: 1,
    per_page: PER_PAGE_DEFAULT,
  },
  onPageChange: () => {}
};

Pagination.propTypes = {
  hide: PropTypes.bool.isRequired,
  paginationData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired
};

