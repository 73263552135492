import React from "react";
import { Provider } from "react-redux";

import configureDashboardStore from "store/configureDashboardStore";
import DashboardComponent from 'containers/DashboardContainer'

const store = configureDashboardStore();

class Dashboard extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <DashboardComponent />
      </Provider>
    )
  }
}
export default Dashboard;
