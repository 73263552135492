import React from "react";
import moment from 'moment';
import { Button, ButtonToolbar, ButtonGroup, Row, Col } from 'react-bootstrap'
import Modal from 'react-responsive-modal';
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form';
import { connect } from 'react-redux';

import { JOB_SOLD_FORM_NAME } from "helpers/FormNames"
import { validate } from 'form-validations/validation-job-sold-form'
import { RenderField, RenderSelect, RenderTextArea, renderDatePicker } from "shared/render-field";
import { findItemById } from "helpers/ListHelpers";
import { addMaterial, removeMaterial, changeMaterialBrand } from "actions/brand-series-actions";
import { estimators } from "helpers/User";
import SelectFieldWithSpecifyInput from "shared/select-field-with-specify-input";

class JobSoldModal extends React.Component {
  constructor(props) {
    super(props);
    this.getMaterialsBrandSeries = this.getMaterialsBrandSeries.bind(this);
    this.getBrandSeries = this.getBrandSeries.bind(this);
    this.onBrandSelect = this.onBrandSelect.bind(this);
    this.addMaterialFields = this.addMaterialFields.bind(this);
    this.removeMaterialFields = this.removeMaterialFields.bind(this);
    this.renderMaterials = this.renderMaterials.bind(this);
  }

  getMaterialsBrandSeries() {
    const { materialsBrandSeries } = this.props;
    return [...materialsBrandSeries];
  }

  getBrandSeries(index) {
    return this.getMaterialsBrandSeries()[index];
  }

  addMaterialFields(fields) {
   const { dispatch } = this.props;
    dispatch(addMaterial());
    fields.push({});
  }

  removeMaterialFields(fields, index) {
    const { dispatch } = this.props;
    dispatch(removeMaterial(index));
    fields.remove(index);
  }

  onBrandSelect(value, index) {
    const { materialBrands, dispatch, autofill } = this.props;
    autofill(`materials[${index}].brand_series`, null);
    const materialsBrandSeries = this.getMaterialsBrandSeries();
    const brand = findItemById(materialBrands, value);
    const brandSeries = value ? [...brand.brand_items] : [];
    materialsBrandSeries.splice(index, 1, brandSeries);
    dispatch(changeMaterialBrand(materialsBrandSeries))
  }

  componentWillUnmount() {
    Modal.prototype.componentWillUnmount = () => {}
  }

  renderInlineForm(label, field) {
    return (
      <Row>
        <Col md={3} sm={4}>
          <label>{label}</label>
        </Col>
        <Col md={5} sm={7}>
          {field}
        </Col>
      </Row>
    )
  }

  renderAddMaterialButtons(fields, index) {
    const lastMaterial = index === (fields.length -1);
    return (
      <Col md={12}>
        <ButtonToolbar>
          <ButtonGroup bsSize="small" className="pull-right">
            { lastMaterial && <Button onClick={() => this.addMaterialFields(fields)}>Add more</Button> }
            { (fields.length > 1) &&<Button onClick={() => this.removeMaterialFields(fields, index)}>Remove</Button> }
          </ButtonGroup>
        </ButtonToolbar>
      </Col>
    )
  }

  renderMaterials({ fields }) {
    const { materialBrands } = this.props;
    return fields.map((material, index) => {
      let brandSeries = this.getBrandSeries(index);
      return (
        <Row className="clearfix" key={index}>
          <FormSection name={material}>
            <Col md={4}>
              <Field
                name="brand"
                label="Brand"
                option="Select Brand"
                component={RenderSelect}
                options={materialBrands}
                onChange={(e, value) => this.onBrandSelect(value, index)}
              />
            </Col>
            <Col md={4}>
              <Field
                name="brand_series"
                label="Brand Series"
                option="Select Series"
                component={RenderSelect}
                options={brandSeries}
              />
            </Col>
            <Col md={4}>
              <Field name="color" label="Color" component={RenderField} />
            </Col>
            { this.renderAddMaterialButtons(fields, index) }
          </FormSection>
        </Row>
      )})
  }

  render() {
    const {
      show, close, submitting, handleSubmit, saleRoofTypes, roofPitches, users
    } = this.props;
    return (
      <Modal closeOnEsc={false} closeOnOverlayClick={false} open={show} onClose={close}>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <h3>Mark job as sold</h3>
            </Col>
          </Row>

          <div className="inline-label">
            { this.renderInlineForm('Sold date', <Field name="job_sold_date" component={renderDatePicker} />) }
            { this.renderInlineForm(
              'Estimator',
              <Field
                name="estimator"
                option="Select Estimator"
                component={RenderSelect}
                options={estimators(users.list)}
              />) }
            { this.renderInlineForm(
              'Roof type',
              <SelectFieldWithSpecifyInput
                fieldName="roof_type"
                specifyFieldName="sale_roof_type_other"
                options={saleRoofTypes}
              />) }
            <br/>
            { this.renderInlineForm('Total Pitch', <Field name="total_pitch" component={RenderField} options={[]} />) }
            { this.renderInlineForm('Total Flat', <Field name="total_flat" component={RenderField} options={[]} />) }
            { this.renderInlineForm(
              'Roof Pitch',
              <SelectFieldWithSpecifyInput
                fieldName="roof_pitch"
                specifyFieldName="roof_pitch_other"
                options={roofPitches}
              />) }
            <br/>
          </div>
            <FieldArray name="materials" component={this.renderMaterials} />
          <Row>
            <Col md={12}>
              <Field name="note" label="Estimate Notes" rows="4" component={RenderTextArea} />
            </Col>
          </Row>
          <ButtonToolbar className="pull-right">
            <Button onClick={close}>Close</Button>
            <button className="btn btn-primary" type="submit" disabled={submitting}>Save</button>
          </ButtonToolbar>
        </form>
      </Modal>
    )
  }
}

JobSoldModal = reduxForm({
  form: JOB_SOLD_FORM_NAME,
  validate,
  enableReinitialize : true
})(JobSoldModal);
JobSoldModal = connect(
  state => ({
    initialValues: state.jobSold.data,
  })
)(JobSoldModal);

export default JobSoldModal;
