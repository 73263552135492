import React from "react";
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import { RenderSelect } from 'shared/render-field';
import { appointmentTimeFrame } from "helpers/DateTimeHelpers";
import { AppointmentFormFields } from "shared/appointment-form-fields";
import { validate } from "form-validations/validation-assign-estimator-form";
import { CAN_ASSIGN_ESTIMATOR } from "helpers/Permissions";
import { appointmentFormDataFromTimeSlots } from "helpers/Calendar";
import { selectedCurrent } from "helpers/Estimates";

class AssignEstimatorForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = { selectedEstimator: null };
    this.appointmentTimeFrame = this.appointmentTimeFrame.bind(this);
    this.selectEstimator = this.selectEstimator.bind(this);
    this.initializeForm = this.initializeForm.bind(this);
  }

  componentDidMount () {
    this.initializeForm()
  }

  componentWillReceiveProps(nextProps) {
    const { selectedEstimate } = nextProps;
    const { estimate } = this.props;
    if (selectedEstimate.id !== estimate.id) {
      this.setState({ selectedEstimator: null });
     this.initializeForm();
    }
  }

  initializeForm() {
    const { estimate, initializeData } = this.props;
    const formData = appointmentFormDataFromTimeSlots(initializeData);
    this.props.initialize({estimate_id: estimate.id, ...formData});
  }

  selectEstimator(val) {
    const { onSelect, estimate } = this.props;
    this.setState({ selectedEstimator: val });
    if(val) {
      onSelect(estimate);
    } else {
      onSelect({});
    }
  }

  appointmentTimeFrame(value) {
    appointmentTimeFrame(value, this.props)
  }

  render() {
    const { estimators, handleSubmit, selectedEstimate, estimate } = this.props;
    const { selectedEstimator } = this.state;
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-inline flex-justify">
          <Field name='estimator'
                 component={RenderSelect}
                 option="Choose estimator"
                 options={estimators}
                 onChange={(e, value)=> this.selectEstimator(value)}
                 disabled={!CAN_ASSIGN_ESTIMATOR}
          />
          <button className="btn btn-default" type="submit" disabled={!selectedEstimator}>Assign</button>
        </div>
        { selectedCurrent(selectedEstimate, estimate) && <AppointmentFormFields appointmentTimeFrame={this.appointmentTimeFrame} /> }
      </form>
    )
  }
}

AssignEstimatorForm.defaultProps = {
  initializeData: {}
};

AssignEstimatorForm.propTypes = {
  estimate: PropTypes.object.isRequired,
  estimators: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initializeData: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  selectedEstimate: PropTypes.object
};

AssignEstimatorForm = reduxForm({
  form: 'assign-estimator-form',
  validate
})(AssignEstimatorForm);
export default AssignEstimatorForm;


