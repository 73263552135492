import ApiClient from 'api/ApiClient'

class DocumentApi extends ApiClient {
  constructor() {
    super({url:'jobs', dataWrapperKey: 'document'})
  }

  documents(jobId) {
    return super.getNestedAction(jobId, '/documents')
  }

  addDocument(jobId, documentData) {
    return $.ajax({
      method: 'POST',
      dataType: "JSON",
      contentType: false,
      processData: false,
      url: `${this.setUrl()}/${jobId}/documents`,
      data: documentData
    })
  }

  deleteDocument(documentId) {
    return $.ajax({
      method: 'DELETE',
      url: `${this.setUrl('documents')}/${documentId}`,
    })
  }
}

export default DocumentApi
