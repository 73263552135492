import React from "react";
import Spinner from 'shared/spinner';

const ElementLoader = props => {
  return (
    <div className="block-loader">
      <Spinner active={true} search />
    </div>
  )
};
export default ElementLoader;

