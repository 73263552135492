import React from "react";
import { Row, Col, Clearfix } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import { validate } from 'form-validations/validation-note-form';
import { RenderTextArea, RenderSelect } from "shared/render-field";
import { NOTE_FORM_NAME } from "helpers/FormNames";

class NoteForm extends React.Component {
  render() {
    const { submitting, handleSubmit, noteTypes } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off" className="note-form">
        <Row>
          <Col md={5}>
            <Field
              name="note_type"
              label="Type"
              component={RenderSelect}
              options={noteTypes}
              hideEmptyOption
             />
          </Col>
        <Clearfix />
          <div className="flex-center-align">
            <Col md={8}>
              <Field name="note" label="Note" component={RenderTextArea} />
            </Col>
            <Col md={4}>
              <button className="btn btn-success margin-top-15" type="submit" disabled={submitting}>Add a note</button>
            </Col>
          </div>
        </Row>
      </form>
    )
  }
}

NoteForm = reduxForm({
  form: NOTE_FORM_NAME,
  validate,
  enableReinitialize : true
})(NoteForm);

// initialize form state
NoteForm = connect(
  state => ({
    initialValues: state.notes && state.notes.editData,
  }))(NoteForm);

NoteForm.defaultProps = {
  noteTypes: []
};

NoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  noteTypes: PropTypes.array.isRequired
};

export default NoteForm
