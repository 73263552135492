import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import { EstimatesReducer } from "reducers/estimates-reducers";
import ActivityLogReducer from "reducers/activity-log-reducer";
import TodosReducers from "reducers/todos-reducers";
import UsersReducer from "reducers/users-reducer";
import { CalendarReducer } from "reducers/calendar-reducers";
import { UserUnavailableReducer } from "reducers/user-unavailable-reducer";

const DashboardReducers = combineReducers({
  estimates: EstimatesReducer,
  activityLog: ActivityLogReducer,
  todos: TodosReducers,
  users: UsersReducer,
  calendar: CalendarReducer,
  userUnavailable: UserUnavailableReducer,
  form: reduxFormReducer
});

export default DashboardReducers
