import React from "react";
import PropTypes from 'prop-types';

import { calculateFullHeight } from "helpers/ScreenHelpers";
import Wrapper from "statistics/wrapper"


class Heatmaps extends React.Component {
  constructor(props) {
    super(props);
    this.prepareHeatmapData = this.prepareHeatmapData.bind(this);
    this.mapCenter = this.mapCenter.bind(this);
    this.initMap = this.initMap.bind(this);
    this.setHeatmapsData = this.setHeatmapsData.bind(this);
  }

  componentDidMount() {
    const { heatmaps } = this.props;
    this.initHeatmap();
    this.setHeatmapsData(heatmaps);
  }

  componentWillUpdate(nextProps) {
    const { heatmaps } = nextProps;
    if (heatmaps !== this.props.heatmaps) this.setHeatmapsData(heatmaps);
  }

  setHeatmapsData(heatmapsData) {
    this.heatmap.setData(this.prepareHeatmapData(heatmapsData));
    this.map.setCenter(this.mapCenter(heatmapsData));
  }

  prepareHeatmapData(heatmaps) {
    return heatmaps.map((coordinates) => {
      const lat = +coordinates.lat;
      const lng = +coordinates.lng;
      return new google.maps.LatLng(lat, lng);
    })
  }

  mapCenter(heatmaps) {
    const coordsLA = { lat: 34.0201613, lng: -118.6919205 };
    if (heatmaps.length) {
      const coords = heatmaps[0];
      const lat = +coords.lat;
      const lng = +coords.lng;
      return { lat: lat, lng: lng }
    } else {
      return coordsLA;
    }
  }

  initMap() {
    const { heatmaps } = this.props;
    return new google.maps.Map(this.refs.map, {
      zoom: 10,
      center: this.mapCenter(heatmaps),
      mapTypeId: 'satellite'
    });
  }

  initHeatmap() {
    this.map = this.map || this.initMap();
    this.heatmap =  new google.maps.visualization.HeatmapLayer({
      map: this.map,
      radius: 25,
      maxIntensity: 10
    });
  }

  render() {
    const { loading } = this.props;
    return (
      <Wrapper loading={loading}>
        <div style={{ height: calculateFullHeight(this.refs.map) }} ref="map" />
      </Wrapper>
      )
  }
}

Heatmaps.defaultProps = {
  heatmaps: [],
  loading: false
};

Heatmaps.propTypes = {
  heatmaps: PropTypes.array,
  loading: PropTypes.bool
};

export default Heatmaps;
