import React from "react";
import { Row, Col, Clearfix } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Spinner from 'shared/spinner'
import JobBreadcrumb from './job-breadcrumb';
import { JobInfo } from './job-info';
import ClientContactInfo from "./client";
import { ActivityTabs } from "activity-tabs/activity-tabs";
import { TYPE_JOB } from "helpers/AppConstants";
import EditJobModal from 'shared/edit-job-modal';
import JobEstimateModal from "shared/job-estimate-modal";
import JobSoldModal from "shared/job-sold-modal";
import JobLostModal from "shared/job-lost-modal";
import { JOB_STATUS_SOLD } from "helpers/AppConstants";
import { checkJobStatus } from "helpers/Job";
import { canAddTodoToJob } from "helpers/Permissions";
import NotesComponent from 'notes/notes-component';
import { BorderedWrapper } from "shared/bordered-wrapper";

class JobComponent extends React.Component {
  constructor(props) {
    super(props);
    this.updateJob = this.updateJob.bind(this);
    this.estimateJob = this.estimateJob.bind(this);
    this.soldJob = this.soldJob.bind(this);
    this.lostJob = this.lostJob.bind(this);
    this.callbackOnStatusChange = this.callbackOnStatusChange.bind(this);
    this.fetchSoldDetails = this.fetchSoldDetails.bind(this);
    this.fetchActivityLog = this.fetchActivityLog.bind(this);
    this.addNote = this.addNote.bind(this);
    this.addTodo = this.addTodo.bind(this);
    this.completeTodo = this.completeTodo.bind(this);
    this.addCallback = this.addCallback.bind(this);
    this.selectNoteWhenJobCreated = this.selectNoteWhenJobCreated.bind(this);
  }

  componentWillMount() {
    const {
      fetchJob, id, fetchMaterialBrands, fetchSaleRoofTypes, fetchCallbacks,
      fetchRoofPitches, fetchLostReasons, fetchTodos, fetchUsers
    } = this.props;
    if (id) fetchJob(id, this.fetchSoldDetails);
    fetchTodos(id);
    fetchCallbacks(id);
    fetchMaterialBrands();
    fetchSaleRoofTypes();
    fetchRoofPitches();
    fetchLostReasons();
    fetchUsers();
    this.fetchActivityLog()
  }

  fetchSoldDetails(job) {
    const { soldDetails } = this.props;
    if(checkJobStatus(job, JOB_STATUS_SOLD)) soldDetails(job.id)
  }

  estimateJob(data) {
    const { estimateJob } = this.props;
    estimateJob(data, this.callbackOnStatusChange);
  }

  soldJob(data) {
    const { soldJob } = this.props;
    soldJob(data, this.callbackOnStatusChange);
  }

  lostJob(data) {
    const { lostJob } = this.props;
    lostJob(data, this.callbackOnStatusChange);
  }

  callbackOnStatusChange() {
    const { fetchNotes, job } = this.props;
    const jobId = job.data.id;
    fetchNotes(TYPE_JOB, jobId);
    this.fetchActivityLog();
  }

  fetchActivityLog() {
    const { fetchActivityLog, id } = this.props;
    fetchActivityLog(id)
  }

  addCallback(data) {
    const { addCallback } = this.props;
    addCallback(data, this.fetchActivityLog);
  }

  addNote(data) {
    const { addNote } = this.props;
    addNote(data, this.fetchActivityLog);
  }

  addTodo(data) {
    const { addTodo } = this.props;
    addTodo(data, this.fetchActivityLog);
  }

  completeTodo(data) {
    const { completeTodo } = this.props;
    completeTodo(data, this.fetchActivityLog);
  }

  updateJob(data) {
    const { updateJob } = this.props;
    updateJob(data, this.fetchActivityLog);
  }

  selectNoteWhenJobCreated() {
    const { notesData, job } = this.props;
    if (job.data.client) {
      const jobCreatedAt = job.data.created_at;
      const notes = notesData.notes.list;
      return notes.find(function(element, index, array) {
        return element.created_at === jobCreatedAt;
      });
    }
  }

  render() {
    const {
      job, editJob, jobEstimate, jobSold, resetJobEditForm, closeJobLostModal,
      closeJobEstimateModal, closeJobSoldModal, jobLost, lostReasons
    } = this.props;
    const client = job.data.client;
    return (
      <div>
        <Spinner active={job.loading} ><span>Loading...</span></Spinner>
        { !job.loading && <div>
          <JobBreadcrumb job={job.data} />
          <Row className="job-show">
            <Col md={8} className="full-height">
              <ClientContactInfo
                client={client}
                className="full-height"
                note={this.selectNoteWhenJobCreated()}
                showDetail
              />
              <div className='margin-top-10'>
                <JobInfo
                  {...this.props}
                  job={job.data}
                  className="full-height"
                />
              </div>
              <div className='margin-top-10'>
                <ActivityTabs
                  {...this.props}
                  type={TYPE_JOB}
                  addCallback={this.addCallback}
                  addTodo={this.addTodo}
                  completeTodo={this.completeTodo}
                  canAddTodo={canAddTodoToJob(job.data)}
                />
              </div>
            </Col>
            <Col md={4}>
              <BorderedWrapper>
                <NotesComponent
                  {...this.props}
                  addNote={this.addNote}
                  type={TYPE_JOB}
                />
              </BorderedWrapper>
            </Col>
          </Row>
          <EditJobModal
            {...this.props}
            show={editJob.showModal}
            close={resetJobEditForm}
            onSubmit={this.updateJob}
          />
          <JobEstimateModal
            onSubmit={this.estimateJob}
            show={jobEstimate.showModal}
            close={closeJobEstimateModal}
          />
          <JobSoldModal
            {...this.props}
            onSubmit={this.soldJob}
            show={jobSold.showModal}
            close={closeJobSoldModal}
          />
          <JobLostModal
            onSubmit={this.lostJob}
            show={jobLost.showModal}
            close={closeJobLostModal}
            lostReasons={lostReasons}
          />
          <Spinner active={editJob.loading} ><span>Loading...</span></Spinner>
        </div> }
      </div>
    )
  }
}

JobComponent.propTypes = {
  job: PropTypes.object.isRequired,
  convertCallbackToJob: PropTypes.func.isRequired
};

export default JobComponent;
