import { connect } from 'react-redux';
import ClientsList from 'presentational/clients/clients-component';
import { loadClientToForm, resetClientEditForm } from 'actions/clients-actions';
import { fetchClients, updateClient, toggleArchive, clientAddress } from 'api/client';
import {resetTabsData, selectTab} from "actions/jobs-tabs-actions";
import { setSortParams } from "actions/search-actions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClients: (params) => {
      dispatch(fetchClients(params))
    },
    loadAddress: (data, key) => {
      dispatch(clientAddress(data, key));
    },
    loadToEditForm: (client) => {
      dispatch(loadClientToForm(client))
    },
    resetEditForm: () => {
      dispatch(resetClientEditForm())
    },
    updateClient: (data) => {
      dispatch(updateClient(data))
    },
    toggleArchive: (data) => {
      dispatch(toggleArchive(data))
    },
    selectTab: (data) => {
      dispatch(selectTab(data))
    },
    resetTabsData: () => {
      dispatch(resetTabsData())
    },
    setSortParams: (sortParams) => {
      dispatch(setSortParams(sortParams))
    }
  }
};

function mapStateToProps(state) {
  return {
    clients: state.clients,
    editClient:state.editClient,
    tabs: state.tabs,
    search: state.search
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
