import StatisticsApi from "api/StatisticsApi";

export const HEATMAPS_REQUEST = 'HEATMAPS_REQUEST';
export const HEATMAPS_REQUEST_SUCCESS = 'HEATMAPS_REQUEST_SUCCESS';
export const HEATMAPS_REQUEST_FAILURE = 'HEATMAPS_REQUEST_FAILURE';

const heatmapsRequest = () => {
  return {
    type: HEATMAPS_REQUEST,
  }
};

const heatmapsRequestSuccess = (heatmapsData) => {
  return {
    type: HEATMAPS_REQUEST_SUCCESS,
    payload: heatmapsData
  }
};

const heatmapsRequestFailure = (error) => {
  return {
    type: HEATMAPS_REQUEST_FAILURE,
    payload: error
  }
};

const statisticsApi = new StatisticsApi();

export const fetchHeatmaps = (params) => {
  return (dispatch) => {
    dispatch(heatmapsRequest());
    return statisticsApi.heatmaps(params)
      .then((data) => {
        dispatch(heatmapsRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(heatmapsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
