import LostReasonsApi from "api/LostReasonsApi";

export const LOST_REASONS_REQUEST = 'LOST_REASONS_REQUEST';
export const LOST_REASONS_REQUEST_SUCCESS = 'LOST_REASONS_REQUEST_SUCCESS';
export const LOST_REASONS_REQUEST_FAILURE = 'LOST_REASONS_REQUEST_FAILURE';

const lostReasonsRequest = () => {
  return {
    type: LOST_REASONS_REQUEST,
  }
};

const lostReasonsRequestSuccess = (lostReasons) => {
  return {
    type: LOST_REASONS_REQUEST_SUCCESS,
    payload: lostReasons
  }
};

const lostReasonsRequestFailure = (error) => {
  return {
    type: LOST_REASONS_REQUEST_FAILURE,
    payload: error
  }
};

const lostReasonsApi = new LostReasonsApi();

export const fetchLostReasons = () => {
  return (dispatch) => {
    dispatch(lostReasonsRequest());
    return lostReasonsApi.index()
      .done((data) => {
        dispatch(lostReasonsRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(lostReasonsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
