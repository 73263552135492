import React from 'react';
import { render } from 'react-dom';
import Modal from 'react-responsive-modal';
import { Button, ButtonToolbar, Row, Col } from 'react-bootstrap';
import ClientContactInfo from '../job-show/client';

export default class JobDetailsModal extends React.Component {
  render() {
    const { show, close, event } = this.props;

    return (
      <div>
        <ButtonToolbar className='pull-right'>
          <Button onClick={close}>Close</Button>
        </ButtonToolbar>
        {
          event &&
            <Modal classNames={{ modal: 'job-details-modal' }} open={show} onClose={close} center>
              <div className='job-details-modal-content' >
                <ClientContactInfo
                  client={event.client}
                  className='full-height'
                  showDetail={false}
                  jobAddress={event.address}
                  vertical={true}
                />
                <Row>
                  <Col md={0}>
                    {`${event.appointment_date} ${event.time_from} - ${event.time_to}`}
                  </Col>
                </Row>
                <Row>
                  <Col md={0}>
                    <a href={ `/jobs/${event.id}` } className='btn btn-sm btn-default margin-top-15'>Show Job</a>
                  </Col>
                </Row>
              </div>
            </Modal>
        }
      </div>
    );
  }
}
