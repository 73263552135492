import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { RenderAutocompleteInput } from "shared/render-field";


export class AutoSuggestInput extends React.Component {
  constructor(props) {
    super(props);
    this.selectItem = this.selectItem.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.keyHandlers = this.keyHandlers.bind(this);
    this.formatAutoComplete = this.formatAutoComplete.bind(this);
    this.state = {
      autoCompleteItems: []
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  formatAutoComplete(items=[]) {
    const { disableAutoSelectActive } = this.props;
    this.setState({
      autoCompleteItems: items.map((item, i) => {
        const active = disableAutoSelectActive ? false : i === 0;
        return {...item, index: i, active: active }
      })
    })
  }

  resetAutoComplete() {
    if (!this.mounted) return;
    this.setState({
      autoCompleteItems: []
    })
  }

  getActiveItem() {
    if(!this.state.autoCompleteItems.length) return;
    let active = this.state.autoCompleteItems.find(item => item.active);
    return active
  }

  setActiveItem(index) {
    this.setState({
      autoCompleteItems: this.state.autoCompleteItems.map((item, i) => {
        if (i === index) {
          return { ...item, active: true }
        } else {
          return { ...item, active: false }
        }
      }),
    })
  }

  activeNext(direction) {
    const { disableAutoSelectActive } = this.props;
    let nextIndex, activeIndex, autoCompleteItems = this.state.autoCompleteItems;
    const autoCompleteLength = autoCompleteItems.length;
    if (disableAutoSelectActive || autoCompleteLength === 0) return;
    let activeItem = this.getActiveItem();
    if(direction === 'down') {
      activeIndex = (activeItem && activeItem.index + 1) || 0;
      nextIndex = (activeIndex) % autoCompleteLength;
    } else if(direction === 'up') {
      activeIndex = (activeItem && activeItem.index) || autoCompleteLength;
      nextIndex = activeIndex === 0 ? autoCompleteLength - 1 : (activeIndex - 1) % autoCompleteLength
    }
    this.setActiveItem(nextIndex)
  }

  selectItem(item) {
    const { onSelect } = this.props;
    if (onSelect) onSelect(item);
    this.resetAutoComplete();
  }

  handleClick(item) {
    this.selectItem(item);
  }

  handleBlur() {
    setTimeout(() => {
      this.resetAutoComplete()
    }, 200)
  }

  onFocus() {
    const { onFocus } = this.props;
    if(!onFocus) return;
    onFocus();
  }

  onEnter(e) {
    const { onEnter } = this.props;
    const { autoCompleteItems } = this.state;
    let value = e.target.value;
    if (!value.length && !autoCompleteItems.length) return;
    if (value.length && !autoCompleteItems.length) {
      this.handleChange(e)
    } else {
      onEnter(value, autoCompleteItems)
    }
  }

  handleEnterKey(e) {
    const activeItem = this.getActiveItem();
    if (activeItem) {
      this.selectItem(activeItem)
    } else {
      this.onEnter(e);
    }
  }

  handleChange(e) {
    let value = e.target.value;
    if (value.length) {
      this.props.handleChange && this.props.handleChange(value, this.formatAutoComplete)
    } else {
      this.resetAutoComplete();
    }
  }

  keyHandlers(event) {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.handleEnterKey(event);
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.activeNext('down');
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.activeNext('up');
        break;
      case 'Escape':
        this.resetAutoComplete();
        break
    }
  }

  eventHandlers() {
    return {
      onChange: this.handleChange,
      onFocus: this.onFocus,
      onClick: this.handleClick,
      onBlur: this.handleBlur,
      onKeyDown: this.keyHandlers
    }
  }

  render() {
    const inputHandlers = this.eventHandlers();
    const { autoCompleteItems } = this.state;
    const { label, name, showSearchIcon } = this.props;
    return (
      <Field
        label={label}
        name={name}
        component={RenderAutocompleteInput}
        showSearchIcon={showSearchIcon}
        options={autoCompleteItems}
        {...inputHandlers}
      />
    )
  }
}

AutoSuggestInput.defaultProps = {
  onEnter: () => {}
};

AutoSuggestInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  showSearchIcon: PropTypes.bool,
  disableAutoSelectActive: PropTypes.bool
};
