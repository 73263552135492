import React from "react";
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'react-bootstrap'

import { BorderedWrapper } from "shared/bordered-wrapper";
import { DescriptionRow } from "shared/description-row";
import { Address } from "job-show/address";
import { selectAllContacts } from 'api/client';
import { isCallbackClosed } from "helpers/Callback";
import { selectOptionOrOther } from "helpers/ViewHelpers";

export class JobDescription extends React.Component {
  renderJobDescription(job, note) {
    return(
      <Row>
        <DescriptionRow name="Called In" value={ job.created_at}/>
        <DescriptionRow name="Address" value={<Address address={job.address}/>}/>
        <DescriptionRow name="Type of Building" value={selectOptionOrOther('building_type', job)}/>
        <DescriptionRow name="Number of Stories" value={ job.stories_count && job.stories_count.name }/>
        <DescriptionRow name="Roof Access" value={selectOptionOrOther('roof_access', job)}/>
        <DescriptionRow name="Type of Roof" value={selectOptionOrOther('roof_type', job)}/>

        <div className="padding-v-10">
          <DescriptionRow name="Comments" value={ note } />
        </div>
      </Row>
    )
  }

  renderClientInfo(client) {
    return (
      <BorderedWrapper>
        <Row>
          <h3 className="margin-top-0 col-md-12 text-muted">
            Client contact info
          </h3>
          <DescriptionRow name="Name" value={client.name}/>
          <DescriptionRow name="Business Name" value={client.company_name}/>
          <DescriptionRow name="Phone number" value={selectAllContacts(client.phones)} isPhone />
          <DescriptionRow name="Email" value={selectAllContacts(client.emails, 'email')} isEmail />
        </Row>
      </BorderedWrapper>
    )
  }

  render() {
    const { job, note } = this.props;
    return (
      <BorderedWrapper>
        <Row>
          <Col md={8}>
            <h3 className="margin-top-0 text-muted">
                Callback report #{job.data.id}
            </h3>
            { isCallbackClosed(job.data) && <Alert bsStyle="danger">Callback is closed</Alert>}
            {this.renderJobDescription(job.data, note)}
          </Col>
          <Col md={4}>
            {job.data.client && this.renderClientInfo(job.data.client)}
          </Col>
        </Row>
      </BorderedWrapper>
    )
  }
}

JobDescription.propTypes = {
  job: PropTypes.object.isRequired,
  note: PropTypes.string.isRequired
};
