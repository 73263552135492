import {
  CALLBACK_STATUS_CLOSED, CALLBACK_STATUS_OPEN, JOB_STATUS_ASSIGNED, JOB_STATUS_ESTIMATED,
  JOB_STATUS_LOST, JOB_STATUS_SCHEDULED, JOB_STATUS_SOLD, JOB_STATUS_UNASSIGNED
} from "helpers/AppConstants";
import { addressToString } from "actions/addresses";
import { CALLBACK, JOB } from "helpers/AppConstants";
import { onOtherOptionSelect } from "helpers/FormHelpers";
import { primaryIndex } from "api/client";

export const selectStatusLabelClass = (job) => {
  switch (job.status) {
    case JOB_STATUS_LOST:
      return 'danger';
    case CALLBACK_STATUS_OPEN: case JOB_STATUS_UNASSIGNED:
      return 'warning';
    case JOB_STATUS_ASSIGNED: case JOB_STATUS_SCHEDULED:
      return 'info';
    case JOB_STATUS_ESTIMATED:
      return 'primary';
    case CALLBACK_STATUS_CLOSED: case JOB_STATUS_SOLD:
      return 'success';
    default:
      return 'default';
  }
};

export const checkJobStatus = (job, status) => {
  return job.status === status
};

export const isJobEstimated = (job) => {
  if (!job.status) return false;
  return job.status.toLowerCase() === 'estimated'
};

export const jobEstimateParams = (jobParams) => {
  return {
    id: jobParams.id,
    note: jobParams.note,
    date_estimated: jobParams.date_estimated
  }
};

export const jobToSoldForm = (job) => {
  return {
    job_id: job.id,
    estimator: job.user && job.user.id
  }
};

export const jobSoldParams = (soldParams) => {
  return {
    id: soldParams.job_id,
    note: soldParams.note,
    job_sold_date: soldParams.job_sold_date,
    total_pitch_square: soldParams.total_pitch,
    total_flat_square: soldParams.total_flat,
    estimator_id: soldParams.estimator,
    sale_roof_type_id: onOtherOptionSelect(soldParams.roof_type),
    sale_roof_type_other: soldParams.sale_roof_type_other,
    roof_pitch_id: onOtherOptionSelect(soldParams.roof_pitch),
    roof_pitch_other: soldParams.roof_pitch_other,
    materials_attributes: soldParams.materials.map(material => {
      return {
        material_brand_id: material.brand,
        brand_item_id: material.brand_series,
        color: material.color,
      }
    })
  }
};

export const jobLostParams = (lostParams) => {
  return {
    id: lostParams.id,
    note: lostParams.note,
    lost_reason_id: lostParams.lost_reason
  }
};

export const jobToString = (job) => {
  const clientName = `${job.client.name},`;
  const address = addressToString(job.address);
  const type = job.callback ? CALLBACK : JOB;
  const phone = `${job.client.phones[primaryIndex(job.client.phones)].phone},`;
  let email = job.client.emails[primaryIndex(job.client.emails)].email;
  email = email ? `${email},` : ""

  return `${clientName} ${email} ${phone} ${address} - ${type}`;
};
