import React from "react";
import { extractNumbersFromStringForPhone } from "helpers/ViewHelpers";

export const renderContacts = (props) => {
  const { value, isPhone, isEmail } = props;

  if (isPhone || isEmail) {
    const contacts = movePrimaryContactToTheTop(value);
    const contactType = isPhone ? 'phone' : 'email';
    return contacts.length ? renderContactsByType(contacts, contactType) : '-';
  }
  return <div className="wrap-word" >{value || '-'}</div>
}

const renderContactsByType = (contacts, type) => {
  return contacts.map((value, key) => {
    return(
      <div key={key}>
        <a className="wrap-word" href={contactHref(value[type], type)}>
          {value[type]}
        </a>
      </div>
    );
  });
}

const contactHref = (value, type) => {
  switch (type) {
    case 'phone': return `tel:${extractNumbersFromStringForPhone(value)}`;
    case 'email': return `mailto:${value}`;
  }
}

const movePrimaryContactToTheTop = (contacts) => {
  if (!contacts || !contacts.length) return [];

  const primaryContact = contacts.find((contact => contact.primary));
  const secondaryContacts = contacts.filter((contact => !contact.primary));

  return primaryContact ? [primaryContact].concat(secondaryContacts) : secondaryContacts;
};
