import React from "react";
import { Row, Col, Tab, Nav, NavItem } from "react-bootstrap";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

import JobsTable from './jobs-table';
import JobsByReferenceTable from './jobs-by-reference-table';
import SearchJobForm from './search-job-form';
import Spinner from 'shared/spinner';
import EditJobModal from 'shared/edit-job-modal';
import Heatmaps from "statistics/heatmaps";
import StackedStatusesChart from "statistics/stacked-statuses-chart";
import StatusChart from "statistics/status-chart";
import Distributions from "statistics/distributions";
import { SEARCH_FORM } from "helpers/FormNames";
import { perfomAsync } from "helpers/Async";
import {
  JOBS_TAB, JOBS_BY_REFERENCE_TAB, HEATMAPS_TAB, STATUSES_BY_ESTIMATOR_CHART_TAB, TIMELINE_CHART_TAB,
  DISTRIBUTIONS_CHARTS_TAB, STATUSES_CHART_TAB, CALLED_IN, CREATED_AT, ORDER_DESC
} from "helpers/AppConstants";
import { parseSearchParams } from "helpers/PathHelpers";
import { oneYearAgoDateFormat } from "helpers/DateTimeHelpers";
import { PAGINATION_START_PAGE } from "helpers/AppConstants";
import { stringToSelectObj } from "helpers/FormHelpers";
import JobApi from 'api/JobApi'

import { findItemById } from "helpers/ListHelpers";


class JobsList extends React.Component {
  constructor(props) {
    super(props);
    this.jobApi = new JobApi();
    this.renderJobsTable = this.renderJobsTable.bind(this);
    this.submitSearchForm = this.submitSearchForm.bind(this);
    this.onSelectTab = this.onSelectTab.bind(this);
    this.initialSearchParams = this.initialSearchParams.bind(this);
    this.fetchJobs = this.fetchJobs.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onTableSort = this.onTableSort.bind(this);
    this.mergeSortParams = this.mergeSortParams.bind(this);
  }

  componentWillMount() {
    const {
      setSortParams,
      fetchMaterialBrands
    } = this.props;
    setSortParams(this.initialSortParams())
    fetchMaterialBrands();
  }

  componentDidMount() {
    perfomAsync(() => {
      const { searchParams } = this.props;
      this.submitSearchForm(this.mergeSortParams(searchParams))
    })
  }

  initialSortParams() {
    return { sortBy: CREATED_AT, orderBy: ORDER_DESC }
  }

  initialSearchParams() {
    return {
      ...parseSearchParams(),
      date_from: oneYearAgoDateFormat(),
      date_filter: stringToSelectObj(CALLED_IN).id
    }
  }

  mergeSortParams(params = {}) {
    const { search } = this.props;
    return {...params, ...search.sort}
  }

  onPageChange(page) {
    const { searchParams } = this.props;
    this.fetchJobs(this.mergeSortParams(searchParams), page)
  }

  onTableSort(sortParams) {
    const { setSortParams, searchParams, jobs } = this.props;
    setSortParams(sortParams);
    const params = {...searchParams, ...sortParams};
    const page = jobs.meta.current_page;
    this.fetchJobs(params, page)
  }

  getMaterialBrandSeries() {
    const { materialBrands, searchParams } = this.props;
    const value = searchParams.material_brand_id ? searchParams.material_brand_id : 0
    const brand = findItemById(materialBrands, value);
    const brandSeries = value ? [...brand.brand_items] : [];
    return brandSeries
  }

  fetchJobs(params, page = PAGINATION_START_PAGE){
    const { fetchJobs } = this.props;
    fetchJobs({...params, page})
  }

  submitSearchForm(params) {
    const {
      fetchJobs, fetchHeatmaps, fetchStatusesCountsByEstimator,
      fetchStatusesCounts, fetchTimelineData, tabs, fetchDistributionsData
    } = this.props;
    switch(tabs.selected) {
      case JOBS_TAB:
        this.fetchJobs(this.mergeSortParams(params));
        break;
      case JOBS_BY_REFERENCE_TAB:
        this.fetchJobs(this.mergeSortParams(params));
        break;
      case HEATMAPS_TAB:
        fetchHeatmaps(params);
        break;
      case STATUSES_BY_ESTIMATOR_CHART_TAB:
        fetchStatusesCountsByEstimator(params);
        break;
      case STATUSES_CHART_TAB:
        fetchStatusesCounts(params);
        break;
      case TIMELINE_CHART_TAB:
        fetchTimelineData(params);
        break;
      case DISTRIBUTIONS_CHARTS_TAB:
        fetchDistributionsData(params);
        break;
      default:
        fetchJobs(params);
    }
  }

  onSelectTab(tabKey) {
    const { selectTab, setSortParams } = this.props;
    selectTab(tabKey);
    if (tabKey === JOBS_TAB || tabKey === JOBS_BY_REFERENCE_TAB) setSortParams(this.initialSortParams());

    perfomAsync(() => {
      const { searchParams, resetTabsData } = this.props;
      resetTabsData();
      this.submitSearchForm(searchParams)
    })
  }

  renderJobsTable() {
    const { jobs, editJob, resetJobEditForm, updateJob } = this.props;
    return(
      <div>
        <JobsTable {...this.props} jobs={jobs} onPageChange={this.onPageChange} onTableSort={this.onTableSort} />
        <EditJobModal
          {...this.props}
          show={editJob.showModal}
          close={resetJobEditForm}
          onSubmit={updateJob}
        />
        <Spinner active={editJob.loading} ><span>Loading...</span></Spinner>
      </div>
    )
  }

  renderJobsByReferenceTable() {
    const { jobs, editJob, resetJobEditForm, updateJob } = this.props;
    return(
      <div>
        <JobsByReferenceTable {...this.props} jobs={jobs} onPageChange={this.onPageChange} onTableSort={this.onTableSort} />
        <EditJobModal
          {...this.props}
          show={editJob.showModal}
          close={resetJobEditForm}
          onSubmit={updateJob}
        />
        <Spinner active={editJob.loading} ><span>Loading...</span></Spinner>
      </div>
    )
  }

  render() {
    const {
      heatmaps,
      filters,
      tabs,
      statusesCountsByEstimator,
      timeline,
      statusesCounts,
      distributions,
      searchParams
    } = this.props;

    return (
      <div>
        <Row>
          <Col md={12}>
            <h2 className="col-md-6 page-title">Jobs</h2>
            <SearchJobForm
              filters={filters}
              onSubmit={this.submitSearchForm}
              initialParams={this.initialSearchParams()}
              brandSeries={this.getMaterialBrandSeries()}
              materialBrands={this.props.materialBrands}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3>
              <small className="pull-right">Download CSV:{' '}
                <a href={this.jobApi.csvUrl(searchParams)} download>Emails | Contact info</a>
              </small>
            </h3>
          </Col>
        </Row>
        <Row>
          <Tab.Container id="jobs-tabs" onSelect={this.onSelectTab} defaultActiveKey={tabs.selected || JOBS_TAB}>
            <Row className="clearfix padding-top-10">
              <Col md={12}>
                <Nav bsStyle="pills" navbar>
                  <NavItem eventKey={JOBS_TAB}>Jobs</NavItem>
                  <NavItem eventKey={JOBS_BY_REFERENCE_TAB}>Jobs by Reference</NavItem>
                  <NavItem eventKey={HEATMAPS_TAB}>Heatmaps</NavItem>
                  <NavItem eventKey={STATUSES_BY_ESTIMATOR_CHART_TAB}>Estimators chart</NavItem>
                  <NavItem eventKey={STATUSES_CHART_TAB}>Status chart</NavItem>
                  <NavItem eventKey={TIMELINE_CHART_TAB}>Timeline chart</NavItem>
                  <NavItem eventKey={DISTRIBUTIONS_CHARTS_TAB}>Distributions</NavItem>
                </Nav>
              </Col>
              <Col md={12} className="margin-top-20">
                <Tab.Content animation mountOnEnter unmountOnExit>
                  <Tab.Pane eventKey={JOBS_TAB}>
                    {this.renderJobsTable()}
                  </Tab.Pane>
                  <Tab.Pane eventKey={JOBS_BY_REFERENCE_TAB}>
                    {this.renderJobsByReferenceTable()}
                  </Tab.Pane>
                  <Tab.Pane eventKey={HEATMAPS_TAB}>
                    <Heatmaps heatmaps={heatmaps.list} loading={heatmaps.loading} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={STATUSES_BY_ESTIMATOR_CHART_TAB}>
                    <StackedStatusesChart
                      chartData={statusesCountsByEstimator.data}
                      loading={statusesCountsByEstimator.loading}
                      xAxisTitle="Estimators"
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={STATUSES_CHART_TAB}>
                    <StatusChart
                      chartData={statusesCounts.data}
                      loading={statusesCounts.loading}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={TIMELINE_CHART_TAB}>
                    <StackedStatusesChart
                      chartData={timeline.data}
                      loading={timeline.loading}
                      chartType="column"
                      xAxisTitle="Timeline"
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={DISTRIBUTIONS_CHARTS_TAB}>
                    <Distributions distributions={distributions} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </div>
    )
  }
}

const selector = formValueSelector(SEARCH_FORM);
JobsList = connect(state => {
  const {
    query, search_by, callback, roof_type_id, status, user_id, date_from, date_to, date_filter, material_brand_id, brand_item_id, color
  } = selector(state, 'query', 'search_by', 'callback', 'roof_type_id', 'status', 'user_id', 'date_from', 'date_to', 'date_filter', 'material_brand_id', 'brand_item_id', 'color');
  return {
    searchParams: {
      query,
      search_by,
      callback,
      roof_type_id,
      status,
      user_id,
      date_filter,
      date_from,
      date_to,
      material_brand_id,
      brand_item_id,
      color
    }
  };
})(JobsList);

export default JobsList;
