import { parseAddress, loadAddress } from './addresses'
import { CALL_FORM_NAME } from 'helpers/FormNames'
import { parseJobToForm } from "api/job";
import { CAN_ASSIGN_ESTIMATOR } from "helpers/Permissions";

export const JOB_REQUEST = 'JOB_REQUEST';
export const JOB_REQUEST_SUCCESS = 'JOB_REQUEST_SUCCESS';
export const JOB_REQUEST_FAILURE = 'JOB_REQUEST_FAILURE';

export const JOBS_REQUEST = 'JOBS_REQUEST';
export const JOBS_REQUEST_SUCCESS = 'JOBS_REQUEST_SUCCESS';
export const JOBS_REQUEST_FAILURE = 'JOBS_REQUEST_FAILURE';

//Create new job
export const CREATE_JOB = 'CREATE_JOB';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAILURE = 'CREATE_JOB_FAILURE';
export const RESET_NEW_JOB = 'RESET_NEW_JOB';

//Update job
export const LOAD_JOB_TO_EDIT_FORM = 'LOAD_JOB_TO_EDIT_FORM';
export const RESET_JOB_EDIT_FORM = 'RESET_JOB_EDIT_FORM';
export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_REQUEST_SUCCESS = 'UPDATE_JOB_REQUEST_SUCCESS';
export const UPDATE_JOB_REQUEST_FAILURE = 'UPDATE_JOB_REQUEST_FAILURE';

export const CHOOSE_JOB_FOR_CALLBACK = 'CHOOSE_JOB_FOR_CALLBACK';

export const DISABLE_ADDRESS_INPUT = 'DISABLE_ADDRESS_INPUT';

export const disableAddressInput = (data) => {
  return {
    type: DISABLE_ADDRESS_INPUT,
    payload: data
  }
}

export const jobRequest = (data) => {
  return {
    type: JOB_REQUEST,
    payload: data
  }
};

export const jobRequestSuccess = (data) => {
  return {
    type: JOB_REQUEST_SUCCESS,
    payload: data
  };
};

export const jobRequestFailure = (error) => {
  return {
    type: JOB_REQUEST_FAILURE,
    payload: error
  };
};


export const jobsRequest = (data) => {
  return {
    type: JOBS_REQUEST,
    payload: data
  }
};

export const jobsRequestSuccess = (data) => {
  return {
    type: JOBS_REQUEST_SUCCESS,
    payload: data
  };
};

export const jobsRequestFailure = (error) => {
  return {
    type: JOBS_REQUEST_FAILURE,
    payload: error
  };
};

export const createJob = () => {
  return {
    type: CREATE_JOB
  }
};

export const createJobSuccess = (newJob) => {
  return {
    type: CREATE_JOB_SUCCESS,
    payload: newJob
  };
};

export const createJobFailure = (error) => {
  return {
    type: CREATE_JOB_FAILURE,
    payload: error
  };
};


export const loadJobToEditForm = (job) => {
  return {
    type: LOAD_JOB_TO_EDIT_FORM,
    payload: parseJobToForm(job)
  }
};

export const resetJobEditForm = () => {
  return {
    type: RESET_JOB_EDIT_FORM
  };
};

export const updateJobRequest = () => {
  return {
    type: UPDATE_JOB_REQUEST,
  }
};

export const updateJobRequestSuccess = (data) => {
  return {
    type: UPDATE_JOB_REQUEST_SUCCESS,
    payload: data
  };
};

export const updateJobRequestFailure = (error) => {
  return {
    type: UPDATE_JOB_REQUEST_FAILURE,
    payload: error
  };
};

export const resetNewJob = () => {
  return {
    type: RESET_NEW_JOB
  }
};

export const chooseJobForCallback = (job) => {
  return {
    type: CHOOSE_JOB_FOR_CALLBACK,
    payload: job
  }
}

export const loadAddressToForm = (data, key, formName=CALL_FORM_NAME) => {
  let address = parseAddress(data, key);
  return (dispatch, getState) => {
    const currentState = getState().form[formName].values;
    const data = Object.assign(currentState, address);
    return dispatch(loadAddress(data));
  }
};

export const jobForCallback = (job) => {
  return (dispatch, getState) => {
    const currentState = getState().form[CALL_FORM_NAME].values;
    const jobToForm = parseJobToForm(job);
    const data = {
      ...currentState,
      ...jobToForm,
      callback: true,
      appointmentDate: null,
      timeFrom: null,
      timeTo: null,
      estimator: CAN_ASSIGN_ESTIMATOR ? jobToForm.estimator : null
    };
    return dispatch(chooseJobForCallback(data));
  }
};
