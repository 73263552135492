import React from "react";
import PropTypes from 'prop-types';
import {Row, Col, ButtonGroup, Button, Glyphicon, ButtonToolbar, Label} from 'react-bootstrap';

import {
  JOB_STATUS_ESTIMATED, JOB_STATUS_SOLD,
  JOB_STATUS_LOST, JOB_STATUS_SCHEDULED,
} from "helpers/AppConstants"
import { CallbackCloseLink } from "shared/callback-close-link";
import { FaIcon } from "shared/fa-icon";
import { isCallbackClosed } from "helpers/Callback";
import { selectStatusLabelClass, checkJobStatus } from "helpers/Job";
import JobApi from "api/JobApi"

export class JobHeader extends React.Component {
  constructor(props) {
    super(props);
    this.renderButtons = this.renderButtons.bind(this);
    this.jobApi = new JobApi()
  }

  printButton() {
    const { job } = this.props;
    const title = "Print";
    return <Button title={title} href={this.jobApi.printUrl(job.id)} target="_blank">
      <FaIcon size="lg" icon="print" />
    </Button>
  }

  jobButtons() {
    const { job, openJobSoldModal, openJobEstimateModal, openJobLostModal } = this.props;
    const jobScheduled = checkJobStatus(job, JOB_STATUS_SCHEDULED);
    const jobEstimated = checkJobStatus(job, JOB_STATUS_ESTIMATED);
    const jobSold = checkJobStatus(job, JOB_STATUS_SOLD);
    const jobLost = checkJobStatus(job, JOB_STATUS_LOST);
    return (
      <ButtonGroup justified>
        <Button
          href="#" disabled={!jobScheduled} className="text-ellipsis"
          onClick={() => openJobEstimateModal(job)} title={JOB_STATUS_ESTIMATED}
        >
          {JOB_STATUS_ESTIMATED}
        </Button>
        <Button
          href="#" disabled={!jobEstimated} className="text-ellipsis"
          onClick={() => openJobSoldModal(job)} title={JOB_STATUS_SOLD}
        >
          {JOB_STATUS_SOLD}
        </Button>
        {/*<Button*/}
          {/*href="#" disabled={jobSold || jobLost} className="text-ellipsis"*/}
          {/*onClick={() => openJobLostModal(job)} title={JOB_STATUS_LOST}*/}
        {/*>*/}
          {/*{JOB_STATUS_LOST}*/}
        {/*</Button>*/}
        {this.printButton()}
      </ButtonGroup>
    );
  }

  callbackButtons() {
    const { job, convertCallbackToJob } = this.props;
    const disableCallbackButtons = isCallbackClosed(job);
    const createJobTitle = "Create a new Job";
    return (
      <ButtonGroup justified>
        <Button
          title={createJobTitle} className="text-ellipsis" disabled={disableCallbackButtons}
          href="#" onClick={() => convertCallbackToJob(job.id)}
        >
          {createJobTitle}
        </Button>
        <CallbackCloseLink disabled={disableCallbackButtons} callback={job} />
        {this.printButton()}
      </ButtonGroup>
    );
  }

  renderButtons() {
    const { job } = this.props;
    return (
      <ButtonToolbar>
        {job.callback ? this.callbackButtons() : this.jobButtons()}
      </ButtonToolbar>
    )
  }

  render() {
    const { job, loadJobToEditForm } = this.props;
    const titleType = job.callback ? 'Callback' : 'Job';
    return (
      <Row className="padding-bottom-10">
        <Col md={6}>
          <h3 className="margin-top-0">
            <a onClick={() => loadJobToEditForm(job)}>
              Edit {' '}<Glyphicon glyph="pencil" />
            </a>
            <Label bsStyle={selectStatusLabelClass(job)} className="pull-right">{job.status}</Label>
            {
              job.urgent &&
              <Label bsStyle='danger' className="pull-right urgent-label">Urgent</Label>
            }
          </h3>
        </Col>
        <Col md={6}>
          {this.renderButtons()}
        </Col>
      </Row>
    )
  }
}

JobHeader.propTypes = {
  job: PropTypes.object.isRequired
};
