import React from "react";
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { OverflowScroll } from "shared/oferflow-scroll";
import RenderJob from 'shared/render-job';

class ClientJobs extends React.Component {

  render() {
    const { jobs, client } = this.props;
    return (
      <div>
        <Row>
          <Col md={12}>
            <h3 className="margin-top-0">
              Jobs
                <Link className="btn btn-sm btn-success pull-right" to={{pathname: '/new_job', state: {client}}}>
                  Add new job or callback
                </Link>
            </h3>
          </Col>
        </Row>
        <OverflowScroll>
          {jobs.map(job => <RenderJob key={job.id} job={job} />)}
        </OverflowScroll>
      </div>
    )
  }
}
export default ClientJobs;
