import {
  formatTimeToTimePicker, formatDateToDatePicker,
  relevantDate, relevantTimeRange
} from "helpers/DateTimeHelpers";

export const formatTimeSlotsData = (data) => {
  if (!data) return;
  return  {
    start: formatTimeToTimePicker(data.start),
    end: formatTimeToTimePicker(data.end),
    date: formatDateToDatePicker(data.start)
  };
};

export const checkRelevantTime = (data) => {
  if (data && data.start && data.end) {
    return relevantDate(data) && relevantTimeRange(data);
  } else {
    return false;
  }
};

export const appointmentFormDataFromTimeSlots = (data = {}) => {
  return {
    timeFrom: data.start,
    timeTo: data.end,
    appointmentDate: data.date
  }
};

export const filterUserNotAvailableList = (list = [], userId) => {
  if (typeof userId === "number") {
    return list.filter(item => item.user.id === userId);
  }
  return list;
};
