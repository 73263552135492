import ApiClient from 'api/ApiClient'

class UserNotAvailableApi extends ApiClient {
  constructor() {
    super({url:'user_not_availables', dataWrapperKey: 'user_not_available' })
  }

  index(date) {
    return super.index({date})
  }

  userNotAvailableList(date) {
    return super.index({current_user: true, date})
  }

  deleteNotAvailableUser(notAvailableUserId) {
    return $.ajax({
      method: 'DELETE',
      url: `${this.setUrl()}/${notAvailableUserId}`,
    })
  }

}
export default UserNotAvailableApi
