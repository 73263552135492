import React from "react";
import PropTypes from 'prop-types';
import { Nav, NavItem, Badge } from 'react-bootstrap'

import { FaIcon } from "shared/fa-icon";
import { OverflowScroll } from "shared/oferflow-scroll";
import { UNASSIGNED_ESTIMATES, ALL_ASSIGNED_ESTIMATES } from "helpers/AppConstants";

class EstimatorsPanel extends React.Component {
  currentUser(estimators) {
    return estimators.find(estimator => estimator.current_user)
  }

  navItemContent(data, title) {
    return (
      <div className="calendar-nav-item relative-element">
        <span className="color-box" style={{background: data.color}} />  { title || data.name }
        <Badge className="position-right">{data.count}</Badge>
      </div>
    )
  }

  render() {
    const { estimators, onSelectNavItem, selectedItem, countAllAssigned, countUnassigned} = this.props;
    const currentUser = this.currentUser(estimators);
    return (
      <OverflowScroll>
        <Nav bsStyle="pills" stacked activeKey={selectedItem} onSelect={onSelectNavItem}>
          { currentUser && <NavItem eventKey={currentUser.id}>{this.navItemContent(currentUser, 'My calendar')}</NavItem> }
          <NavItem className="bottom-divider bolder-text" disabled>Others calendar <FaIcon icon="arrow-down" /></NavItem>
          { estimators.filter((item) => item !== currentUser).map((item) => {
            return <NavItem key={item.id} eventKey={item.id}>{this.navItemContent(item)}</NavItem>
          }) }
          <NavItem eventKey={ALL_ASSIGNED_ESTIMATES}>All assigned<Badge pullRight>{countAllAssigned}</Badge></NavItem>
          <NavItem eventKey={UNASSIGNED_ESTIMATES}>Unassigned<Badge pullRight>{countUnassigned}</Badge></NavItem>
        </Nav>
      </OverflowScroll>
    )
  }
}

EstimatorsPanel.defaultProps = {
  estimators: [],
  selectEstimator: (estimator) => console.log(estimator),
};

EstimatorsPanel.propTypes = {
  estimators: PropTypes.array.isRequired,
  onSelectNavItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.any,
  countAllEstimates: PropTypes.number,
  countUnassigned: PropTypes.number
};

export default EstimatorsPanel;
