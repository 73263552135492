import {
  updateClientRequest, updateClientRequestSuccess, updateClientRequestError,
  clientsRequest, clientsRequestSuccess, clientsRequestFailure
} from 'actions/clients-actions';

import { parseAddress, loadAddress } from 'actions/addresses';
import { onOtherOptionSelect, otherOptionValueIfNeed, setOtherField } from "helpers/FormHelpers";
import ClientApi from 'api/ClientApi';
import { normalizePhone } from "helpers/FormHelpers";
import { setAutocompleteMaxLength } from "helpers/SearchBar";

const clientApi = new ClientApi();

const initialContactData = [{}];

// select value for primary phones/emails radio buttons
export const primaryIndex = (arr) => {
  const index = arr.findIndex((el) => {
    return el.primary;
  });
  return index === -1 ? '0' : index.toString();
};

export const selectMainContact = (items, type='phone') => {
  if (!items || !items.length) return '';
  let contact = items.find((item => item.primary)) || items[0];
  const mainContact = contact[type];
  return type === 'phone' ? normalizePhone(mainContact) : mainContact;
};

export const selectAllContacts = (items, type='phone') => {
  if (!items || !items.length) return [];
  return items.filter(item => item[type] !== '');
};

const contactsArray = (obj, type) => {
  const key = type === 'phones' ? 'phone' : 'email';
  const primary_property = `primary_${key}`;
  const result = obj[type].map(item => {
    return {
      [key]: item[key],
      primary: false
    };
  });

  if (obj[primary_property]) {
    result[+obj[primary_property]].primary = true;
  }
  return JSON.stringify(result);
};

const preparePhonesToForm = (phones = []) => {
  if (!phones.length) return initialContactData;
  return phones.map((contact) => {
    return {
      ...contact,
      phone: normalizePhone(contact.phone)
    }
  })
};


export const clientAddress = (data, key) => {
  let address = parseAddress(data, key);
  return (dispatch, getState) => {
    let currentState = getState().form['edit-client-form'].values;
    let data = Object.assign(currentState, address);
    return dispatch(loadAddress(data));
  }
};

export const clientData = (data) => {
  return {
    id: data.client_id || data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    company_name: data.company_name,
    client_position_id: onOtherOptionSelect(data.position),
    client_position_other: setOtherField(data.position, data.client_position_other),
    referral_resource_id: onOtherOptionSelect(data.referralSource),
    referral_resource_other: setOtherField(data.referralSource, data.referral_resource_other, true),
    street: data.contact_address.street,
    city: data.contact_address.city,
    state: data.contact_address.state,
    zip: data.contact_address.zip,
    lat: data.contact_address.lat,
    lng: data.contact_address.lng,
    country: data.contact_address.country,
    phones: contactsArray(data, 'phones'),
    emails: contactsArray(data, 'emails'),
    primary_contact_form: data.primary_contact_form
  }
};

export const parseDataToForm = (data) => {
  if(!data) {
    return {
      phones: initialContactData,
      emails: initialContactData,
      primary_email: '0',
      primary_phone: '0'
    }
  }
  return {
    client_id: data.id,
    id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    company_name: data.company_name,
    position: otherOptionValueIfNeed(data, "client_position"),
    client_position_other: data.client_position_other,
    referralSource: otherOptionValueIfNeed(data, "referral_resource"),
    referral_resource_other: data.referral_resource_other,
    phones: preparePhonesToForm(data.phones),
    primary_phone: primaryIndex(data.phones),
    emails: data.emails && data.emails.length ? data.emails : initialContactData,
    primary_email: primaryIndex(data.emails),
    contact_address: data.address,
    primary_contact_form: data.primary_contact_form
  };
};

const transformToAutoComplete = (data=[]) => {
  setAutocompleteMaxLength(data);
  return data.map((item) => {
    const company = item.company_name ? `${item.company_name},` : '';
    return { ...item, name: `${item.name}, ${company} ${item.address.state}` }
  })
};

export const fetchClients = (params={}, callback) => {
  return function (dispatch) {
    dispatch(clientsRequest(params));
    return clientApi.index(params)
      .done(function(data) {
        dispatch(clientsRequestSuccess(data));
        if (params.autoComplete) data = transformToAutoComplete(data.clients);
        if (typeof callback === 'function') callback(data);
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(clientsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const updateClient = (client) => {
  let dataToSend = clientData(client);
  return clientUpdateRequest(dataToSend)
};

export const toggleArchive = (client) => {
  let dataToSend = {
    id: client.id,
    archived: !client.archived
  };
  return clientUpdateRequest(dataToSend);
};

const clientUpdateRequest = (data) => {
  return function (dispatch) {
    dispatch(updateClientRequest());
    return clientApi.update(data)
      .done(function(data) {
        dispatch(updateClientRequestSuccess(data));
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(updateClientRequestError(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

