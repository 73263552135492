import {
  LOST_REASONS_REQUEST, LOST_REASONS_REQUEST_SUCCESS, LOST_REASONS_REQUEST_FAILURE
} from "actions/lost-reasons-actions";

const INITIAL_STATE = { list: [], error: null, loading: false };

const LostReasonsReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case LOST_REASONS_REQUEST:
      return {...state, error: null, loading: true};
    case LOST_REASONS_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false};
    case LOST_REASONS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};

    default:
      return state;
  }
};

export default LostReasonsReducer
