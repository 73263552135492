import React from "react";
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Row, Col, ButtonToolbar, Well } from 'react-bootstrap'

import ClientInfo from "shared/client-info";
import ContactInfo from "shared/contact-info";
import JobInfo from "shared/job-input-fields";
import ClientNote from "./note";
import JobsPanel from './jobs-panel'
import { loadClientToJobForm } from 'actions/clients-actions'
import { appointmentTimeFrame } from "helpers/DateTimeHelpers";
import { fetchClientJobs } from "api/job";
import { validate } from 'form-validations/validation-call-form'
import { CALL_FORM_NAME } from 'helpers/FormNames';
import { SCROLL_HEIGHT_TO_FIX_NOTE_INPUT } from "helpers/AppConstants";
import { calendarPath, redirectTo } from "helpers/PathHelpers";

class NewJob extends React.Component {
  constructor(props) {
    super(props);
    this.defaultTimeFrame = this.defaultTimeFrame.bind(this)
    this.searchClients = this.searchClients.bind(this)
    this.onClientSelect = this.onClientSelect.bind(this)
    this.jobForCallback = this.jobForCallback.bind(this)
    this.selectSubmit = this.selectSubmit.bind(this)
    this.clientFromLocation = this.clientFromLocation.bind(this);
  }

  componentDidMount() {
    this.fixedNoteOnScroll();
    const { load } = this.props;
    const client = this.clientFromLocation();
    load(client);
    if(client) this.scrollToJob()
  }

  clientFromLocation() {
    return this.props.location && this.props.location.state && this.props.location.state.client;
  }

  scrollToJob() {
    let jobInfo = document.getElementById("job-info");
    try {
      jobInfo.scrollIntoView({behavior: 'smooth', block: 'start'});
    } catch(e) {
      console.log(e);
      jobInfo.scrollIntoView()
    }
  }

  fixedNoteOnScroll() {
    let note = this.refs.note;
    window.addEventListener('scroll', () => {
      if(window.scrollY > SCROLL_HEIGHT_TO_FIX_NOTE_INPUT) {
        note.classList.add('note-fixed');
      } else {
        note.classList.remove('note-fixed')
      }
    })
  }

  defaultTimeFrame (value) {
    appointmentTimeFrame(value, this.props)
  }

  searchClients(data, callback) {
    setTimeout(()=> {
      const { first_name, last_name, company_name, searchClients } = this.props;
      const params = {
        search_by: 'fields',
        first_name: first_name,
        last_name: last_name,
        company_name: company_name,
        autoComplete: true
      };
      searchClients(params, callback)
    })
  }

  onClientSelect(client) {
    const { load, dispatch } = this.props;
    load(client);
    dispatch(fetchClientJobs(client.id));
    this.scrollToJob();
  }

  jobForCallback(job) {
    const { jobForCallback } = this.props;
    jobForCallback(job);
    this.scrollToJob();
  }

  selectSubmit(data) {
    const { job, addJob, addCallback } = this.props;
    const callback = () => redirectTo(calendarPath());
    if (job.data.callback) {
      addCallback(data, callback)
    } else  {
      addJob(data, callback);
    }
  }

  render() {
    const { disableAddressInput, handleSubmit, pristine, reset, submitting, clientOptions,
            jobOptions, loadAddress, copyClientAddressToJob,
            jobs, job } = this.props;
    const callback = job.data.callback;
    const submitButtonName = callback ? 'Add callback' : 'Save';
    const disableSubmitButton = job.disableSubmitButton;
    return (
      <div className="call-form">
        {/*<h2 className="page-title">Call Form</h2>*/}
        <form onSubmit={handleSubmit(this.selectSubmit)}  autoComplete="off" className="validation-inputs">
          <Row>
            <Col md={8}>
              <ClientInfo
                clientOptions={clientOptions}
                enableAutocomplete
                loadClient={this.onClientSelect}
                searchClients={this.searchClients}
              />
              <ContactInfo
                loadAddress={loadAddress}
                copyClientAddressToJob={copyClientAddressToJob}
                disableAddressInput={disableAddressInput}
                disableJobAddressForm={job.disableJobAddressForm}
              />
              <JobInfo jobOptions={jobOptions}
                loadAddress={loadAddress}
                disableFields={callback}
                copyClientAddressToJob={copyClientAddressToJob}
                appointmentTimeFrame={this.defaultTimeFrame}
                disableAddressInput={disableAddressInput}
                disableJobAddressForm={job.disableJobAddressForm}
              />
            </Col>
            <Col md={4}>
              <div ref="note" id="fixed-on-scroll">
                <Well>
                  <ClientNote/>
                  <ButtonToolbar className="flex-center margin-top-5">
                    <button className="btn btn-success" type="submit" disabled={submitting || disableSubmitButton}>
                      {submitButtonName}
                      </button>
                    <button className="btn btn-danger" type="button" disabled={pristine || submitting} onClick={reset}>
                      Cancel
                    </button>
                  </ButtonToolbar>
                </Well>
                <JobsPanel jobs={jobs.list} jobForCallback={this.jobForCallback} />
              </div>
            </Col>
          </Row>
        </form>
      </div>
    )
  }
}

NewJob = reduxForm({
  form: CALL_FORM_NAME,
  validate,
  enableReinitialize : true
})(NewJob);

const selector = formValueSelector('call-form');
NewJob = connect(state => {
  const { first_name, last_name, company_name } = selector(state, 'first_name', 'last_name', 'company_name');
  return {
    first_name,
    last_name,
    company_name
  };
})(NewJob);

NewJob = connect(
  state => ({
    initialValues: state.job.data,
  }),
  { load: loadClientToJobForm },
)(NewJob);

export default NewJob;
