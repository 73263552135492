import {
  MATERIAL_BRANDS_REQUEST,
  MATERIAL_BRANDS_REQUEST_SUCCESS,
  MATERIAL_BRANDS_REQUEST_FAILURE
} from "actions/material-brands-actions";

const INITIAL_STATE = { list: [], error: null, loading: false };

const MaterialBrandsReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case MATERIAL_BRANDS_REQUEST:
      return {...state, error: null, loading: true};
    case MATERIAL_BRANDS_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false};
    case MATERIAL_BRANDS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};

    default:
      return state;
  }
};

export default MaterialBrandsReducer
