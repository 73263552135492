import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import NewJobReducer from './new-job-reducer'
import { JobsReducer } from "./jobs-list-reducer";

const CallFormReducer = combineReducers({
  job: NewJobReducer,
  jobs: JobsReducer,
  form: reduxFormReducer
});

export default CallFormReducer