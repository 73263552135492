import { createStore, compose } from "redux"

import GeneralSearchBarReducer from "reducers/general-search-bar-reducer"
import configureMiddleware from "store/configure-middleware";

const configureGeneralSearchBarStore = preloadedState => {
  const store = createStore(
    GeneralSearchBarReducer,
    preloadedState,
    compose(
      configureMiddleware()
    )
  );

  return store
};

export default configureGeneralSearchBarStore
