import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, Well } from 'react-bootstrap'

import { RenderField } from "shared/render-field";
import { AutoSuggestInput } from "shared/autosuggest-input";
import SelectFieldWithSpecifyInput from "shared/select-field-with-specify-input";

export default class ClientInfo extends React.Component {
  renderSimpleFields() {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Field label="First Name" name="first_name" component={RenderField} type="text"/>
          </Col>
          <Col sm={6}>
            <Field label="Last Name" name="last_name" component={RenderField} type="text"/>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field label="Company name" name="company_name" component={RenderField} type="text"/>
          </Col>
        </Row>
      </div>
    )
  }

  renderAutoCompleteField(label, name) {
    const { searchClients, loadClient } = this.props;
    return (
      <AutoSuggestInput
        label={label} name={name}
        handleChange={searchClients}
        onSelect={loadClient}
      />
    )
  }

  renderAutoCompleteFields() {
    return (
      <div>
        <Row>
          <Col sm={6}>
            { this.renderAutoCompleteField("First Name", "first_name") }
          </Col>
          <Col sm={6}>
            { this.renderAutoCompleteField("Last Name", "last_name") }
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            { this.renderAutoCompleteField("Company name", "company_name") }
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const { clientOptions, hideOptions, enableAutocomplete } = this.props;
    return (
      <div>
        <Well>
          {/*<h3>Client info</h3>*/}
          { enableAutocomplete ? this.renderAutoCompleteFields() : this.renderSimpleFields() }
          { !hideOptions &&
          <Row>
            <Col sm={6}>
              <SelectFieldWithSpecifyInput
                label="Position"
                fieldName="position"
                specifyFieldName="client_position_other"
                options={clientOptions.positions}
              />
            </Col>
            <Col sm={6}>
              <SelectFieldWithSpecifyInput
                label="Referral Source"
                fieldName="referralSource"
                specifyFieldName="referral_resource_other"
                options={clientOptions.referral_resources}
                alwaysShowOtherField
              />
            </Col>
          </Row> }

        </Well>
      </div>
    )
  }
}
