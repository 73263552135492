import JobApi from "api/JobApi";
import { jobEstimateParams, jobSoldParams } from "helpers/Job";

export const OPEN_JOB_ESTIMATE_MODAL = 'OPEN_JOB_ESTIMATE_MODAL';
export const CLOSE_JOB_ESTIMATE_MODAL = 'CLOSE_JOB_ESTIMATE_MODAL';
export const JOB_ESTIMATE_REQUEST = 'JOB_ESTIMATE_REQUEST';
export const JOB_ESTIMATE_REQUEST_SUCCESS = 'JOB_ESTIMATE_REQUEST_SUCCESS';
export const JOB_ESTIMATE_REQUEST_FAILURE = 'JOB_ESTIMATE_REQUEST_FAILURE';

export const OPEN_JOB_SOLD_MODAL = 'OPEN_JOB_SOLD_MODAL';
export const CLOSE_JOB_SOLD_MODAL = 'CLOSE_JOB_SOLD_MODAL';
export const JOB_SOLD_REQUEST = 'JOB_SOLD_REQUEST';
export const JOB_SOLD_REQUEST_SUCCESS = 'JOB_SOLD_REQUEST_SUCCESS';
export const JOB_SOLD_REQUEST_FAILURE = 'JOB_SOLD_REQUEST_FAILURE';

export const SOLD_DETAILS_REQUEST = 'SOLD_DETAILS_REQUEST';
export const SOLD_DETAILS_REQUEST_SUCCESS = 'SOLD_DETAILS_REQUEST_SUCCESS';
export const SOLD_DETAILS_REQUEST_FAILURE = 'SOLD_DETAILS_REQUEST_FAILURE';

export const openJobEstimateModal = (job) => {
  return {
    type: OPEN_JOB_ESTIMATE_MODAL,
    payload: job
  }
};

export const closeJobEstimateModal = () => {
  return {
    type: CLOSE_JOB_ESTIMATE_MODAL
  };
};

export const jobEstimateRequest = () => {
  return {
    type: JOB_ESTIMATE_REQUEST,
  }
};

const jobEstimateRequestSuccess = (data) => {
  return {
    type: JOB_ESTIMATE_REQUEST_SUCCESS,
    payload: data
  };
};

const jobEstimateRequestFailure = (error) => {
  return {
    type: JOB_ESTIMATE_REQUEST_FAILURE,
    payload: error
  };
};

export const openJobSoldModal = (job) => {
  return {
    type: OPEN_JOB_SOLD_MODAL,
    payload: job
  }
};

export const closeJobSoldModal = () => {
  return {
    type: CLOSE_JOB_SOLD_MODAL
  };
};

const jobSoldRequest = () => {
  return {
    type: JOB_SOLD_REQUEST,
  }
};

const jobSoldRequestSuccess = (data) => {
  return {
    type: JOB_SOLD_REQUEST_SUCCESS,
    payload: data
  };
};

const jobSoldRequestFailure = (error) => {
  return {
    type: JOB_SOLD_REQUEST_FAILURE,
    payload: error
  };
};

const soldDetailRequest = () => {
  return {
    type: SOLD_DETAILS_REQUEST,
  }
};

const soldDetailRequestSuccess = (data) => {
  return {
    type: SOLD_DETAILS_REQUEST_SUCCESS,
    payload: data
  };
};

const soldDetailRequestFailure = (error) => {
  return {
    type: SOLD_DETAILS_REQUEST_FAILURE,
    payload: error
  };
};


const jobApi = new JobApi();

export const estimateJob = (jobParams, callback) => {
  const dataToSend = jobEstimateParams(jobParams);
  return (dispatch) => {
    dispatch(jobEstimateRequest());
    return jobApi.estimate(dataToSend)
      .done(function(data) {
        dispatch(jobEstimateRequestSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(jobEstimateRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const soldJob = (jobParams, callback) => {
  const dataToSend = jobSoldParams(jobParams);
  return (dispatch) => {
    dispatch(jobSoldRequest());
    return jobApi.soldJob(dataToSend)
      .done(function(data) {
        dispatch(jobSoldRequestSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(jobSoldRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const soldDetails = (jobId) => {
  return (dispatch) => {
    dispatch(soldDetailRequest());
    return jobApi.soldDetails(jobId)
      .done(function(data) {
        dispatch(soldDetailRequestSuccess(data));
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(soldDetailRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
