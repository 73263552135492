import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";

import Wrapper from "statistics/wrapper"
import {
  JOB_STATUS_UNASSIGNED, JOB_STATUS_ASSIGNED, JOB_STATUS_SCHEDULED, JOB_STATUS_ESTIMATED,
  JOB_STATUS_SOLD, JOB_STATUS_LOST, CALLBACK_STATUS_OPEN, CALLBACK_STATUS_CLOSED
} from "helpers/AppConstants";

class StackedStatusesChart extends React.Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.setData = this.setData.bind(this);
    this.formatSeries = this.formatSeries.bind(this);
  }

  componentDidMount() {
    const { chartData } = this.props;
    this.renderChart();
    this.setData(chartData);
  }

  componentWillUpdate(nextProps) {
    const { chartData } = nextProps;
    if (chartData !== this.props.chartData) this.setData(chartData);
  }

  getCategories(chartData) {
    return chartData.map((item) => {
      return item[0] ?  item[0] : 'Empty';
    })
  }

  formatSeries(chartData) {
    const series = this.initialSeries();
    chartData.map((dataRow) => {
      this.addDataToSeria(series, JOB_STATUS_UNASSIGNED, dataRow);
      this.addDataToSeria(series, JOB_STATUS_ASSIGNED, dataRow);
      this.addDataToSeria(series, JOB_STATUS_SCHEDULED, dataRow);
      this.addDataToSeria(series, JOB_STATUS_ESTIMATED, dataRow);
      this.addDataToSeria(series, JOB_STATUS_SOLD, dataRow);
      this.addDataToSeria(series, JOB_STATUS_LOST, dataRow);
      this.addDataToSeria(series, CALLBACK_STATUS_OPEN, dataRow);
      this.addDataToSeria(series, CALLBACK_STATUS_CLOSED, dataRow);
    });
    return series
  }

  addDataToSeria(series, nameKey, dataRow) {
    return series.find((item) => item.name === nameKey).data.push(this.getCounts(dataRow[1], nameKey))
  }

  getCounts(data, key) {
    return data[key] || 0
  }

  initialSeries() {
    return [
      {
        name: JOB_STATUS_UNASSIGNED,
        data: []
      },
      {
        name: JOB_STATUS_ASSIGNED,
        data: []
      },
      {
        name: JOB_STATUS_SCHEDULED,
        data: []
      },
      {
        name: JOB_STATUS_ESTIMATED,
        data: []
      },
      {
        name: JOB_STATUS_SOLD,
        data: []
      },
      {
        name: JOB_STATUS_LOST,
        data: []
      },
      {
        name: CALLBACK_STATUS_OPEN,
        data: []
      },
      {
        name: CALLBACK_STATUS_CLOSED,
        data: []
      }
    ].reverse()
  }

  setData(chartData) {
    const { xAxisTitle } = this.props;
    this.chart.update({
      xAxis: {
        title: {
          text: xAxisTitle
        },
        categories: this.getCategories(chartData)
      },
      series: this.formatSeries(chartData)
    }, true)
  }

  renderChart() {
    const { chartType } = this.props;
    this.chart =  Highcharts.chart(this.refs.chart, {
      chart: {
        type: chartType
      },
      title: {
        text: ''
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Jobs count'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
          }
        }
      },
      legend: {
        reversed: true
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: this.initialSeries()
    });
  }

  render() {
    const { loading } = this.props;
    return (
      <Wrapper loading={loading}>
        <div ref="chart" />
      </Wrapper>
    )
  }
}

StackedStatusesChart.defaultProps = {
  chartData: [],
  loading: false,
  chartType: 'bar',
  xAxisTitle: ''
};

StackedStatusesChart.propTypes = {
  chartData: PropTypes.array,
  loading: PropTypes.bool,
  chartType: PropTypes.string,
  xAxisTitle: PropTypes.string
};

export default StackedStatusesChart;
