import React from "react";
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Row, Col, Clearfix } from 'react-bootstrap';

import { RenderTextArea, RenderSelect } from "shared/render-field";
import { estimators, currentUser } from "helpers/User";

import { validate } from 'form-validations/validation-callback-form';
import { CALLBACK_FORM_NAME } from "helpers/FormNames";
import { CAN_ASSIGN_ESTIMATOR } from "helpers/Permissions";

class CallbackForm extends React.Component {
  componentDidMount() {
    const { autofill } = this.props;
    if (!CAN_ASSIGN_ESTIMATOR) autofill('estimator', currentUser().id);
  }

  render() {
    const { submitting, handleSubmit, users } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off" className="note-form">
        <Row>
          <Col md={5}>
            <Field name="estimator" label="Assign to" component={RenderSelect} options={estimators(users)} />
          </Col>
          <Clearfix />
          <div className="flex-center-align">
            <Col md={9}>
              <Field name="note" label="Comments" component={RenderTextArea} />
            </Col>
            <Col md={3}>
              <button className="btn btn-success" type="submit" disabled={submitting}>Add a callback</button>
            </Col>
          </div>
        </Row>
      </form>
    )
  }
}

CallbackForm = reduxForm({
  form: CALLBACK_FORM_NAME,
  validate,
  enableReinitialize: true
})(CallbackForm);

CallbackForm = connect(
  state => ({
    initialValues: {
      estimator: state.job.data.user && state.job.data.user.id
    },
  }))(CallbackForm);

CallbackForm.defaultProps = {
  users: []
};

CallbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

export default CallbackForm
