import React from "react";
import { Table } from 'react-bootstrap'
import { Appointment } from '../shared/appointment'

class AssignedEstimates extends React.Component {
  constructor (props) {
    super(props)
  }

  renderTableRow(estimate) {
    const { showEstimator, selectEstimate } = this.props;
    return (
      <tr key={estimate.id}>
        <td>{ estimate.client && estimate.client.name }</td>
        <td>{ estimate.address && estimate.address.city }</td>
        {showEstimator && <td>{ estimate.user && estimate.user.name }</td>}
        <td className="appointment-form">
            <Appointment {...this.props} estimate={estimate} onSelect={()=> selectEstimate(estimate)} />
        </td>
      </tr>
    )
  }

  render() {
    const { estimates, showEstimator } = this.props;
    return (
      <Table striped bordered condensed hover>
        <thead>
        <tr>
          <th>Client's name</th>
          <th>Location</th>
          {showEstimator && <th>Estimator</th>}
          <th className="column-with-form">Appointment time</th>
        </tr>
        </thead>
        <tbody>
        { estimates.map((estimate) => this.renderTableRow(estimate)) }
        </tbody>
      </Table>
    )
  }
}

export default AssignedEstimates;


