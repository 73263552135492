import React from 'react';
import moment from 'moment';
import { Field } from 'redux-form';
import { Row, Col, Well } from 'react-bootstrap'
import { RenderSelect, RenderCheckbox, RenderControlledCheckbox, renderDatePicker } from 'shared/render-field'
import AddressInputs from 'shared/address-inputs'
import { AppointmentTimeInputs } from "shared/appointment-time-inputs";
import { estimators } from "helpers/User";
import SelectFieldWithSpecifyInput from "shared/select-field-with-specify-input";

class JobInputFields extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheckCopyClientAddressToJob = this.handleCheckCopyClientAddressToJob.bind(this);
  }

  handleCheckCopyClientAddressToJob(val, copyClientAddress) {
    this.props.disableAddressInput(val);
    val && copyClientAddress();
  }

  render() {
    const {
      jobOptions, appointmentTimeFrame, copyClientAddressToJob, copyClientAddressToEditJob, disableFields,
      showCopyClientLocation, disableEstimatorField, disableAppointmentFields, disableJobAddressForm
    } = this.props;
    return (
      <div id="job-info">
        <Well>
          {/*<h3>Job Info</h3>*/}
          <AddressInputs
            type="job"
            loadAddress={this.props.loadAddress}
            disableFields={disableJobAddressForm}>
            {showCopyClientLocation && (
              <Field
                label="Same as Contact location"
                name="contact_location"
                checked={disableJobAddressForm}
                disabled={disableFields}
                onChange={(_, val) => this.handleCheckCopyClientAddressToJob(val, copyClientAddressToJob)}
                component={RenderControlledCheckbox}
              />
            )}
            {!showCopyClientLocation && (
              <Field
                label="Same as Contact location"
                name="contact_location"
                disabled={disableFields}
                checked={disableJobAddressForm}
                onChange={(_, val) => this.handleCheckCopyClientAddressToJob(val, copyClientAddressToEditJob)}
                component={RenderControlledCheckbox}
              />
            )}
          </AddressInputs>
          <Row>
            <Col md={6}>
              <SelectFieldWithSpecifyInput
                label="Type of Building"
                fieldName="buildingType"
                specifyFieldName="building_type_other"
                disabled={disableFields}
                options={jobOptions.building_types}
              />
              <SelectFieldWithSpecifyInput
                label="Type of Roof"
                fieldName="roofType"
                specifyFieldName="roof_type_other"
                disabled={disableFields}
                options={jobOptions.roof_types}
              />
              <SelectFieldWithSpecifyInput
                label="Type of Job"
                fieldName="jobType"
                specifyFieldName="job_type_other"
                disabled={disableFields}
                options={jobOptions.job_types}
              />
            </Col>
            <Col md={4}>
              <Field
                label="Number of Stories"
                name="storiesCount"
                disabled={disableFields}
                component={RenderSelect}
                options={jobOptions.stories_counts}
              />
              <SelectFieldWithSpecifyInput
                label="Roof Access"
                fieldName="roofAccess"
                specifyFieldName="roof_access_other"
                disabled={disableFields}
                options={jobOptions.roof_accesses}
              />
              <Field label="Urgent Job?" name="urgent" disabled={disableFields} component={RenderCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Field
                label="Requested Estimator"
                name="requestedEstimator"
                disabled={disableFields}
                component={RenderSelect}
                options={jobOptions.estimators}
              />
            </Col>
            <Col md={4}>
              <Field
                label="Requested Appointment Date"
                name="requestedAppointmentDate"
                disabled={disableFields}
                component={renderDatePicker}
                normalize={ (value) =>  moment(value) }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Field
                label="Assigned Estimator"
                name="estimator"
                component={RenderSelect}
                options={estimators(jobOptions.estimators)}
                disabled={disableEstimatorField}
              />
            </Col>
            <Col md={4}>
              <Field
                label="Appointment Date"
                name="appointmentDate"
                component={renderDatePicker}
                normalize={ (value) =>  moment(value) }
                disablePrevious
                disabled={disableAppointmentFields}
              />
            </Col>
            <AppointmentTimeInputs
              size={2}
              appointmentTimeFrame={appointmentTimeFrame}
              disableFields={disableAppointmentFields}
            />
          </Row>
        </Well>
      </div>
    )
  }
}

JobInputFields.defaultProps = {
  showCopyClientLocation: true,
  disableAppointmentFields: false,
  disableEstimatorField: false
};

export default JobInputFields;
