import { connect } from 'react-redux';

import EstimatesList from '../presentational/estimates/estimates-component';
import { fetchEstimates, assignEstimator, setAppointment, unassignEstimator } from '../api/estimate'
import { fetchEstimators } from "actions/user-actions";
import { selectEstimate, resetSelectedEstimate } from "../actions/estimates-actions";


const mapDispatchToProps = (dispatch) => {
  return {
    fetchEstimates: (params) => {
      dispatch(fetchEstimates(params))
    },
    assignEstimator: (data, callback) => {
      dispatch(assignEstimator(data, callback))
    },
    unassignEstimator: (data, callback) => {
      dispatch(unassignEstimator(data, callback))
    },
    setAppointment: (data, callback) => {
      dispatch(setAppointment(data, callback))
    },
    fetchEstimatesSidebar: () => {
      dispatch(fetchEstimators())
    },
    selectEstimate: (data) => {
      dispatch(selectEstimate(data))
    },
    resetSelectedEstimate: () => {
      dispatch(resetSelectedEstimate())
    }
  }
};


function mapStateToProps(state) {
  return {
    estimates: state.estimates,
    sidebarData: state.sidebarData
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatesList);

