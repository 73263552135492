import React from "react";
import { Button, ButtonToolbar } from 'react-bootstrap'
import Modal from 'react-responsive-modal';
import {formValueSelector, reduxForm} from "redux-form";
import { connect } from 'react-redux';

import { JOB_EDIT_FORM_NAME } from "helpers/FormNames";
import { validate } from 'form-validations/validation-job-edit-form'
import { appointmentTimeFrame } from "helpers/DateTimeHelpers";
import { loadAddressToForm } from "actions/jobs-actions";
import JobInputFields from 'shared/job-input-fields';
import { CAN_ASSIGN_ESTIMATOR, canEditAppointment } from "helpers/Permissions";

class EditJobModal extends React.Component {
  constructor(props) {
    super(props);
    this.loadAddress = this.loadAddress.bind(this);
    this.defaultTimeFrame = this.defaultTimeFrame.bind(this);
  }

  componentWillUnmount() {
    Modal.prototype.componentWillUnmount = () => {}
  }

  loadAddress(data, key) {
    const { dispatch } = this.props;
    dispatch(loadAddressToForm(data, key, JOB_EDIT_FORM_NAME))
  }

  defaultTimeFrame (value) {
    appointmentTimeFrame(value, this.props)
  }

  render() {
    const { show, close, submitting, handleSubmit, jobOptions, estimator, copyClientAddressToEditJob, job, disableAddressInput } = this.props;
    return (
      <Modal closeOnEsc={false} closeOnOverlayClick={false} open={show} onClose={close}>
        <form onSubmit={handleSubmit}>
          <JobInputFields
            copyClientAddressToEditJob={copyClientAddressToEditJob}
            jobOptions={jobOptions}
            loadAddress={this.loadAddress}
            appointmentTimeFrame={this.defaultTimeFrame}
            showCopyClientLocation={false}
            disableEstimatorField={!CAN_ASSIGN_ESTIMATOR}
            disableAppointmentFields = {!canEditAppointment(estimator)}
            addressSameAsContactLocation={job ? job.data.address_same_as_contact_location : null}
            disableAddressInput={disableAddressInput}
            disableJobAddressForm={job ? job.data.disableJobAddressForm : null}
          />
          <ButtonToolbar className="pull-right">
            <Button onClick={close}>Close</Button>
            <button className="btn btn-primary" type="submit" disabled={submitting}>Save</button>
          </ButtonToolbar>
        </form>
      </Modal>
    )
  }
}

EditJobModal = reduxForm({
  form: JOB_EDIT_FORM_NAME,
  validate,
  enableReinitialize : true
})(EditJobModal);

const selector = formValueSelector(JOB_EDIT_FORM_NAME);
EditJobModal = connect(state => {
  const  estimator = selector(state, 'estimator');
  return {
    estimator
  };
})(EditJobModal);

EditJobModal = connect(
  state => ({
    initialValues: state.editJob.data,
  })
)(EditJobModal);

export default EditJobModal;

