import React from "react";
import { Provider } from "react-redux";

import configureClientsListStore from "app/store/configureClientsListStore";
import ClientsList from "app/containers/ClientsListContainer";

const store = configureClientsListStore();

class Clients extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ClientsList clientOptions={this.props.client_options}
                     filters={this.props.filters} />
      </Provider>
    )
  }
}
export default Clients;


