import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form'

import { LOAD_CLIENT, LOAD_CLIENT_TO_JOB_FORM, UPDATE_CLIENT_REQUEST,
         UPDATE_CLIENT_REQUEST_SUCCESS, UPDATE_CLIENT_REQUEST_ERROR
       } from 'actions/clients-actions';


import { JobsReducer } from "./jobs-list-reducer";
import NotesReducer from './notes-reducer'
import NewJobReducer from './new-job-reducer'
import TodosReducers from './todos-reducers'
import UsersReducer from './users-reducer'
import EditClientReducer from './edit-client-reducer'
import CallbacksReducer from "./callbacks-reducer";
import ActivityLogReducer from "reducers/activity-log-reducer";

const INITIAL_STATE = { data: {}, error: null, loading: false };

const ClientReducers = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case LOAD_CLIENT:
      return {...state, data: action.payload };

    case UPDATE_CLIENT_REQUEST_SUCCESS:
      return {...state, data: action.payload };
    case UPDATE_CLIENT_REQUEST_ERROR:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    default:
      return state;
  }
};

const ClientInfoReducers = combineReducers({
  client: ClientReducers,
  editClient: EditClientReducer,
  job: NewJobReducer,
  jobs: JobsReducer,
  notes: NotesReducer,
  todos: TodosReducers,
  users: UsersReducer,
  callbacks: CallbacksReducer,
  activityLog: ActivityLogReducer,
  form: reduxFormReducer,
});

export default ClientInfoReducers
