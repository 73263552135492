import ApiClient from './ApiClient'

class JobApi extends ApiClient {
  constructor() {
    super({url:'jobs', dataWrapperKey: 'job'})
  }

  clientJobs(id) {
   return super.show(id, 'client_jobs')
  }

  estimates(params) {
    return super.index(params, 'estimates')
  }

  callbacks(id) {
    return super.getNestedAction(id, '/callbacks')
  }

  assignEstimator(data) {
    return super.putNestedAction('/assign_estimator', data)
  }

  unassignEstimator(data) {
    return super.putNestedAction('/unassign_estimator', data)
  }

  addAppointment(data) {
    return super.putNestedAction('/add_appointment', data)
  }

  addCallback(params) {
    return super.postNestedAction('/add_callback', params)
  }

  convertCallbackToJob(callbackId) {
    return super.putNestedAction('/convert_callback_to_job', {id: callbackId})
  }

  estimate(params) {
    return super.putNestedAction('/estimate', params)
  }

  soldJob(saleParams) {
    return super.postNestedAction('/sale', saleParams, 'sale')
  }

  soldDetails(jobId) {
    return super.getNestedAction(jobId, '/sale')
  }

  lost(jobLostParams) {
    return super.putNestedAction('/lost', jobLostParams)
  }

  printUrl(jobId) {
    return `${this.setUrl(this.url)}/${jobId}/print`;
  }

  csvUrl(params) {
    const url = `${this.setUrl(this.url)}.csv`;
    return this.addParamsToUrl(url, params);
  }
}

export default JobApi
