import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { RenderField, RenderSelect} from "shared/render-field";
import { OTHER_SELECT_OPTION } from "helpers/AppConstants";
import { perfomAsync } from "helpers/Async";

class SelectFieldWithSpecifyInput extends React.Component {
  constructor(props) {
    super(props);
    this.onMount = this.onMount.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.canShowSpecifyField = this.canShowSpecifyField.bind(this);
    this.state = {
      showSpecifyField: false,
      value: null
    }
  }

  componentDidMount() {
    this.onMount();
  }

  onMount() {
    const value = this.refs.inputField.value;
    this.canShowSpecifyField(value)
  }

  componentDidUpdate() {
    perfomAsync(() => {
      const inputValue = this.refs.inputField.value;
      const stateValue = this.state.value;
      if (inputValue !== stateValue) this.canShowSpecifyField(inputValue)
    })
  }

  handleChange(e) {
    const value = e.target.value;
    this.canShowSpecifyField(value);
  }

  canShowSpecifyField(value) {
    const { alwaysShowOtherField } = this.props;
    let showSpecifyField = false;
    if (value) {
      showSpecifyField = alwaysShowOtherField ? true : value === OTHER_SELECT_OPTION;
    } else {
      showSpecifyField = false
    }
    this.setState({showSpecifyField: showSpecifyField, value})
  }

  render() {
    const { label, fieldName, options, specifyFieldName, disabled } = this.props;
    const { showSpecifyField } = this.state;
    return (
      <div>
        <Field
          ref="inputField"
          label={label}
          name={fieldName}
          component={RenderSelect}
          options={options}
          onChange={this.handleChange}
          disabled={disabled}
          showOtherOption
        />
        {
          showSpecifyField &&
          <div className="specify-input">
            <Field
              label="Please Specify"
              name={specifyFieldName}
              component={RenderField}
              type="text"
              focus={focus}
            />
          </div>
        }
      </div>
    )
  }
}

SelectFieldWithSpecifyInput.defaultProps = {
  label: '',
  disabled: false,
  alwaysShowOtherField: false
};

SelectFieldWithSpecifyInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  alwaysShowOtherField: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  specifyFieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectFieldWithSpecifyInput
