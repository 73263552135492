import React from "react";
import { Loader } from 'react-loaders'

const Spinner = props => {
  const type = props.search ? 'search-loader' : 'page-loader';
  return (
    <div className={ `${type} ${props.active ? 'loader-container-show': 'loader-container-hide'}` }>
      <Loader type="ball-spin-fade-loader" active />
      <div className="loader-text">
        {props.children}
      </div>
    </div>
  )
};
export default Spinner;

