import React from "react";
import { Row, Col, Well } from "react-bootstrap"

import { DescriptionRow } from "shared/description-row";
import { Materials } from "job-show/materials";
import { selectOptionOrOther } from "helpers/ViewHelpers";;

export const SoldDetails = props => {
  const { soldDetails } = props;
  return (
    <Well>
      <Row>
        <Col md={12}>
          <h4>
            <span className="italic-font text-muted">Sold Details</span>
          </h4>
        </Col>
        <Row>
          <Col md={5}>
            <DescriptionRow name="Sold date" value={soldDetails.job_sold_date}/>
            <DescriptionRow name="Estimator" value={soldDetails.estimator && soldDetails.estimator.name}/>
            <DescriptionRow name="Roof type" value={selectOptionOrOther("sale_roof_type", soldDetails)}/>
          </Col>
          <Col md={7}>
            <DescriptionRow name="Total Pitch Square" value={soldDetails.total_pitch_square}/>
            <DescriptionRow name="Total Flat Square" value={soldDetails.total_flat_square}/>
            <DescriptionRow name="Roof Pitch" value={selectOptionOrOther("roof_pitch", soldDetails)}/>
          </Col>
        </Row>
        <Col md={12} className="margin-top-10">
          <div><b>Estimate Notes</b></div>
          <p>{soldDetails.note}</p>
        </Col>
        <Col md={12}><Materials materials={soldDetails.materials} /></Col>
      </Row>
    </Well>
  )
};
