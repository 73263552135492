import { reset } from 'redux-form';

import UserNotAvailableApi from "api/UserNotAvailableApi";
import { formatDateToISO, ISO_DATE_TIME_FORMAT } from "helpers/DateTimeHelpers";
import { USER_UNAVAILABLE_FORM_NAME } from "helpers/FormNames";

export const OPEN_USER_UNAVAILABLE_MODAL = 'OPEN_USER_UNAVAILABLE_MODAL';
export const CLOSE_USER_UNAVAILABLE_MODAL = 'CLOSE_USER_UNAVAILABLE_MODAL';

export const USERS_UNAVAILABLE_LIST_REQUEST = 'USERS_UNAVAILABLE_LIST_REQUEST';
export const USERS_UNAVAILABLE_LIST_REQUEST_SUCCESS = 'USERS_UNAVAILABLE_LIST_REQUEST_SUCCESS';
export const USERS_UNAVAILABLE_LIST_REQUEST_FAILURE = 'USERS_UNAVAILABLE_LIST_REQUEST_FAILURE';

export const ADD_USER_UNAVAILABLE_REQUEST = 'ADD_USER_UNAVAILABLE_REQUEST';
export const ADD_USER_UNAVAILABLE_SUCCESS = 'ADD_USER_UNAVAILABLE_SUCCESS';
export const ADD_USER_UNAVAILABLE_FAILURE = 'ADD_USER_UNAVAILABLE_FAILURE';

export const openUserUnavailableModal = () => {
  return {
    type: OPEN_USER_UNAVAILABLE_MODAL,
  }
};

export const closeUserUnavailableModal = () => {
  return {
    type: CLOSE_USER_UNAVAILABLE_MODAL,
  }
};

export const usersUnavailableListRequest = () => {
  return {
    type: USERS_UNAVAILABLE_LIST_REQUEST
  }
};

export const usersUnavailableListRequestSuccess = (unavailableList) => {
  return {
    type: USERS_UNAVAILABLE_LIST_REQUEST_SUCCESS,
    payload: unavailableList
  }
};

export const usersUnavailableListFailure = (error) => {
  return {
    type: USERS_UNAVAILABLE_LIST_REQUEST_FAILURE,
    payload: error
  }
};

export const addUserUnavailableRequest = () => {
  return {
    type: ADD_USER_UNAVAILABLE_REQUEST
  }
};

export const addUserUnavailableSuccess = (unavailable) => {
  return {
    type: ADD_USER_UNAVAILABLE_SUCCESS,
    payload: unavailable
  }
};

export const addUserUnavailableFailure = (error) => {
  return {
    type: ADD_USER_UNAVAILABLE_FAILURE,
    payload: error
  }
};

const userNotAvailableApi = new UserNotAvailableApi();

export const fetchUserNotAvailables = (date) => {
  return userNotAvailablesRequestHandler(userNotAvailableApi.index(date));
};

export const fetchCurrentUserNotAvailables = (date) => {
  return  userNotAvailablesRequestHandler(userNotAvailableApi.userNotAvailableList(date));
};

const userNotAvailablesRequestHandler = (request) => {
  return (dispatch) => {
    dispatch(usersUnavailableListRequest());
    return request
      .then((data) => {
        dispatch(usersUnavailableListRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(usersUnavailableListFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const addUserNotAvailable = (params, callback) => {
  const formattedParams = { ...params, start_date: formatDateToISO(params.start_date, ISO_DATE_TIME_FORMAT), end_date: formatDateToISO(params.end_date, ISO_DATE_TIME_FORMAT) };
  return (dispatch) => {
    dispatch(addUserUnavailableRequest());
    return userNotAvailableApi.create(formattedParams)
      .then((data) => {
        dispatch(addUserUnavailableSuccess(data));
        dispatch(reset(USER_UNAVAILABLE_FORM_NAME));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(addUserUnavailableFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};


