import React from "react";
import moment from 'moment';
import { Row, Col, Clearfix } from 'react-bootstrap';
import { Field } from 'redux-form';

import { RenderField, RenderSelect, renderDatePicker } from "shared/render-field";
import { CAN_SELECT_TODO_RESPONSIBLE } from "helpers/Permissions";

const TodoInputFields =(props) => {
    const { users, isJobPage } = props;

    return (
        <Row>
          <Col md={isJobPage ? 3 : 12}>
            <Field
              name="user_id"
              label="Responsible"
              component={RenderSelect}
              options={users}
              disabled={!CAN_SELECT_TODO_RESPONSIBLE}
            />
          </Col>
          <Col md={isJobPage ? 3 : 12}>
            <Field name="due_date" label="Due Date" disablePrevious topPosition={isJobPage} component={renderDatePicker}/>
          </Col>
          <Clearfix />
          <div className={`${isJobPage && 'flex-center-align'}`}>
            <Col md={isJobPage ? 9 : 12}>
              <Field name="description" label="Description" component={RenderField} />
            </Col>
            <Col md={isJobPage ? 3 : 12}>
              {props.children}
            </Col>
          </div>
        </Row>
    )
  };

export default TodoInputFields
