import { SELECT_TAB } from "actions/jobs-tabs-actions";

const INITIAL_STATE = { selected: null };

const TabsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case SELECT_TAB:
      return { ...state, selected: action.payload };

    default:
      return state;
  }
};

export default TabsReducer
