export const updateList = (list, data) => {
  const elementIndex = list.findIndex((el) => el.id === data.id);
  let newList = [...list];
  if (elementIndex >= 0) newList.splice(elementIndex, 1, data);
  return newList;
};

export const addElementToList = (list, data) => {
  let newList = [...list];
  newList.unshift(data);
  return newList
};

export const removeElementFromList = (list, findValue, key='id') => {
  return list.filter((element) => element[key] !== findValue);
};

export const findItemById = (objArray, id) => {
  return objArray.find((obj) => obj.id === +id);
};

export const arrayPush = (array, data) => {
  let newArray = [...array];
  newArray.push(data);
  return newArray
};

export const removeArrayItem = (array, index) => {
  let newArray = [...array];
  newArray.splice(index, 1);
  return newArray
};
