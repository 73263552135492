import React from "react";
import { reduxForm } from 'redux-form';
import { AutoSuggestInput } from "shared/autosuggest-input";
import { GENERAL_SEARCH_FORM } from "helpers/FormNames";
import { redirectTo, jobPath, jobsPath } from "helpers/PathHelpers";

class GeneralSearchComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  onEnter(value, suggestions) {
    const suggestionsLength = suggestions.length;
    if (suggestionsLength && suggestionsLength === 1 ) {
      redirectTo(jobPath(suggestions[0].id))
    } else {
      redirectTo(jobsPath({query: value}))
    }
  }

  render() {
    const { handleSubmit, fetchSearchBarJobs } = this.props;
    return (
      <form onSubmit={handleSubmit(fetchSearchBarJobs)} className="general-search-bar">
        <AutoSuggestInput
          name="query"
          showSearchIcon
          handleChange={fetchSearchBarJobs}
          onEnter={this.onEnter}
          onSelect={(job) => redirectTo(jobPath(job.id))}
          disableAutoSelectActive
        />
      </form>
    )
  }
}

export default reduxForm({
  form: GENERAL_SEARCH_FORM
})(GeneralSearchComponent);
