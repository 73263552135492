import React from "react";
import { Provider } from "react-redux";

import configureEstimatesStore from "app/store/configureEstimatesStore";
import EstimatesList from 'app/containers/EstimatesContainer'

const store = configureEstimatesStore();

class Estimates extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <EstimatesList />
      </Provider>
    )
  }
}
export default Estimates;

