import React from "react";
import PropTypes from "prop-types";
import { ListGroup, ListGroupItem, Row, Col } from "react-bootstrap"
import InfiniteScroll from 'react-infinite-scroller';
import { OverflowScroll } from "shared/oferflow-scroll";
import { jobPath, clientPath } from "helpers/PathHelpers";

const ActivityLogComponent = (props) => {
  const { activityLog, isJobPage, canShowClientLink, onScroll } = props;
  const noteWidth = !isJobPage ? 8 : 12;
  const space = ' ';
  const list = (
    <div className="margin-top-10">
      <ListGroup>
        {activityLog.list.map((log, i) => {
            const linkType = log.data.callback ? 'Callback' : 'Job';
          let link = '';
          if (log.data.job_id) link = <a href={jobPath(log.data.job_id)} >Show {linkType}</a>;
          if (canShowClientLink && log.data.client_id) link = <a href={clientPath(log.data.client_id)} >Show client</a>;
            return (
              <ListGroupItem key={i} className="non-bordered">
                <Row>
                  <Col md={noteWidth}>
                    <span>{log.date}</span>{space}
                    <b>{log.author}</b>{space}
                    <i>{log.message}</i>
                  </Col>
                  {(!isJobPage) &&
                  <Col md={4}>
                    {link}
                  </Col>}
                </Row>
              </ListGroupItem>
            )
          }
        )}
      </ListGroup>
    </div>
  );

  if (!isJobPage) {
    return (
      <OverflowScroll>
        <InfiniteScroll
          pageStart={1}
          loadMore={onScroll}
          hasMore={!!activityLog.list.length}
          useWindow={false}
          initialLoad={false}
        >
          {list}
        </InfiniteScroll>
      </OverflowScroll>
    )
  }
  return list;
};

ActivityLogComponent.defaultProps = {
  onScroll: ()=>{},
  canShowClientLink: false
};

ActivityLogComponent.propTypes = {
  activityLog: PropTypes.object.isRequired,
  onScroll: PropTypes.func,
  canShowClientLink: PropTypes.bool
};
export default ActivityLogComponent
