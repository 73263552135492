import React from "react";
import { Badge, ListGroup, ListGroupItem, Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';


export class NoteList extends React.Component {
  constructor(props) {
    super(props)
  }

  renderNote(note) {
    const space = ' '
    return (
      <ListGroupItem key={note.id}>
        <div>{note.created_at}, {space}
          <u>{note.user && note.user.name}</u> {space}
          <Badge>{note.note_type}</Badge>
        </div>
        <div className="wrap-word">{note.note}</div>
      </ListGroupItem>
    )
  }

  render() {
    const { notes } = this.props;
    return (
      <ListGroup>
        {notes.map(note => this.renderNote(note))}
      </ListGroup>
    )
  }
}

NoteList.defaultProps = {
  notes: []
}

NoteList.propTypes = {
  notes: PropTypes.array.isRequired
};

export default NoteList

