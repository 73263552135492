import ApiClient from 'api/ApiClient'

class StatisticsApi extends ApiClient {
  constructor() {
    super({url:'statistics'})
  }

  heatmaps(params) {
    return this.request(params, 'heatmaps')
  }

  status(params) {
    return this.request(params, 'status')
  }

  estimators(params) {
    return this.request(params, 'estimators')
  }

  timeline(params) {
    return this.request(params, 'timeline')
  }

  distributions(params) {
    return this.request(params, 'distributions')
  }

  request(params, type) {
    const url = `${this.url}/${type}`;
    return super.index(params, url)
  }
}

export default StatisticsApi
