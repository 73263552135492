import { TIMELINE_REQUEST, TIMELINE_REQUEST_SUCCESS, TIMELINE_REQUEST_FAILURE } from "actions/statistics/timeline-actions";
import { RESET_DATA } from "actions/jobs-tabs-actions";

const INITIAL_STATE = { data: [], error: null, loading: false };

const TimelineReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case TIMELINE_REQUEST:
      return {...state, error: null, loading: true};
    case TIMELINE_REQUEST_SUCCESS:
      return {...state, data: action.payload, loading: false};
    case TIMELINE_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};
    case RESET_DATA:
      return {...state, data: []};
    default:
      return state;
  }
};

export default TimelineReducer
