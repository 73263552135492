import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import { FaIcon } from "shared/fa-icon";
import { testMatch } from "helpers/Regexp";
import {
  IMAGE_MIME_TYPE, AUDIO_MIME_TYPE, DOC_MIME_TYPE,
  EXCEL_MIME_TYPE, PDF_MIME_TYPE, VIDEO_MIME_TYPE,
  ZIP_MIME_TYPE
} from "helpers/AppConstants";

export class DocumentThumbnail extends React.Component {
  constructor(props) {
    super(props)
  }

  selectIcon(type) {
    switch(true) {
      case testMatch(type, AUDIO_MIME_TYPE):
        return 'file-sound-o';
      case testMatch(type, DOC_MIME_TYPE):
        return 'file-word-o';
      case testMatch(type, EXCEL_MIME_TYPE):
        return 'file-excel-o';
      case testMatch(type, PDF_MIME_TYPE):
        return 'file-pdf-o';
      case testMatch(type, VIDEO_MIME_TYPE):
        return 'file-video-o';
      case testMatch(type, ZIP_MIME_TYPE):
        return 'file-zip-o';
      default:
        return 'file';
    }
  }

  renderImage(document) {
    return <Image className="image" src={document.thumb_url} thumbnail />
  }

  renderIcon(document) {
    return (
      <div className="icon flex-center">
        <FaIcon size="3x" icon={this.selectIcon(document.attachment_type)} />
      </div>
    )
  }

  isImage(document) {
    return testMatch(document.attachment_type, IMAGE_MIME_TYPE)
  }

  renderThumbnail(document) {
    return this.isImage(document) ? this.renderImage(document) : this.renderIcon(document);
  }

  render() {
    const { document } = this.props;
    return this.renderThumbnail(document)
  }
}

DocumentThumbnail.defaultProps = {
  document: {}
};

DocumentThumbnail.propTypes = {
  document: PropTypes.object.isRequired
};

export default DocumentThumbnail

