import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import {
  SEARCH_BAR_JOBS_REQUEST,
  SEARCH_BAR_JOBS_REQUEST_SUCCESS,
  SEARCH_BAR_JOBS_REQUEST_FAILURE,
  RESET_SEARCH_BAR_JOBS_LIST
} from "actions/general-search-bar-actions";

const INITIAL_STATE = { list: [], error: null, loading: false};

const SearchBarReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case SEARCH_BAR_JOBS_REQUEST:
      return {...state, list: [], loading: true};
    case SEARCH_BAR_JOBS_REQUEST_SUCCESS:
      return {...state, list: action.payload, error:null, loading: false};
    case SEARCH_BAR_JOBS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};
    case RESET_SEARCH_BAR_JOBS_LIST:
      return {...state, list: [] };

    default:
      return state;
  }
};

const GeneralSearchBarReducer = combineReducers({
  jobs: SearchBarReducer,
  form: reduxFormReducer
});

export default GeneralSearchBarReducer
