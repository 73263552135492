export const ESTIMATES_REQUEST = 'ESTIMATES_REQUEST';
export const ESTIMATES_REQUEST_SUCCESS = 'ESTIMATES_REQUEST_SUCCESS';
export const ALL_ESTIMATES_REQUEST_SUCCESS = 'ALL_ESTIMATES_REQUEST_SUCCESS';
export const ESTIMATES_REQUEST_FAILURE = 'ESTIMATES_REQUEST_FAILURE';
export const SELECT_ESTIMATE = 'SELECT_ESTIMATE';
export const RESET_SELECTED_ESTIMATE = 'RESET_SELECTED_ESTIMATE';

export const estimatesRequest = (data) => {
  return {
    type: ESTIMATES_REQUEST,
    payload: data
  }
};

export const estimatesRequestSuccess = (data) => {
  return {
    type: ESTIMATES_REQUEST_SUCCESS,
    payload: data
  }
};

export const allEstimatesRequestSuccess = (data) => {
  return {
    type: ALL_ESTIMATES_REQUEST_SUCCESS,
    payload: data
  }
};

export const estimatesRequestFailure = (error) => {
  return {
    type: ESTIMATES_REQUEST_FAILURE,
    payload: error
  }
};

export const selectEstimate = (data) => {
  return {
    type: SELECT_ESTIMATE,
    payload: data
  }
};

export const resetSelectedEstimate = () => {
  return {
    type: RESET_SELECTED_ESTIMATE
  }
};
