import { createStore, compose } from "redux"

import DashboardReducers from "reducers/dashboard-reducers"
import configureMiddleware from "store/configure-middleware"

const configureCalendarStore = preloadedState => {
  return createStore(
    DashboardReducers,
    preloadedState,
    compose(
      configureMiddleware()
    )
  )
};

export default configureCalendarStore
