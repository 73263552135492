import { connect } from 'react-redux';

import JobComponent from 'presentational/job-show/job-component';
import { fetchJob, addCallback, updateJob } from "api/job";
import { loadJobToEditForm, resetJobEditForm, disableAddressInput } from "actions/jobs-actions";
import { copyClientAddressToEditJob } from "actions/addresses";
import { fetchNotes, fetchNoteTypes, addNote } from "actions/notes-actions";
import { addTodo, fetchJobTodos, completeTodo } from "actions/todo-actions";
import { fetchJobCallbacks, convertCallbackToJob } from 'actions/callback-actions';
import { fetchMaterialBrands } from "actions/material-brands-actions";
import { fetchSaleRoofTypes } from "actions/sale-roof-types-actions";
import { fetchRoofPitches } from "actions/roof-pitches-actions";
import { fetchLostReasons } from "actions/lost-reasons-actions";
import { openJobLostModal, closeJobLostModal, lostJob } from "actions/job-lost-actions";
import { fetchJobActivityLog } from "actions/activity-log-actions";
import { fetchUsers } from "actions/user-actions";
import {
  estimateJob, openJobEstimateModal,
  closeJobEstimateModal, openJobSoldModal,
  closeJobSoldModal, soldDetails, soldJob
} from "actions/job-status-actions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchJob: (id, callback) => {
      dispatch(fetchJob(id, callback))
    },
    loadJobToEditForm: (job) => {
     dispatch(loadJobToEditForm(job))
    },
    resetJobEditForm: () => {
      dispatch(resetJobEditForm())
    },
    fetchNotes: (type, id) => {
      dispatch(fetchNotes(type, id))
    },
    fetchNoteTypes: () => {
      dispatch(fetchNoteTypes())
    },
    fetchTodos: (job_id) => {
      dispatch(fetchJobTodos(job_id))
    },
    addNote: (data, callback) => {
      dispatch(addNote(data, callback))
    },
    addTodo: (data, callback) => {
      dispatch(addTodo(data, callback))
    },
    fetchCallbacks: (job_id) => {
      dispatch(fetchJobCallbacks(job_id))
    },
    convertCallbackToJob: (callbackId) => {
      dispatch(convertCallbackToJob(callbackId))
    },
    estimateJob: (jobParams, callback) => {
      dispatch(estimateJob(jobParams, callback))
    },
    soldJob: (soldParams, callback) => {
      dispatch(soldJob(soldParams, callback))
    },
    lostJob: (lostParams, callback) => {
      dispatch(lostJob(lostParams, callback))
    },
    openJobEstimateModal: (job) => {
      dispatch(openJobEstimateModal(job))
    },
    closeJobEstimateModal: () => {
      dispatch(closeJobEstimateModal())
    },
    openJobSoldModal: (job) => {
      dispatch(openJobSoldModal(job))
    },
    closeJobSoldModal: () => {
      dispatch(closeJobSoldModal())
    },
    openJobLostModal: (job) => {
      dispatch(openJobLostModal(job))
    },
    closeJobLostModal: () => {
      dispatch(closeJobLostModal())
    },
    fetchMaterialBrands: () => {
      dispatch(fetchMaterialBrands())
    },
    fetchSaleRoofTypes: () => {
      dispatch(fetchSaleRoofTypes())
    },
    fetchRoofPitches: () => {
      dispatch(fetchRoofPitches())
    },
    soldDetails: (jobId) => {
      dispatch(soldDetails(jobId))
    },
    fetchLostReasons: () => {
      dispatch(fetchLostReasons())
    },
    fetchActivityLog: (jobId) => {
      dispatch(fetchJobActivityLog(jobId))
    },
    addCallback: (data, callback) => {
      dispatch(addCallback(data, callback))
    },
    updateJob: (data, callback) => {
      dispatch(updateJob(data, callback))
    },
    completeTodo: (todoId, callback) => {
      dispatch(completeTodo(todoId, callback))
    },
    fetchUsers: () => {
      dispatch(fetchUsers())
    },
    copyClientAddressToEditJob: () => {
      dispatch(copyClientAddressToEditJob());
    },
    disableAddressInput: (data) => {
      dispatch(disableAddressInput(data));
    }
  }
};

const mapStateToProps = (state) => {
  return {
    job: state.job,
    editJob: state.editJob,
    jobEstimate: state.jobEstimate,
    jobSold: state.jobSold,
    jobLost: state.jobLost,
    notesData: state.notes,
    users: state.users,
    todos: state.todos,
    callbacks: state.callbacks,
    materialBrands: state.materialBrands.list,
    roofPitches: state.roofPitches.list,
    saleRoofTypes: state.saleRoofTypes.list,
    lostReasons: state.lostReasons.list,
    materialsBrandSeries: state.materialsBrandSeries.series,
    activityLog: state.activityLog,
    documents: state.documents
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobComponent);
