import React from "react";
import PropTypes from 'prop-types';
import { Row, Col, Table } from "react-bootstrap"

export const Materials = props => {
  const { materials } = props;
  return (
      <Table>
        <thead>
        <tr>
          <th>Brand</th>
          <th>Brand Series</th>
          <th>Color</th>
        </tr>
        </thead>
        <tbody>
        { materials.map(material => (
          <tr key={material.id}>
            <td>{material.material_brand && material.material_brand.name }</td>
            <td>{material.brand_item && material.brand_item.name}</td>
            <td>{material.color}</td>
          </tr>
        )) }
        </tbody>
      </Table>
  )
};

Materials.propTypes = {
  materials: PropTypes.array.isRequired
};
