import { connect } from 'react-redux';

import DashboardComponent from 'presentational/dashboard/dashboard-component';
import { fetchDashboardActivityLog } from "actions/activity-log-actions";
import { completeTodo, fetchDashboardTodos } from "actions/todo-actions";
import { fetchUsers} from "actions/user-actions";
import {
  selectTimeSlots, clearTimeSlotsData, selectEvent, resetSelectedEvent,
  closeRemoveUnavailableDateModal, openRemoveUnavailableDateModal,
  removeUnavailableDate, openJobDetailsModal, closeJobDetailsModal
} from 'actions/calendar-actions';
import { fetchEstimates, setAppointment, unassignEstimator } from "api/estimate";
import { selectEstimate, resetSelectedEstimate } from "actions/estimates-actions";
import { fetchCurrentUserNotAvailables } from "actions/user-not-available";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityLog: (page) => {
      dispatch(fetchDashboardActivityLog(page))
    },
    fetchTodos: () => {
      dispatch(fetchDashboardTodos())
    },
    completeTodo: (todoId, callback) => {
      dispatch(completeTodo(todoId, callback))
    },
    fetchUsers: () => {
      dispatch(fetchUsers())
    },
    fetchEstimates: (params) => {
      dispatch(fetchEstimates(params))
    },
    unassignEstimator: (data, callback) => {
      dispatch(unassignEstimator(data, callback))
    },
    setAppointment: (data, callback) => {
      dispatch(setAppointment(data, callback))
    },
    clearTimeSlotsData: () => {
      dispatch(clearTimeSlotsData())
    },
    selectTimeSlots: (data) => {
      dispatch(selectTimeSlots(data))
    },
    selectEstimate: (data) => {
      dispatch(selectEstimate(data))
    },
    selectEvent: (data) => {
      dispatch(selectEvent(data))
    },
    resetSelectedEvent: () => {
      dispatch(resetSelectedEvent())
    },
    resetSelectedEstimate: () => {
      dispatch(resetSelectedEstimate())
    },
    fetchUserNotAvailables: (date) => {
      dispatch(fetchCurrentUserNotAvailables(date))
    },
    closeRemoveUnavailableDateModal: () => {
      dispatch(closeRemoveUnavailableDateModal())
    },
    openRemoveUnavailableDateModal: (event) => {
      dispatch(openRemoveUnavailableDateModal(event))
    },
    removeUnavailableDate: (event) => {
      dispatch(removeUnavailableDate(event))
    },
    openJobDetailsModal: (event) => {
      dispatch(openJobDetailsModal(event))
    },
    closeJobDetailsModal: () => {
      dispatch(closeJobDetailsModal())
    }
  }
};

const mapStateToProps = (state) => {
  return {
    event: state.calendar.event,
    showUnavailableDateModal: state.calendar.showUnavailableDateModal,
    showJobDetailsModal: state.calendar.showJobDetailsModal,
    closeJobDetailsModal: state.calendar.closeJobDetailsModal,
    estimates: state.estimates,
    todos: state.todos,
    activityLog: state.activityLog,
    users: state.users,
    focusUnassigned: state.calendar.focusUnassigned,
    timeSlotsData: state.calendar.timeSlotsData,
    selectedEvent: state.calendar.selectedEvent,
    userUnavailable: state.userUnavailable
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
