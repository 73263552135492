import React from "react";
import PropTypes from 'prop-types';
import { ORDER_ASC, ORDER_DESC } from "helpers/AppConstants";
import { FaIcon } from "shared/fa-icon";

export class Sorting extends React.Component {
  constructor(props) {
    super(props);
    this.selectOrderIcon = this.selectOrderIcon.bind(this);
    this.onClick = this.onClick.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }

  selectOrderIcon() {
    const { sortBy, selectedSortKey, orderBy } = this.props;
    if (sortBy !== selectedSortKey) return '';
    const icon = orderBy === ORDER_DESC ? 'long-arrow-down' : 'long-arrow-up';
    return <FaIcon icon={icon} size="lg"  />
  }

  sortBy() {
    const { sortBy, selectedSortKey, orderBy } = this.props;
    let ordering;
    if (sortBy !== selectedSortKey) {
      ordering = ORDER_ASC;
    } else {
      ordering = orderBy === ORDER_DESC ? ORDER_ASC : ORDER_DESC;
    }
    return this.sortParams(sortBy, ordering);
  }

  onClick() {
    const { onClick } = this.props;
    onClick(this.sortBy());
  }

  sortParams(sortBy, orderBy) {
    return {
      sortBy,
      orderBy
    }
  }

  render() {
    const { title } = this.props;

    return (
      <div className="pointer-cursor text-ellipsis" onClick={this.onClick}>{title} {this.selectOrderIcon()}</div>
    )
  }
}

Sorting.defaultProps = {
  title: '',
  sortBy: '',
  selectedSortKey: '',
  orderBy: ORDER_DESC,
  onClick: () => {}
};

Sorting.propTypes = {
  title: PropTypes.string,
  sortBy: PropTypes.string,
  selectedSortKey: PropTypes.string,
  orderBy: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

