import React from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

import { GOOGLE_API_KEY } from "helpers/AppConstants";

export const AddressMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={props.zoom || 17}
    defaultCenter={{ lat: +props.lat, lng: +props.lng }}
  >
    <Marker position={{ lat: +props.lat, lng: +props.lng }} />
  </GoogleMap>
))

AddressMapComponent.defaultProps = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_API_KEY}&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `300px` }} />,
  mapElement: <div style={{ height: `100%` }} />
};
