import { baseClientValidation } from "form-validations/base-client-validation";
import { baseJobValidation } from "form-validations/base-job-validation";

export const validate = values => {
  const required = 'Required';

  const errors = {};

  if (values.callback ) {
    if (!values.note) {
      errors.note = required
    }
    if (!values.estimator) {
      errors.estimator = required
    }
  }

  return {...errors, ...baseClientValidation(values), ...baseJobValidation(values)}
};
