import React from "react";
import { Provider } from "react-redux";

import configureGeneralSearchBarStore from "app/store/configureGeneralSearchBarStore";
import GeneralSearchBarContainer from "app/containers/GeneralSearchBarContainer";

const store = configureGeneralSearchBarStore();

class GeneralSearchBar extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <GeneralSearchBarContainer />
      </Provider>
    )
  }
}
export default GeneralSearchBar;

