import {
  ROOF_PITCHES_REQUEST,
  ROOF_PITCHES_REQUEST_SUCCESS,
  ROOF_PITCHES_REQUEST_FAILURE
} from "actions/roof-pitches-actions";

const INITIAL_STATE = { list: [], error: null, loading: false };

const RoofPitchesReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case ROOF_PITCHES_REQUEST:
      return {...state, error: null, loading: true};
    case ROOF_PITCHES_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false};
    case ROOF_PITCHES_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};

    default:
      return state;
  }
};

export default RoofPitchesReducer
