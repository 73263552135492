import React from "react";
import { Provider } from "react-redux";

import configureCalendarStore from "app/store/configureCalendarStore";
import CalendarComponent from 'app/containers/CalendarContainer'

const store = configureCalendarStore();

class Calendar extends React.Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <CalendarComponent />
        </Provider>
      </div>
    )
  }
}
export default Calendar;

