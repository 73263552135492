import React from "react";
import { Row, Col, Glyphicon } from "react-bootstrap";

import { AddressMapComponent } from "shared/map-component";
import ArchiveClientAlert from "shared/archive-client-alert";
import EditClientModal from "shared/edit-client-modal";
import { selectAllContacts } from "api/client";
import { addressToString } from "actions/addresses";
import { CAN_ARCHIVE_CLIENT } from "helpers/Permissions";
import { DescriptionRow } from "shared/description-row";
import { selectOptionOrOther } from "helpers/ViewHelpers";
import { Address } from "../job-show/address";

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showAlert: false}
  }

  editContact(client) {
    this.props.loadToEditForm(client)
  }

  closeEditModal() {
    this.props.resetEditForm();
  }

  showToggleAlert() {
    this.setState({showAlert: true})
  }

  hideAlert() {
    this.setState({showAlert: false})
  }

  toggleArchive(client) {
    this.props.toggleArchive(client);
    this.setState({showAlert: false})
  }

  renderClientInfo(client) {
    const btnInfo = client.archived ? {class: 'primary', text: 'Unarchive'} : {class: 'success', text: 'Archive'};
    return (
      <Row>
        <Col md={12}>
          <h3 className="margin-top-0">
            <a onClick={()=>this.editContact(client)}>
              Edit {' '}<Glyphicon glyph="pencil" />
            </a>
            <button
              onClick={() => this.showToggleAlert(client)}
              className={`btn btn-sm btn-${btnInfo.class} pull-right`}
              disabled={!CAN_ARCHIVE_CLIENT}
            >
              {btnInfo.text}
            </button>
          </h3>
        </Col>
        <DescriptionRow name="First and Last Name" value={client.name}/>
        <DescriptionRow name="Business Name" value={client.company_name}/>
        <DescriptionRow name="Position" value={selectOptionOrOther('client_position', client)}/>
        <DescriptionRow name="Phone number" value={selectAllContacts(client.phones)} isPhone />
        <DescriptionRow name="Email" value={selectAllContacts(client.emails, 'email')} isEmail />
        <DescriptionRow name="Referral Source" value={selectOptionOrOther('referral_resource', client, true)}/>
        <DescriptionRow name="Primary Contact Form" value={client.primary_contact_form}/>
      </Row>
    )
  }

  renderAddresses(addresses=[]) {
    return (
      <Row>
        <Col md={12}>
          <h3 className="text-muted">Addresses {' '}<Glyphicon glyph="pencil" /></h3>
        </Col>
        { addresses.map((address, i) => (
            <Col md={12} key={i}><p>{addressToString(address)}</p></Col>
          )) }
      </Row>
    )
  }

  renderMailingAddress(client) {
    return (
      <Row>
        <Col md={12}>
          <h3 className="text-muted">Mailing Address</h3>
        </Col>
        <Col md={12}>
          <Address
            address={client.address}
            showInline
          />
        </Col>
        {client.address && client.address.lat && client.address.lng &&
        <Col md={12}>
          <AddressMapComponent lat={client.address.lat} lng={client.address.lng} />
        </Col>}
      </Row>
    )
  }

  render() {
    const { client, addresses, loadAddress, updateClient, showEditModal, clientOptions, editClient } = this.props;
    return (
      <div>
        { this.renderClientInfo(client) }
        { addresses.length > 0 && this.renderAddresses(addresses) }
        { this.renderMailingAddress(client) }

        <EditClientModal show={showEditModal}
                         close={() => this.closeEditModal()}
                         loadAddress={loadAddress}
                         onSubmit={updateClient}
                         clientOptions={clientOptions}
                       />

        <ArchiveClientAlert
          client={client}
          show={this.state.showAlert}
          onConfirm={() => this.toggleArchive(client)}
          onCancel={() => this.hideAlert()}
        />
      </div>
    )
  }
}
export default CustomerInfo;
