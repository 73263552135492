import UserApi from 'api/UserApi'

export const USERS_REQUEST         = 'USERS_REQUEST';
export const USERS_REQUEST_SUCCESS = 'USERS_REQUEST_SUCCESS';
export const USERS_REQUEST_FAILURE = 'USERS_REQUEST_FAILURE';

export const ESTIMATORS_REQUEST         = 'ESTIMATORS_REQUEST';
export const ESTIMATORS_REQUEST_SUCCESS = 'ESTIMATORS_REQUEST_SUCCESS';
export const ESTIMATORS_REQUEST_FAILURE = 'ESTIMATORS_REQUEST_FAILURE';

export const usersRequest = () => {
  return {
    type: USERS_REQUEST,
  }
};

export const usersRequestSuccess = (users) => {
  return {
    type: USERS_REQUEST_SUCCESS,
    payload: users
  }
};

export const usersRequestFailure = (error) => {
  return {
    type: USERS_REQUEST_FAILURE,
    payload: error
  }
};

export const estimatorsRequest = () => {
  return {
    type: ESTIMATORS_REQUEST,
  }
};

export const estimatorsRequestSuccess = (estimators) => {
  return {
    type: ESTIMATORS_REQUEST_SUCCESS,
    payload: estimators
  }
};

export const estimatorsRequestFailure = (error) => {
  return {
    type: ESTIMATORS_REQUEST_FAILURE,
    payload: error
  }
};

const userApi = new UserApi();

export const fetchUsers = () => {
  return (dispatch, getState) => {
    const users = getState().users.list;
    if (!users.length) {
      dispatch(usersRequest());
      return userApi.index()
        .done((data) => {
          dispatch(usersRequestSuccess(data));
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          dispatch(usersRequestFailure(textStatus + ': ' + errorThrown));
          console.log(textStatus + ': ' + errorThrown);
        });
    }
  }
};

export const fetchEstimators = (params) => {
  return (dispatch) => {
    dispatch(estimatorsRequest());
    return userApi.estimators(params)
      .done((data) => {
        dispatch(estimatorsRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(estimatorsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
