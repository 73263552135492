import React from "react";
import { Provider } from "react-redux";

import configureCallbackCloseStore from "store/configureCallbackCloseStore";
import CallbackCloseComponent from 'containers/CallbackCloseContainer'

const store = configureCallbackCloseStore();

class CallbackClose extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <CallbackCloseComponent callbackId={this.props.callback_id} />
      </Provider>
    )
  }
}
export default CallbackClose;
