import {
  OPEN_JOB_LOST_MODAL,
  CLOSE_JOB_LOST_MODAL,
  JOB_LOST_REQUEST,
  JOB_LOST_REQUEST_SUCCESS,
  JOB_LOST_REQUEST_FAILURE
} from "actions/job-lost-actions";

const INITIAL_STATE = { data: {}, showModal: false, loading: false };

const JobLostReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case OPEN_JOB_LOST_MODAL:
      return {...state, data: action.payload,  showModal: true, loading: false };
    case CLOSE_JOB_LOST_MODAL:
      return {...state, data: {}, showModal: false, loading: false };

    case JOB_LOST_REQUEST:
      return {...state, loading: true };
    case JOB_LOST_REQUEST_SUCCESS:
      return {...state, data: {}, showModal: false, loading: false };
    case JOB_LOST_REQUEST_FAILURE:
      return {...state, showModal: true, loading: false };

    default:
      return state;
  }
};
export default JobLostReducer
