import StatisticsApi from "api/StatisticsApi";

export const TIMELINE_REQUEST = 'TIMELINE_REQUEST';
export const TIMELINE_REQUEST_SUCCESS = 'TIMELINE_REQUEST_SUCCESS';
export const TIMELINE_REQUEST_FAILURE = 'TIMELINE_REQUEST_FAILURE';

const timelineRequest = () => {
  return {
    type: TIMELINE_REQUEST,
  }
};

const timelineRequestSuccess = (data) => {
  return {
    type: TIMELINE_REQUEST_SUCCESS,
    payload: data
  }
};

const timelineRequestFailure = (error) => {
  return {
    type: TIMELINE_REQUEST_FAILURE,
    payload: error
  }
};

const statisticsApi = new StatisticsApi();

export const fetchTimelineData = (params) => {
  return (dispatch) => {
    dispatch(timelineRequest());
    return statisticsApi.timeline(params)
      .then((data) => {
        dispatch(timelineRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(timelineRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
