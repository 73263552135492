import { TODOS_REQUEST, TODOS_REQUEST_SUCCESS, TODOS_REQUEST_FAILURE,
         CREATE_TODO_REQUEST, CREATE_TODO_SUCCESS, CREATE_TODO_FAILURE,
         UPDATE_TODO_REQUEST, UPDATE_TODO_SUCCESS, UPDATE_TODO_FAILURE,
         EDIT_TODO, CANCEL_EDIT_TODO, COMPLETE_TODO_SUCCESS
       } from "actions/todo-actions";

import { updateList, addElementToList, removeElementFromList } from "helpers/ListHelpers";

const INITIAL_STATE = { list: [], edit: {}, error: null, loading: false };

const TodosReducers = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case TODOS_REQUEST:
      return { ...state, error: null, loading: true };
    case TODOS_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false };
    case TODOS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    case CREATE_TODO_REQUEST:
      return { ...state, error: null, loading: true };
    case CREATE_TODO_SUCCESS:
      return {...state, list: addElementToList(state.list, action.payload), loading: false };
    case CREATE_TODO_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    case UPDATE_TODO_REQUEST:
      return { ...state, error: null, loading: true };
    case UPDATE_TODO_SUCCESS:
      return {...state, list: updateList(state.list, action.payload), edit: {}, loading: false };
    case UPDATE_TODO_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    case COMPLETE_TODO_SUCCESS:
      return {...state, list: removeElementFromList(state.list, action.payload.id), loading: false };

    case EDIT_TODO:
      return { ...state, edit: action.payload };

    case CANCEL_EDIT_TODO:
      return { ...state, edit: {} };

    default:
      return state;
  }
};

export default TodosReducers

