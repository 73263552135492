import React from "react";
import PropTypes from 'prop-types';

import Spinner from 'shared/spinner'
import Breadcrumb from './breadcrumb';
import CallbackCloseForm from "./callback-close-form"
import { JobDescription } from "./job-description"
import { TYPE_JOB } from "helpers/AppConstants";

class CallbackCloseComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    const { callbackId, fetchJob, fetchNotes, fetchUsers } = this.props;
    fetchJob(callbackId);
    fetchNotes(TYPE_JOB, callbackId);
    fetchUsers();
  }

  selectCallbackNote(notes) {
    if (!notes.length) return '';
    const lastNote = notes.length - 1;
    return notes[lastNote].note;
  }

  render() {
    const { job, notes, users, closeCallback } = this.props;
    return (
      <div>
        <Spinner active={job.loading} ><span>Loading...</span></Spinner>
        { !job.loading && <div>
          <Breadcrumb job={job.data} />
          <JobDescription job={job} note={this.selectCallbackNote(notes.list)} />
          <CallbackCloseForm onSubmit={closeCallback} users={users.list} job={job.data} />
        </div> }
      </div>
    )
  }
}

CallbackCloseComponent.propTypes = {
  job: PropTypes.object.isRequired,
  callbackId: PropTypes.number.isRequired,
  fetchJob: PropTypes.func.isRequired,
  fetchNotes: PropTypes.func.isRequired,
};

export default CallbackCloseComponent;

