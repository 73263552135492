import { NOTES_REQUEST, NOTES_REQUEST_SUCCESS, NOTES_REQUEST_FAILURE,
         CREATE_NOTE_REQUEST, CREATE_NOTE_SUCCESS, CREATE_NOTE_FAILURE,
         NOTE_TYPES_REQUEST, NOTE_TYPES_REQUEST_SUCCESS, NOTE_TYPES_REQUEST_FAILURE
       } from "actions/notes-actions";
import { addElementToList } from "helpers/ListHelpers";

const INITIAL_STATE = { notes: { list: [], loading: false, error: null },
                        noteTypes: { list: [], loading: false, error: null},
                        editData: { note_type: '0' }};

export const NotesReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case NOTES_REQUEST:
      return {...state, notes:{...state.notes, loading: true, error: null }};
    case NOTES_REQUEST_SUCCESS:
      return {...state, notes:{...state.notes, list: action.payload, loading: false }};
    case NOTES_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, notes:{...state.notes, error: error, loading: false }};

    case CREATE_NOTE_REQUEST:
      return {...state, notes:{...state.notes, loading: true, error: null }};
    case CREATE_NOTE_SUCCESS:
      return {...state, notes: {...state.notes, list: addElementToList(state.notes.list, action.payload), loading: false } };
    case CREATE_NOTE_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, notes: {...state.notes, error: error, loading: false} };

    case NOTE_TYPES_REQUEST:
      return {...state, noteTypes: {...state.noteTypes, loading: true, error: null }};
    case NOTE_TYPES_REQUEST_SUCCESS:
      return {...state, noteTypes: {...state.noteTypes, list: action.payload, loading: false }};
    case NOTE_TYPES_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, noteTypes: { ...state.noteTypes, error: error, loading: false}};

    default:
      return state;
  }
};

export default NotesReducer;