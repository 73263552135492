import { OTHER_SELECT_OPTION } from "helpers/AppConstants";

export const baseJobValidation = values => {
  const required = 'Required';
  const errors = {};
  const requiredAddress =  {
    street: required,
    city: required,
    state: required,
    country: required
  };

  if (!values.job_address ) {
    errors.job_address = requiredAddress
  }
  // if (!values.buildingType) {
  //   errors.buildingType = required
  // }
  if (values.buildingType && values.buildingType === OTHER_SELECT_OPTION) {
    if (!values.building_type_other) errors.building_type_other = required
  }
  // if (!values.roofType) {
  //   errors.roofType = required
  // }
  if (values.roofType && values.roofType === OTHER_SELECT_OPTION) {
    if (!values.roof_type_other) errors.roof_type_other = required
  }
  // if (!values.jobType) {
  //   errors.jobType = required
  // }
  if (values.jobType && values.jobType === OTHER_SELECT_OPTION) {
    if (!values.job_type_other) errors.job_type_other = required
  }
  if (values.roofAccess && values.roofAccess === OTHER_SELECT_OPTION) {
    if (!values.roof_access_other) errors.roof_access_other = required
  }

  if (values.timeFrom || values.timeTo || values.appointmentDate) {
    if (!values.appointmentDate) {
      errors.appointmentDate = required
    }
    if (!values.timeFrom) {
      errors.timeFrom = required
    }
    if (!values.timeTo ) {
      errors.timeTo = required
    }
    if (!values.estimator ) {
      errors.estimator = required
    }
  }


  return errors
};
