import React from "react";
import { ButtonToolbar } from 'react-bootstrap'
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { validate } from 'form-validations/validation-appointment-form'
import { AppointmentFormFields } from "./appointment-form-fields";
import { formatTimeToTimePicker, appointmentTimeFrame } from "helpers/DateTimeHelpers";
import { appointmentFormDataFromTimeSlots } from "helpers/Calendar";

class AppointmentForm extends React.Component {
  constructor (props) {
    super(props);
    this.closeForm = this.closeForm.bind(this);
    this.appointmentTimeFrame    = this.appointmentTimeFrame.bind(this);
    this.selectInitializers      = this.selectInitializers.bind(this);
    this.initializeFromEstimate  = this.initializeFromEstimate.bind(this);
  }

  componentWillMount () {
    this.initializeForm(this.selectInitializers())
  }

  selectInitializers() {
    const { timeSlotsData, estimate } = this.props;
    if (timeSlotsData && timeSlotsData.start) {
      return appointmentFormDataFromTimeSlots(timeSlotsData)
    }
    return this.initializeFromEstimate(estimate)
  }

  initializeFromEstimate(estimate) {
    return {
      timeFrom: formatTimeToTimePicker(estimate.time_from),
      timeTo: formatTimeToTimePicker(estimate.time_to),
      appointmentDate: estimate.appointment_date
    }
  }

  initializeForm(formData) {
    const { estimate } = this.props;
    this.props.initialize({estimate_id: estimate.id, ...formData});
  }

  closeForm() {
    const { reset, onClose } = this.props;
    reset();
    onClose()
  }

  appointmentTimeFrame(value) {
    appointmentTimeFrame(value, this.props)
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off" className="validation-inputs">
        <AppointmentFormFields appointmentTimeFrame={this.appointmentTimeFrame} />
        <ButtonToolbar className="flex-justify">
          <button className="btn btn-success" type="submit" disabled={submitting}>Save</button>
          <button className="btn btn-danger" type="button" disabled={submitting}
                  onClick={this.closeForm}>Cancel</button>
        </ButtonToolbar>
      </form>
    )
  }
}

AppointmentForm = reduxForm({
  form: 'appointment-form',
  validate,
})(AppointmentForm);

AppointmentForm.defaultProps = {
  onClose: () => {}
};

AppointmentForm.propTypes = {
  timeSlotsData: PropTypes.object,
  onClose:       PropTypes.func,
  estimate:      PropTypes.object.isRequired
};

export default AppointmentForm;

