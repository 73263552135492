import React from "react";
import PropTypes from 'prop-types';
import { Button } from "react-bootstrap"

import { callbackClosePath } from "helpers/PathHelpers";

export const CallbackCloseLink = props => {
  const { callback, disabled } = props;
  const title = "Close Callback";
  return (
    <Button className="text-ellipsis" title={title} disabled={disabled} href={callbackClosePath(callback.id)}>
      {title}
    </Button>
  )
};

CallbackCloseLink.propTypes = {
  callback: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};
