import ApiClient from './ApiClient'

class NotesApi extends ApiClient {
  constructor() {
    super({url:'notes', dataWrapperKey: 'note' })
  }
  index(type, id) {
    return super.index({resource_type: type, resource_id: id})
  }
  noteTypes() {
    return super.index({}, 'note_types')
  }
}
export default NotesApi