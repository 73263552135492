export const ADD_MATERIAL = "ADD_MATERIAL";
export const REMOVE_MATERIAL = "REMOVE_MATERIAL";
export const CHANGE_MATERIAL_BRAND = "CHANGE_MATERIAL_BRAND";

export const addMaterial = () => {
  return {
    type: ADD_MATERIAL
  }
};

export const removeMaterial = (index) => {
  return {
    type: REMOVE_MATERIAL,
    payload: index
  }
};

export const changeMaterialBrand = (brandsSeries) => {
  return {
    type: CHANGE_MATERIAL_BRAND,
    payload: brandsSeries
  }
};
