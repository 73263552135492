import React from "react";
import { ButtonToolbar, Row, Col } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form';

import { validate } from '../../form-validations/validation-bounceback-form'
import { RenderTextArea } from './render-field'

class BounceBackForm extends React.Component {
  constructor (props) {
    super(props);
    this.closeForm = this.closeForm.bind(this);
  }

  componentWillMount () {
    this.initializeForm(this.props.estimate)
  }

  initializeForm(estimate) {
    this.props.initialize({
      estimate_id: estimate.id
    });
  }

  closeForm() {
    const { reset, onClose } = this.props;
    reset();
    onClose()
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off" className="validation-inputs">
        <Row>
          <Col md={12} className="textarea-input">
            <Field label="Add a note" name="message" component={RenderTextArea} autoFocus/>
          </Col>
        </Row>
        <ButtonToolbar className="flex-justify">
          <button className="btn btn-success" type="submit" disabled={submitting}>Bounce back</button>
          <button className="btn btn-danger" type="button" disabled={submitting}
                  onClick={this.closeForm}>Cancel</button>
        </ButtonToolbar>
      </form>
    )
  }
}

BounceBackForm = reduxForm({
  form: 'bounce-back-form',
  validate,
})(BounceBackForm);

export default BounceBackForm;