import React from "react";
import { Col, Nav, NavItem, Badge } from 'react-bootstrap';

import { ALL_ASSIGNED_ESTIMATES, UNASSIGNED_ESTIMATES } from "helpers/AppConstants";

class Sidebar extends React.Component {
  navItem(estimator) {
    return (
      <NavItem key={estimator.id} eventKey={estimator.id}>
        {estimator.name} <Badge>{estimator.count}</Badge>
      </NavItem>
    )
  }

  render() {
    const { sidebarData } = this.props;

    return (
      <Col sm={3}>
        <Nav bsStyle="tabs" className="tabs-left">
          <NavItem eventKey={UNASSIGNED_ESTIMATES}>
            Unassigned estimates <Badge>{sidebarData.unassigned}</Badge>
          </NavItem>
          <NavItem eventKey={ALL_ASSIGNED_ESTIMATES}>
            All assigned estimates <Badge>{sidebarData.all_assigned}</Badge>
          </NavItem>
          { sidebarData.estimators.map((estimator) => this.navItem(estimator)) }
        </Nav>
      </Col>
    )
  }
}
export default Sidebar;


