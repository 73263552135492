import UserNotAvailableApi from 'api/UserNotAvailableApi';
import { fetchUserNotAvailables } from 'actions/user-not-available';

export const UPDATE_CALENDAR = 'UPDATE_CALENDAR';
export const RESET_UPDATE_CALENDAR = 'RESET_UPDATE_CALENDAR';
export const SELECT_ESTIMATOR = 'SELECT_ESTIMATOR';
export const CLOSE_ESTIMATES_PANEL = 'CLOSE_ESTIMATES_PANEL';
export const SELECT_TIME_SLOTS = 'SELECT_TIME_SLOTS';
export const SELECT_EVENT = 'SELECT_EVENT';
export const RESET_SELECTED_EVENT = 'RESET_SELECTED_EVENT';
export const CLEAR_TIME_SLOTS_DATA = 'CLEAR_TIME_SLOTS_DATA';
export const CLOSE_REMOVE_UNAVALIABLE_DATE_MODAL = 'CLOSE_REMOVE_UNAVALIABLE_DATE_MODAL';
export const OPEN_REMOVE_UNAVALIABLE_DATE_MODAL = 'OPEN_REMOVE_UNAVALIABLE_DATE_MODAL';
export const OPEN_JOB_DETAILS_MODAL = 'OPEN_JOB_DETAILS_MODAL';
export const CLOSE_JOB_DETAILS_MODAL = 'CLOSE_JOB_DETAILS_MODAL';

export const openJobDetailsModal = (event) => {
  return {
    type: OPEN_JOB_DETAILS_MODAL,
    payload: event
  }
};

export const updateCalendar = () => {
  return {
    type: UPDATE_CALENDAR
  }
}

export const resetUpdateCalendar = () => {
  return {
    type: RESET_UPDATE_CALENDAR
  }
}

export const openRemoveUnavailableDateModal = (event) => {
  return {
    type: OPEN_REMOVE_UNAVALIABLE_DATE_MODAL,
    payload: event
  }
};

export const closeRemoveUnavailableDateModal = () => {
  return {
    type: CLOSE_REMOVE_UNAVALIABLE_DATE_MODAL
  }
};

export const closeJobDetailsModal = () => {
  return {
    type: CLOSE_JOB_DETAILS_MODAL
  }
};

export const selectEstimator = (data) => {
  return {
    type: SELECT_ESTIMATOR,
    payload: data
  }
};

export const closeEstimatesPanel = () => {
  return {
    type: CLOSE_ESTIMATES_PANEL,
  }
};

export const selectTimeSlots = (data) => {
  return {
    type: SELECT_TIME_SLOTS,
    payload: data
  }
};

export const clearTimeSlotsData = () => {
  return {
    type: CLEAR_TIME_SLOTS_DATA
  }
};


export const selectEvent = (data) => {
  return {
    type: SELECT_EVENT,
    payload: data
  }
};

export const resetSelectedEvent = () => {
  return {
    type: RESET_SELECTED_EVENT
  }
};

const userNotAvailableApi = new UserNotAvailableApi();

export const removeUnavailableDate = (event) => {
  return (dispatch) => {
    return userNotAvailableApi.deleteNotAvailableUser(event.id)
      .then(function() {
        dispatch(closeRemoveUnavailableDateModal());
        dispatch(fetchUserNotAvailables());
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
