import { OTHER_SELECT_OPTION } from "helpers/AppConstants";

export const formatOtherFieldKey = (fieldKey) => `${fieldKey}_other`;

export const onOtherOptionSelect = (value) => value === OTHER_SELECT_OPTION ? null : value;
export const setOtherField = (option, otherField, setBoth = false) => {
  if (setBoth) return otherField;
  return option !== OTHER_SELECT_OPTION ? null : otherField;
};

export const otherOptionValueIfNeed = (object, fieldKey) => {
  const optionObject = object[fieldKey];
  const optionOther = object[formatOtherFieldKey(fieldKey)];
  if (optionObject) return optionObject.id;
  return !optionObject && optionOther ? OTHER_SELECT_OPTION : null;
};

export const normalizePhone = (value) => {
  if (!value) return value;
  const onlyNumbers = value.replace(/\D/g, '').substring(0, 10);
  const char = { 0: '(', 3: ') ', 6: '-' };
  value = '';
  for (let i = 0; i < onlyNumbers.length; i++) {
    value += (char[i] || '') + onlyNumbers[i];
  }
  return value;
};

export const stringToSelectObj = (string) => {
  return {
    id: string.toLowerCase().split(' ').join('_'),
    name: string
  }
};
