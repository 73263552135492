import React from "react";
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { BorderedWrapper } from "shared/bordered-wrapper";
import { validate } from 'form-validations/validation-callback-close-form';
import { RenderTextArea, RenderSelect, renderDatePicker, RenderCheckbox } from "shared/render-field";
import { CALLBACK_CLOSE_FORM_NAME } from "helpers/FormNames";
import { AppointmentTimeInputs } from "shared/appointment-time-inputs";
import { appointmentTimeFrame } from "helpers/DateTimeHelpers";
import { parseJobToForm } from "api/job";
import { isCallbackClosed } from "helpers/Callback";
import { estimators } from "helpers/User";

class CallbackCloseForm extends React.Component {
  constructor(props) {
    super(props);
    this.defaultTimeFrame = this.defaultTimeFrame.bind(this);
    this.renderInputFieldsBlock = this.renderInputFieldsBlock.bind(this);
  }

  defaultTimeFrame (value, field) {
    appointmentTimeFrame(value, this.props, field)
  }

  renderInputFieldsBlock(disabled, namePrefix = '') {
    const { users } = this.props;
    return (
      <div>
        <Row>
          <Col md={4}>
            <Field name={`${namePrefix}estimator`}
                   label="Assigned to"
                   component={RenderSelect}
                   options={estimators(users)}
                   disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Field label="Date"
                   name={`${namePrefix}appointmentDate`}
                   component={renderDatePicker}
                   disabled={disabled}
            />
          </Col>
          <AppointmentTimeInputs
            size={2} namePrefix={namePrefix}
            appointmentTimeFrame={this.defaultTimeFrame}
            disableFields={disabled}
          />
        </Row>
        <Row>
          <Col md={12}>
            <Field name={`${namePrefix}note`} label="Comments" disabled={disabled} component={RenderTextArea} />
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const { submitting, handleSubmit, follow_up, job } = this.props;
    return (
      <BorderedWrapper>
        <form onSubmit={handleSubmit} autoComplete="off" className="note-form">
          {this.renderInputFieldsBlock(false)}
          <Row className="padding-v-10">
            <Col md={1} sm={2} xs={6}>
              <label className="margin-top-10">Follow up</label>
            </Col>
            <Col sm={1} xs={6}>
              <Field
                label="Yes"
                name="follow_up"
                hideBlankLine
                component={RenderCheckbox}
              />
            </Col>
          </Row>
          {this.renderInputFieldsBlock(!follow_up, 'follow_up_')}
          <Row>
            <Col md={12}>
              {!isCallbackClosed(job) &&
              <button className="btn btn-success" type="submit" disabled={submitting}>Close</button>}
            </Col>
          </Row>
        </form>
      </BorderedWrapper>
    )
  }
}

CallbackCloseForm = reduxForm({
  form: CALLBACK_CLOSE_FORM_NAME,
  validate,
  enableReinitialize : true
})(CallbackCloseForm);

const selector = formValueSelector(CALLBACK_CLOSE_FORM_NAME);
CallbackCloseForm = connect(state => {
  const follow_up = selector(state, 'follow_up');
  return {
    follow_up
  };
})(CallbackCloseForm);

CallbackCloseForm = connect(
  state => ({
    initialValues: parseJobToForm(state.job.data),
  }))(CallbackCloseForm);

CallbackCloseForm.defaultProps = {
  users: []
};

CallbackCloseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

export default CallbackCloseForm
