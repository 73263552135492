import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { OverflowScroll } from "shared/oferflow-scroll";
import CallbackForm from "./callback-form";
import CallbacksList from "./callbacks-list";

class CallbacksComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addCallback = this.addCallback.bind(this);
  }

  addCallback(callbackParams) {
    const { id, addCallback } = this.props;
    const dataToSend = { ...callbackParams, id, callback_serializer: true };
    addCallback(dataToSend);
  }

  render() {
    const { users, callbacks, isJobPage, convertCallbackToJob } = this.props;
    const form = (
      <div>
        <CallbackForm onSubmit={this.addCallback} users={users.list} />
        <hr/>
      </div>
    );
    const callbacksList = (
      <div className="margin-top-10">
        { isJobPage && form}
        <CallbacksList
          {...this.props}
          callbacks={callbacks.list}
          convertCallbackToJob={convertCallbackToJob}
        />
      </div>
    );
    if (!isJobPage) {
      return (
        <OverflowScroll>
          {callbacksList}
        </OverflowScroll>
      )
    }
    return callbacksList
  }
}

CallbacksComponent.defaultProps = {
  users: {},
  callbacks: { list: [] }
};

CallbacksComponent.propTypes = {
  id: PropTypes.any.isRequired,
  users: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired
};

export default connect()(CallbacksComponent);
