import moment from 'moment';

export const DAY_START = '05:00 am';
export const CALENDAR_START_TIME = '07:00 am';
export const DAY_END = '11:00 pm';
export const TIME_FORMAT = 'hh:mm a';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const ISO_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT} ${TIME_FORMAT}`;
export const DEFAULT_TIME_INTERVAL = 30;

// V2 Constants for updated Date function - https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
export const DATE_FORMAT_II = 'MM/dd/yyyy';
export const ISO_DATE_FORMAT_II = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT_II = `${DATE_FORMAT_II} ${TIME_FORMAT}`;

const HOUR_IN_SECONDS = 3600;
const MINUTE_IN_SECONDS = 60;
const HALF_HOURS_IN_SECONDS = 1800;
const DEFAULT_TIME_RANGE = 1.5;

const secondsToMilliseconds = (seconds) => seconds * 1000;
export const dateToMilliseconds = (date) => date.getTime();

export const formatDateToISO = (date, format = ISO_DATE_FORMAT) => {
  if (!date) return;
  return moment(date, DATE_TIME_FORMAT).format(format);
}

export const relevantDate = (data, endTime=false) => {
  if (!data) return false;

  const timeNow = dateToMilliseconds(new Date());
  const selectedDate = new Date(moment(data.date, DATE_FORMAT));
  const selectedTime = endTime ? data.end : data.start;
  const selectedTimeInMilliseconds = dateToMilliseconds(selectedDate) + secondsToMilliseconds(selectedTime);
  return timeNow < selectedTimeInMilliseconds;
};

export const passedTodoDueDate = (date) => {
  if (!date) return false;

  const currentDate = new Date(moment().endOf('day')).getTime();
  const dueDate = new Date(moment(date, DATE_FORMAT).endOf('day')).getTime();
  return currentDate <= dueDate;
};

export const relevantTimeRange = (data) => {
  if (!data) return false;

  return (data.end - data.start) > HALF_HOURS_IN_SECONDS;
};

const addDefaultTime = (val) => val && val + HOUR_IN_SECONDS * DEFAULT_TIME_RANGE;
export const appointmentTimeFrame = (val, props, fieldName = 'timeTo') => {
  let time = addDefaultTime(val);
  props.autofill(fieldName, time )
};

export const formatTimeToTimePicker = (time) => {
  if(!time) return;

  time = moment(time, TIME_FORMAT);
  let hours = time.hours();
  let minutes = time.minutes();
  let seconds = time.seconds();
  return (hours * HOUR_IN_SECONDS) + (minutes * MINUTE_IN_SECONDS) + seconds
};

export const formatDateToDatePicker = (date) => {
  if(!date) return;
  return moment(date).format(DATE_FORMAT);
};

export const formatHours = (seconds) => {
  if(!seconds) return;
  let date = new Date;
  date.setHours(0,0,0);
  let selectedMilliseconds = dateToMilliseconds(date) + secondsToMilliseconds(seconds);
  return moment(selectedMilliseconds).format(TIME_FORMAT);
};

export const setMomentDate = (dateString, dateFormat) => {
  const date = moment(dateString, dateFormat);
  if (date.isValid()) return date;
  return moment();
};

export const setDatePickerDate = (momentString) => {
  if(momentString === true) return new Date()
  return momentString.toDate()
}

export const newDateFromString = (dateString) => {
  return new Date(moment(dateString));
};

export const renderAppointmentTime = (job) => {
  if (!job.appointment_date) return '';
  return `${job.appointment_date} ${job.time_from ? job.time_from : '' }`
};

export const oneYearAgo = () => {
  const date = moment().startOf('month').subtract(1, 'years');
  return formatDateToDatePicker(date)
};

export const oneYearAgoDateFormat = () => {
  const date = moment().startOf('month').subtract(1, 'years');
  return date
};

export const timeFromNow = (dateTime) => {
  return moment(dateTime, DATE_TIME_FORMAT).fromNow();
};

export const convertDateTimeToStringFormat = (dateTime) => {
  return moment(dateTime, DATE_TIME_FORMAT).format('LLL');
};

export const parseDate = (date, time) => {
  if (!date) return null;
  const dateString = time ? `${date} ${time}` : date;
  return new Date(moment(dateString, DATE_TIME_FORMAT))
};

export const parseTime = (time) => {
  return new Date(moment(time, TIME_FORMAT));
};
