import React from "react";
import PropTypes from 'prop-types';
import {Col, Clearfix} from 'react-bootstrap'
import { renderContacts } from "./render-contacts";

export const DescriptionRow = (props) => {
  return (
    <div>
      <Col xs={6}><b>{props.name}</b></Col>
      <Col xs={6}>{renderContacts(props)}</Col>
      <Clearfix />
    </div>
  )
};

DescriptionRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  isPhone: PropTypes.bool
};
