import React from "react";
import { Provider } from "react-redux";

import configureCallFormStore from "app/store/configureCallFormStore";
import NewJob from 'app/containers/CallFormContainer';

const store = configureCallFormStore();

class CallForm extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <NewJob jobOptions={this.props.job_options}
                clientOptions={this.props.client_options}/>
      </Provider>
    )
  }
}
export default CallForm;

