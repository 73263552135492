import RoofPitchesApi from "api/RoofPitchesApi";

export const ROOF_PITCHES_REQUEST = 'ROOF_PITCHES_REQUEST';
export const ROOF_PITCHES_REQUEST_SUCCESS = 'ROOF_PITCHES_REQUEST_SUCCESS';
export const ROOF_PITCHES_REQUEST_FAILURE = 'ROOF_PITCHES_REQUEST_FAILURE';

const roofPitchesRequest = () => {
  return {
    type: ROOF_PITCHES_REQUEST,
  }
};

const roofPitchesRequestSuccess = (roofPitches) => {
  return {
    type: ROOF_PITCHES_REQUEST_SUCCESS,
    payload: roofPitches
  }
};

const roofPitchesRequestFailure = (error) => {
  return {
    type: ROOF_PITCHES_REQUEST_FAILURE,
    payload: error
  }
};

const roofPitchesApi = new RoofPitchesApi();

export const fetchRoofPitches = () => {
  return (dispatch) => {
    dispatch(roofPitchesRequest());
    return roofPitchesApi.index()
      .done((data) => {
        dispatch(roofPitchesRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(roofPitchesRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
