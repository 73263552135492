import React from "react";
import PropTypes from 'prop-types';

import { Clearfix } from 'react-bootstrap'

import { jobPath } from "helpers/PathHelpers";
import { addressToString } from 'actions/addresses';
import { selectOptionOrOther } from "helpers/ViewHelpers";

const RenderJob = (props) => {
  const { job } = props;
  return (
    <div className="client-job bottom-divider">
      <a className="pull-right" href={jobPath(job.id)}>View details</a>
      <div className="left"><p>Roof type</p></div>
      <div className="right"><p>{ selectOptionOrOther("roof_type", job) || '-' }</p></div>
      <Clearfix />

      <div className="left"><p>Building type</p></div>
      <div className="right"><p>{ selectOptionOrOther("building_type", job) || '-' }</p></div>
      <Clearfix />

      <div className="left"><p>Location</p></div>
      <div className="right"><p>{ addressToString(job.address) }</p></div>
      <Clearfix />

      <div className="left"><p>Status</p></div>
      <div className="right"><p>{ job.status }</p></div>
      <Clearfix />
      { props.children }
    </div>
  )
};

RenderJob.propTypes = {
  job: PropTypes.object.isRequired
};

export default RenderJob;
