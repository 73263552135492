export const isMobile = () => {
  let screenWidth = window.innerWidth;
  return screenWidth < 768
};

export const calculateFullHeight = (element) => {
  if(!element) return 0;
  let elementHeight;
  let screenHeight = window.innerHeight;
  if (isMobile()) {
    elementHeight =  screenHeight;
  } else {
    let elementPosition = element.getBoundingClientRect().top || 0;
    elementHeight = screenHeight - elementPosition;
  }
  return  elementHeight - 10;
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if(element) element.scrollIntoView({behavior: 'smooth'});
};
