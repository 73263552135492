export const validate = values => {
  const required = 'Required';

  const errors = {};

  if (values.timeFrom || values.timeTo || values.appointmentDate) {
    if (!values.appointmentDate) {
      errors.appointmentDate = required
    }
    if (!values.timeFrom) {
      errors.timeFrom = required
    }
    if (!values.timeTo ) {
      errors.timeTo = required
    }
  }

  return errors
};