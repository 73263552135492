import React from "react";
import PropTypes from 'prop-types';
import { googleMapsPlacePath, convertStringAsUrlPart } from "helpers/PathHelpers";
import { addressToString } from "actions/addresses";

export class Address extends React.Component {
  constructor(props) {
    super(props);
    this.renderAddressInline = this.renderAddressInline.bind(this);
    this.renderAddress = this.renderAddress.bind(this);
  }

  renderSameLabel(asContactLocation) {
    if (asContactLocation) {
      return (<span className="label label-warning">Same as billing address</span>);
    }
  }

  renderAddressInline(address) {
    return (
      <p>{ address.street }, { address.city }, { address.state }, { address.zip }</p>
    );
  }

  renderAddress(address) {
    return (
      <div>
        <p>{ address.street }</p>
        <p>{ address.city }, { address.state } { address.zip }</p>
      </div>
    );
  }

  render() {
    const { address, asContactLocation, showInline } = this.props;

    return (
      <div>
        <p>{ asContactLocation ? this.renderSameLabel(asContactLocation) : '' }</p>
        <a target='_blank' href={`${googleMapsPlacePath()}/${convertStringAsUrlPart(addressToString(address))}`}>
          { showInline ? this.renderAddressInline(address) : this.renderAddress(address) }
        </a>
      </div>
    );
  }
}

Address.defaultProps = {
  address: {},
  showInline: false
};

Address.propTypes = {
  address: PropTypes.object
};
