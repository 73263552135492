import React from "react";
import { Provider } from "react-redux";

import configureJobStore from "store/configureJobStore";
import JobComponent from 'containers/JobContainer'

const store = configureJobStore();

class Job extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <JobComponent id={this.props.id} jobOptions={this.props.job_options} />
      </Provider>
    )
  }
}
export default Job;