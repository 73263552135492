import { formatOtherFieldKey } from "helpers/FormHelpers";

export const selectOptionOrOther = (fieldKey, object, checkBoth = false) => {
  const option = object[fieldKey];
  const otherField = object[formatOtherFieldKey(fieldKey)];
  let result = option ? option.name : otherField;
  if (checkBoth && option && otherField ) {
    result += ` / ${otherField}`
  }
  return result;
};

export const extractNumbersFromStringForPhone = (value) => {
  return value.replace(/\D/g, '').substring(0, 10);
};
