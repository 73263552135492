export const SELECT_TAB = "SELECT_TAB";
export const RESET_DATA = "RESET_DATA";

export const selectTab = (selectedTab) => {
  return {
    type: SELECT_TAB,
    payload: selectedTab
  }
};

export const resetTabsData = () => {
  return {
    type: RESET_DATA
  }
};
