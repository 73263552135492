import { createStore, compose } from "redux"

import JobReducers from "reducers/job-reducers"
import configureMiddleware from "store/configure-middleware";

const configureJobStore = preloadedState => {
  return createStore(
    JobReducers,
    preloadedState,
    compose(
      configureMiddleware()
    )
  );
};

export default configureJobStore
