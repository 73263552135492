import React from "react";
import {Tabs, Tab} from 'react-bootstrap'

import ActivityLogComponent from "activity-log/activity-log-component";
import TodosComponent from 'todos/todos-component';
import NotesComponent from 'notes/notes-component';
import CallbacksComponent from 'callbacks/callbacks-component';
import DocumentComponent from 'documents/document-component'
import { TYPE_JOB } from "helpers/AppConstants";

export class ActivityTabs extends React.Component {
  constructor(props) {
    super(props);
    this.isJobPage = this.props.type === TYPE_JOB;
    this.canRenderCallbacks = this.canRenderCallbacks.bind(this);
  }

  canRenderCallbacks() {
    const { job } = this.props;
    if (!this.isJobPage) return true;

    return !job.data.callback;
  }

  render() {
    return (
      <Tabs defaultActiveKey={3} id="additional-info" mountOnEnter={!this.isJobPage}>
        <Tab eventKey={3} title={<div>Todos <span className="badge">{this.props.todos.list.length}</span></div>}>
          <TodosComponent {...this.props} isJobPage={this.isJobPage} />
        </Tab>
        <Tab eventKey={1} title={<div>Activity log <span className="badge">{this.props.activityLog.list.length}</span></div>}>
          <ActivityLogComponent {...this.props} isJobPage={this.isJobPage} />
        </Tab>
        {!this.isJobPage && (
          <Tab eventKey={2} title="Notes">
            <NotesComponent {...this.props} isJobPage={this.isJobPage} />
          </Tab>
        )}
        {this.canRenderCallbacks() && (
          <Tab eventKey={4} title="Callbacks">
            <CallbacksComponent {...this.props} isJobPage={this.isJobPage} />
          </Tab>
        )}
        {this.isJobPage && (
          <Tab eventKey={5} title="Documents">
            <DocumentComponent {...this.props} jobId={this.props.id} />
          </Tab>
        )}
      </Tabs>
    )
  }
}
