import React from "react";
import { Well } from "react-bootstrap";

import { CALLBACK_STATUS_OPEN } from "helpers/AppConstants";

import Callback from "callbacks/callback";

export class CallbackActive extends React.Component {
  constructor(props) {
    super(props);
    this.findOpenCallback = this.findOpenCallback.bind(this)
    this.renderCallback = this.renderCallback.bind(this)
  }

  findOpenCallback() {
    const { callbacks, job } = this.props;
    if (!callbacks.list.length || job.callback ) return null;
    return callbacks.list.find(callback => callback.status === CALLBACK_STATUS_OPEN);
  }

  renderCallback(callback) {
    const { convertCallbackToJob } = this.props;
    return (
      <Callback
        Wrapper={Well}
        isJobPage
        callback={callback}
        convertCallbackToJob={convertCallbackToJob}
      >
        <h4 className="margin-top-0 margin-bottom-0">
          <span className="italic-font text-muted">Active Callback</span>
        </h4>
      </Callback>
    )
  }

  render() {
    const callback = this.findOpenCallback();
    return  callback ? this.renderCallback(callback) : null;
  }
}
