import { CALLBACKS_REQUEST, CALLBACKS_REQUEST_SUCCESS, CALLBACKS_REQUEST_FAILURE,
         CALLBACK_TO_JOB_REQUEST, CALLBACK_TO_JOB_REQUEST_SUCCESS, CALLBACK_TO_JOB_REQUEST_FAILURE
       } from "../actions/callback-actions";
import { CREATE_JOB, CREATE_JOB_FAILURE, CREATE_JOB_SUCCESS,
       } from 'actions/jobs-actions';

import { updateList, addElementToList } from "helpers/ListHelpers";

const INITIAL_STATE = { list: [], error: null, loading: false };

const CallbacksReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case CALLBACKS_REQUEST:
      return { ...state, error: null, loading: true };
    case CALLBACKS_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false };
    case CALLBACKS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    case CALLBACK_TO_JOB_REQUEST:
      return { ...state, error: null, loading: true };
    case CALLBACK_TO_JOB_REQUEST_SUCCESS:
      return {...state, loading: false };
    case CALLBACK_TO_JOB_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    case CREATE_JOB:
      return { ...state, error: null, loading: true };
    case CREATE_JOB_SUCCESS:
      return {...state, list: addElementToList(state.list, action.payload), loading: false };
    case CREATE_JOB_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    default:
      return state;
  }
};

export default CallbacksReducer
