import React from 'react';

export class BorderedWrapper extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <div className={`bordered ${className}`}>
        {this.props.children}
      </div>
    )
  }
}

BorderedWrapper.defaultProps = {
  className: ''
};