import React from "react";
import PropTypes from "prop-types";

import ElementLoader from 'shared/element-loader';

const Wrapper = props => {
  const { loading } = props;
  const loader = <ElementLoader />;
  return (
    <div className="relative-element">
      { loading && loader }
      { props.children }
    </div>
  )
};

Wrapper.defaultProps = {
  loading: false
};

Wrapper.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default Wrapper;

