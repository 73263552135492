import React from "react";
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Row, Col, Label } from 'react-bootstrap'

import { jobPath } from "helpers/PathHelpers";
import { CallbackCloseLink } from "shared/callback-close-link";
import { isCallbackClosed } from "helpers/Callback";
import { selectStatusLabelClass } from "helpers/Job";

const Callback = (props) => {
  const { callback, isJobPage, convertCallbackToJob, Wrapper } = props;
  const callbackClosed = isCallbackClosed(callback);
  const jobLink = <a className="pull-right" href={jobPath(callback.job_id)}>View Job</a>;
  const callbackLink = <a className="pull-right" href={jobPath(callback.id)}>View Callback</a>;
  const note = <span className="italic-font wrap-word">{callback.note}</span>;
  const callbackDateStatus = (
    <span>
        <span className="small italic-font">{callback.created_at}</span>
      {' '}
      <Label bsStyle={selectStatusLabelClass(callback)}>{callback.status}</Label>
      </span>
  ) ;


  return (
    <Wrapper>
      {props.children}
      <Row>
        <div className="flex-center-align">
          <Col md={isJobPage ? 9 : 12}>
            { isJobPage && <p>{callbackDateStatus}</p> }
            <p>
              Assigned to: <b>{callback.user && callback.user.name}</b>
              { !isJobPage && jobLink }
            </p>
            { !isJobPage && <span className="pull-right">{callbackDateStatus}</span>}
            <p>Comments: { !isJobPage && <br/> } {note} { !isJobPage && callbackLink }</p>
          </Col>
          { isJobPage &&
          <Col md={3}>
            <ButtonGroup vertical>
              <a href={jobPath(callback.id)} className="btn btn-default">View callback</a>
              <CallbackCloseLink disabled={callbackClosed} callback={callback} />
              <Button disabled={callbackClosed} onClick={() => convertCallbackToJob(callback.id)}>Create a new job</Button>
            </ButtonGroup>
          </Col> }
        </div>
      </Row>
    </Wrapper>
  )
};

Callback.defaultProps = {
  callback: {},
  convertCallbackToJob: () => {},
  isJobPage: false
};

Callback.propTypes = {
  callback: PropTypes.object.isRequired,
  convertCallbackToJob: PropTypes.func.isRequired,
  Wrapper: PropTypes.func.isRequired,
  isJobPage: PropTypes.bool
};

export default Callback
