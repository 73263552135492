import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import {
  JOBS_REQUEST, JOBS_REQUEST_SUCCESS, JOBS_REQUEST_FAILURE,
  UPDATE_JOB_REQUEST_SUCCESS, UPDATE_JOB_REQUEST_FAILURE
} from 'actions/jobs-actions';
import { RESET_DATA } from "actions/jobs-tabs-actions";
import { updateList } from "helpers/ListHelpers";
import MaterialBrandsReducer from "reducers/material-brands-reducer";
import EditJobReducer from 'reducers/edit-job-reducer';
import HeatmapsReducer from "reducers/statistics/heatmaps-reducer";
import TabsReducer from "reducers/tabs-reducer";
import StatusesCountsByEstimatorReducer from "reducers/statistics/statuses-counts-by-estimator-reducer";
import TimelineReducer from "reducers/statistics/timeline-reducer";
import StatusesCountsReducer from "reducers/statistics/statuses-counts-reducer";
import DistributionsReducer from "reducers/statistics/distributions-reducer";
import { SearchReducer } from "reducers/search-reducer";

const INITIAL_STATE = { list: [], meta: {}, error: null, loading: false};

export const JobsReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case JOBS_REQUEST:
      return {...state, loading: true};
    case JOBS_REQUEST_SUCCESS:
      return {...state, list: action.payload.jobs, meta: action.payload.meta, error:null, loading: false};
    case JOBS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};

    case UPDATE_JOB_REQUEST_SUCCESS:
      return {...state, list: updateList(state.list, action.payload) }
    case UPDATE_JOB_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error};
    case RESET_DATA:
      return {...state, list: []};

    default:
      return state;
  }
};

const JobsListReducer = combineReducers({
  jobs: JobsReducer,
  editJob: EditJobReducer,
  tabs: TabsReducer,
  heatmaps: HeatmapsReducer,
  materialBrands: MaterialBrandsReducer,
  statusesCountsByEstimator: StatusesCountsByEstimatorReducer,
  statusesCounts: StatusesCountsReducer,
  timeline: TimelineReducer,
  distributions: DistributionsReducer,
  search: SearchReducer,
  form: reduxFormReducer
});

export default JobsListReducer
