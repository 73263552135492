import React from 'react';
import PropTypes from 'prop-types';

import { calculateFullHeight, isMobile } from "helpers/ScreenHelpers";

export class OverflowScroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wrapperStyles: {}
    };
    this.wrapperStyles = this.wrapperStyles.bind(this)
    this.getElementHeight = this.getElementHeight.bind(this)
  }

  componentDidMount() {
    this.setState({ wrapperStyles: this.wrapperStyles()})
  }

  getElementHeight() {
    let element = this.refs.scrollElement;
    return calculateFullHeight(element)
  }

  wrapperStyles() {
    const { height } = this.props;
    const heightType = isMobile() ? 'maxHeight' : 'height';
    return {
      [heightType]: height || this.getElementHeight(),
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingRight: '5px'
    }
  }

  render() {
    const { wrapperStyles } = this.state;
    return (
      <div ref="scrollElement" style={wrapperStyles} className="overflow-scroll">
        {this.props.children}
      </div>
    )
  }
}

OverflowScroll.propTypes = {
  height: PropTypes.string,
};
