import {
  SALE_ROOF_TYPES_REQUEST,
  SALE_ROOF_TYPES_REQUEST_SUCCESS,
  SALE_ROOF_TYPES_REQUEST_FAILURE
} from "actions/sale-roof-types-actions";

const INITIAL_STATE = { list: [], error: null, loading: false };

const SaleRoofTypesReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case SALE_ROOF_TYPES_REQUEST:
      return {...state, error: null, loading: true};
    case SALE_ROOF_TYPES_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false};
    case SALE_ROOF_TYPES_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};

    default:
      return state;
  }
};

export default SaleRoofTypesReducer
