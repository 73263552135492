import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Alert } from 'react-bootstrap'
import {OverflowScroll} from "./oferflow-scroll";

export class FocusElement extends React.Component {
  constructor(props) {
    super(props);
    this.keyHandlers = this.keyHandlers.bind(this)
    this.close = this.close.bind(this)
  }

  componentWillMount() {
    window.addEventListener('keydown', this.keyHandlers);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyHandlers);
  }

  keyHandlers(event) {
    switch (event.key) {
      case 'Escape':
        this.close();
        break
    }
  }

  close() {
    this.props.focus && this.props.onClose()
  }

  renderChildren(focus, children) {
    if (focus) {
      return (
        <OverflowScroll>
          {children}
        </OverflowScroll>
      )
    } else {
      return children
    }
  }

  render() {
    const { focus, text } = this.props;
    const overlay = <div className="overlay" />;
    return (
        <ReactCSSTransitionGroup
          transitionName="animate"
          transitionEnterTimeout={100}
          transitionLeaveTimeout={100}>
          {focus && overlay }
          {focus && <div className="overlay-alert">
            <Alert bsStyle="success" onDismiss={this.close}>
              {text}
            </Alert>
          </div>}
          <div className={`${focus ? 'overlay-focus-element': ''}`}>
            {this.renderChildren(focus, this.props.children)}
          </div>
        </ReactCSSTransitionGroup>

    )
  }
}

FocusElement.defaultProps = {
  focus: false
};

FocusElement.propTypes = {
  focus: PropTypes.bool,
  text: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired

};