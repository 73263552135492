import SaleRoofTypesApi from "api/SaleRoofTypesApi";

export const SALE_ROOF_TYPES_REQUEST = 'SALE_ROOF_TYPES_REQUEST';
export const SALE_ROOF_TYPES_REQUEST_SUCCESS = 'SALE_ROOF_TYPES_REQUEST_SUCCESS';
export const SALE_ROOF_TYPES_REQUEST_FAILURE = 'SALE_ROOF_TYPES_REQUEST_FAILURE';

const saleRoofTypesRequest = () => {
  return {
    type: SALE_ROOF_TYPES_REQUEST,
  }
};

const saleRoofTypesRequestSuccess = (estimateRoofTypes) => {
  return {
    type: SALE_ROOF_TYPES_REQUEST_SUCCESS,
    payload: estimateRoofTypes
  }
};

const saleRoofTypesRequestFailure = (error) => {
  return {
    type: SALE_ROOF_TYPES_REQUEST_FAILURE,
    payload: error
  }
};

const saleRoofTypesApi = new SaleRoofTypesApi();

export const fetchSaleRoofTypes = () => {
  return (dispatch) => {
    dispatch(saleRoofTypesRequest());
    return saleRoofTypesApi.index()
      .done((data) => {
        dispatch(saleRoofTypesRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(saleRoofTypesRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
