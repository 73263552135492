import ApiClient from 'api/ApiClient'

class ClientApi extends ApiClient {
  constructor() {
    super({url:'clients', dataWrapperKey: 'client'})
  }

  callbacks(id) {
    return super.getNestedAction(id, '/callbacks')
  }

  csvUrl(params) {
    const url = `${this.setUrl(this.url)}.csv`;
    return this.addParamsToUrl(url, params);
  }
}

export default ClientApi
