export const validate = values => {
  const required = 'Required';
  const errors = {};
  if (!values.lost_reason) {
    errors.lost_reason = required
  }
  if (!values.note) {
    errors.note = required
  }
  return errors
};
