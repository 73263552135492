import { currentUser, assignedToCurrentUser } from "helpers/User";

export const CAN_ASSIGN_ESTIMATOR = currentUser().permissions.assign_estimator;
export const CAN_SELECT_TODO_RESPONSIBLE = currentUser().permissions.todo_to_all_jobs;
export const CAN_ARCHIVE_CLIENT = currentUser().permissions.archive_client;

export const enableTodoButton = (todo) => {
  const responsible = getResponsibleUserId(todo);
  const canAddToAll = currentUser().permissions.todo_to_all_jobs;
  return canAddToAll || assignedToCurrentUser(responsible);
};

export const canAddTodoToJob = (job) => {
  const jobEstimator = getResponsibleUserId(job);
  const canAddToAll = currentUser().permissions.todo_to_all_jobs;
  return canAddToAll || assignedToCurrentUser(jobEstimator);
};

export const canEditEstimate = (estimate) => {
  const estimatorId =  getResponsibleUserId(estimate);
  return canEditAppointment(estimatorId)
};

export const canEditAppointment = (jobEstimatorId) => {
  const editAllEstimates =  currentUser().permissions.appointment_to_all_estimates;
  return editAllEstimates || assignedToCurrentUser(jobEstimatorId);
};

const getResponsibleUserId = (data = {}) => {
  return data.user && data.user.id;
};
