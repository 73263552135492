import React from "react";
import { Col, Tab } from 'react-bootstrap'

import UnassignedEstimates from './unassigned-estimates'
import AssignedEstimates from './assigned-estimates'
import Spinner from 'shared/spinner'
import { onlyAssignedEstimates } from "helpers/Estimates";

class TabContent extends React.Component {
  constructor (props) {
    super(props);
    this.renderPane = this.renderPane.bind(this);
  }

  renderPane(estimator, estimates, loader) {
    const { setAppointment, unassignEstimator }  = this.props;
    return (
      <Tab.Pane key={estimator.id} eventKey={estimator.id}>
        <p>{estimator.name} estimates</p>
        { estimates.loading ? loader : <AssignedEstimates {...this.props}
                                                          estimates={estimates}
                                                          setAppointment={setAppointment}
                                                          unassignEstimator={unassignEstimator} /> }
      </Tab.Pane>
    )
  }

  render() {
    const { estimators, estimates, assignEstimator, unassignEstimator, setAppointment }  = this.props;
    const assignedEstimates = onlyAssignedEstimates(estimates.list);
    const loader = <Spinner active={true} search><span>Loading...</span></Spinner>;
    return (
      <Col sm={9}>
        <Tab.Content animation mountOnEnter unmountOnExit>
          <Tab.Pane eventKey="unassigned">
            <p>Unassigned estimates</p>
            { estimates.loading ? loader : <UnassignedEstimates {...this.props}
                                                                estimators={estimators}
                                                                assignEstimator={assignEstimator}
                                                                estimates={estimates.list} /> }
          </Tab.Pane>
          <Tab.Pane eventKey="all_assigned">
            <p>All assigned estimates</p>
            { estimates.loading ? loader : <AssignedEstimates {...this.props}
                                                              showEstimator
                                                              estimates={assignedEstimates}
                                                              setAppointment={setAppointment}
                                                              unassignEstimator={unassignEstimator}/>
            }
          </Tab.Pane>
          {estimators.map((estimator) => this.renderPane(estimator, assignedEstimates, loader))}
        </Tab.Content>
      </Col>
    )
  }
}
export default TabContent;

