import { connect } from 'react-redux';

import CallbackCloseComponent from 'presentational/callback-close/callback-close-component';
import { fetchJob } from "api/job";
import { fetchNotes } from "actions/notes-actions";
import { fetchUsers } from "actions/user-actions";
import { closeCallback } from "actions/callback-actions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchJob: (id) => {
      dispatch(fetchJob(id))
    },
    fetchNotes: (type, id) => {
      dispatch(fetchNotes(type, id))
    },
    fetchUsers: () => {
      dispatch(fetchUsers())
    },
    closeCallback: (params) => {
      dispatch(closeCallback(params))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    job: state.job,
    notes: state.notes.notes,
    users: state.users
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallbackCloseComponent);
