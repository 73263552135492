import { formatHours } from "helpers/DateTimeHelpers";
import { CALLBACK_STATUS_CLOSED } from "helpers/AppConstants";

export const callbackParams = (callback) => {
  return {
    id: callback.id,
    user_id: callback.estimator,
    appointment_date: callback.appointmentDate,
    time_from: formatHours(callback.timeFrom),
    time_to: formatHours(callback.timeTo),
    note: callback.note,
    callback_serializer: callback.callback_serializer
  }
};

export const closeCallbackParams = (callback) => {
  return {
    id: callback.id,
    user_id: callback.estimator,
    appointment_date: callback.appointmentDate,
    time_from: formatHours(callback.timeFrom),
    time_to: formatHours(callback.timeTo),
    note: callback.note,
    follow_up: callback.follow_up,
    follow_up_params: {
      user_id: callback.follow_up_estimator,
      note: callback.follow_up_note,
      appointment_date: callback.follow_up_appointmentDate,
      time_from: formatHours(callback.follow_up_timeFrom),
      time_to: formatHours(callback.follow_up_timeTo),
    }
  }
};

export const isCallbackClosed = (callback) => {
  return callback.status === CALLBACK_STATUS_CLOSED
};
