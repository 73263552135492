import StatisticsApi from "api/StatisticsApi";

export const DISTRIBUTIONS_REQUEST = 'DISTRIBUTIONS_REQUEST';
export const DISTRIBUTIONS_REQUEST_SUCCESS = 'DISTRIBUTIONS_REQUEST_SUCCESS';
export const DISTRIBUTIONS_REQUEST_FAILURE = 'DISTRIBUTIONS_REQUEST_FAILURE';

const distributionsRequest = () => {
  return {
    type: DISTRIBUTIONS_REQUEST,
  }
};

const distributionsRequestSuccess = (data) => {
  return {
    type: DISTRIBUTIONS_REQUEST_SUCCESS,
    payload: data
  }
};

const distributionsRequestFailure = (error) => {
  return {
    type: DISTRIBUTIONS_REQUEST_FAILURE,
    payload: error
  }
};

const statisticsApi = new StatisticsApi();

export const fetchDistributionsData = (params) => {
  return (dispatch) => {
    dispatch(distributionsRequest());
    return statisticsApi.distributions(params)
      .then((data) => {
        dispatch(distributionsRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(distributionsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
