import React from "react";
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Label, ButtonToolbar } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { FaIcon } from "shared/fa-icon";
import { validate } from 'form-validations/validation-todo-form';
import TodoInputFields from './todo-input-fields'
import { EDIT_TODO_FORM_NAME } from "helpers/FormNames";
import { jobPath } from "helpers/PathHelpers";
import { passedTodoDueDate } from "helpers/DateTimeHelpers";
import { enableTodoButton } from "helpers/Permissions";

export class TodoList extends React.Component {
  constructor(props) {
    super(props);
    this.renderTodo = this.renderTodo.bind(this);
    this.renderSelector = this.renderSelector.bind(this);
    this.renderEditForm = this.renderEditForm.bind(this);
    this.editTodo = this.editTodo.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.isJobPage = this.props.isJobPage
  }

  editTodo(todo) {
    const { dispatch, editTodo } = this.props;
    dispatch(editTodo(todo))
  }

  cancelEdit() {
    const { dispatch, cancelEditTodo } = this.props;
    dispatch(cancelEditTodo())
  }

  renderEditForm() {
    const { submitting, handleSubmit, users } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off" className="note-form">
        <TodoInputFields users={users} isJobPage={this.isJobPage}>
          <ButtonToolbar className={`${this.isJobPage ? "margin-top-15" : '' }`}>
            <button className="btn btn-success" type="submit" disabled={submitting}>Update</button>
            <button className="btn btn-danger" type="button" disabled={submitting}
                    onClick={this.cancelEdit}>Cancel</button>
          </ButtonToolbar>
        </TodoInputFields>
      </form>
    )
  }

  renderTodo(todo) {
    const { completeTodo } = this.props;
    const disabled = !enableTodoButton(todo);
    const jobLink = <a className="pull-right" href={jobPath(todo.job_id)}>View Job</a>;
    const buttonGroup = (
      <ButtonGroup>
        <Button bsSize="sm" onClick={() => completeTodo(todo.id)} disabled={disabled}>
          Complete <span className="text-success"><FaIcon size="lg" icon="check" /></span>
        </Button>
        <Button bsSize="sm" onClick={() => this.editTodo(todo)} disabled={disabled}>Edit</Button>
      </ButtonGroup>
    );
    const topLine = this.isJobPage ? buttonGroup : jobLink;
    return (
      <div>
        <div>
          Responsible: <b>{todo.user && todo.user.name}</b> {' '}
          { topLine }
        </div>
        <div>
          <span>Client: {todo.client_name}</span>
          <br />
          <span>Created: {todo.created_at}</span>
          <br />
          <span>
            Due Date:
            {' '}
            { !passedTodoDueDate(todo.due_date) ? <Label bsStyle="danger">{todo.due_date}</Label> : todo.due_date}
          </span>
        </div>
        <div><span className="italic-font wrap-word">{todo.description}</span></div>
        { !this.isJobPage && buttonGroup }
      </div>
    )
  }

  renderSelector(todo) {
    const { todos } = this.props;
    const render = todo.id === todos.edit.id ? this.renderEditForm() : this.renderTodo(todo);
    return (
      <li key={todo.id}>
        {render}
      </li>
    )
  }

  render() {
    const { todos } = this.props;
    return (
      <ul className="todo-list">
        {todos.list.filter((todo) => !todo.completed).map(todo => this.renderSelector(todo))}
      </ul>
    )
  }
}

TodoList = reduxForm({
  form: EDIT_TODO_FORM_NAME,
  validate,
  enableReinitialize: true
})(TodoList);

// initialize form state
TodoList = connect(
  state => ({
    initialValues: state.todos && state.todos.edit
  }))(TodoList);

TodoList.defaultProps = {
  todos: {}
};

TodoList.propTypes = {
  todos: PropTypes.object.isRequired
};

export default TodoList
