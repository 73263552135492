import React from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, ButtonToolbar } from 'react-bootstrap'

import { FaIcon } from "shared/fa-icon";
import DocumentThumbnail from "documents/document-thumbnail"

const DocumentsList = (props) => {
    const { documents, deleteDocument } = props;
    return (
      <Row>
        {documents.map(document => {
          return (
            <Col lg={3} md={4} sm={6} key={document.id}>
              <div className="document-block">
                <div className="document-thumbnail">
                  <a href={document.attachment_url} target="_blank">
                    <DocumentThumbnail document={document} />
                  </a>
                </div>
                <p className="text-ellipsis text-center">{document.name}</p>
                <ButtonToolbar>
                  <Button href={document.attachment_url} target="_blank">
                    <span className="text-primary">
                      <FaIcon icon="download" /> ({document.attachment_size})
                    </span>
                  </Button>
                  <Button onClick={() => deleteDocument(document.id)}>
                    <span className="text-danger"><FaIcon icon="trash" /></span>
                  </Button>
                </ButtonToolbar>
              </div>
            </Col>
          )
        })}
      </Row>
    )
  };

DocumentsList.defaultProps = {
  documents: []
};

DocumentsList.propTypes = {
  documents: PropTypes.array.isRequired,
  deleteDocument: PropTypes.func.isRequired
};

export default DocumentsList

