import { connect } from 'react-redux';

import CalendarComponent from 'presentational/calendar/calendar-component';
import { fetchEstimates, updateCalendarIfEstimatesUpdated, assignEstimator, setAppointment, unassignEstimator } from 'api/estimate'
import { fetchEstimators } from "actions/user-actions";
import { selectEstimate, resetSelectedEstimate } from "actions/estimates-actions";
import {
  selectEstimator, closeEstimatesPanel, clearTimeSlotsData,
  selectTimeSlots, selectEvent, resetSelectedEvent, closeRemoveUnavailableDateModal,
  openRemoveUnavailableDateModal, removeUnavailableDate, openJobDetailsModal, closeJobDetailsModal
} from "actions/calendar-actions";
import {
  fetchUserNotAvailables, addUserNotAvailable, openUserUnavailableModal, closeUserUnavailableModal
} from "actions/user-not-available";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEstimators: (params) => {
      dispatch(fetchEstimators(params))
    },
    fetchEstimates: (params) => {
      dispatch(fetchEstimates(params))
    },
    updateCalendarIfEstimatesUpdated: (params) => {
      return dispatch(updateCalendarIfEstimatesUpdated(params))
    },
    assignEstimator: (data, callback) => {
      dispatch(assignEstimator(data, callback))
    },
    unassignEstimator: (data, callback) => {
      dispatch(unassignEstimator(data, callback))
    },
    setAppointment: (data, callback) => {
      dispatch(setAppointment(data, callback))
    },
    selectEstimator: (data) => {
      dispatch(selectEstimator(data))
    },
    closeEstimatesPanel: () => {
      dispatch(closeEstimatesPanel())
    },
    clearTimeSlotsData: () => {
      dispatch(clearTimeSlotsData())
    },
    selectTimeSlots: (data) => {
      dispatch(selectTimeSlots(data))
    },
    selectEstimate: (data) => {
      dispatch(selectEstimate(data))
    },
    selectEvent: (data) => {
      dispatch(selectEvent(data))
    },
    resetSelectedEvent: () => {
      dispatch(resetSelectedEvent())
    },
    resetSelectedEstimate: () => {
      dispatch(resetSelectedEstimate())
    },
    openUserUnavailableModal: () => {
      dispatch(openUserUnavailableModal())
    },
    closeUserUnavailableModal: () => {
      dispatch(closeUserUnavailableModal())
    },
    fetchUserNotAvailables: (date) => {
      dispatch(fetchUserNotAvailables(date))
    },
    addUserNotAvailable: (params) => {
      dispatch(addUserNotAvailable(params))
    },
    closeRemoveUnavailableDateModal: () => {
      dispatch(closeRemoveUnavailableDateModal())
    },
    openRemoveUnavailableDateModal: (event) => {
      dispatch(openRemoveUnavailableDateModal(event))
    },
    removeUnavailableDate: (event) => {
      dispatch(removeUnavailableDate(event))
    },
    openJobDetailsModal: (event) => {
      dispatch(openJobDetailsModal(event))
    },
    closeJobDetailsModal: () => {
      dispatch(closeJobDetailsModal())
    }
  }
};


const mapStateToProps = (state) => {
  return {
    calendarUpdated: state.calendar.calendarUpdated,
    event: state.calendar.event,
    showUnavailableDateModal: state.calendar.showUnavailableDateModal,
    showJobDetailsModal: state.calendar.showJobDetailsModal,
    closeJobDetailsModal: state.calendar.closeJobDetailsModal,
    estimates: state.estimates,
    estimators: state.estimators.estimators,
    selectedNavItem: state.calendar.selectedNavItem,
    selectedEvent: state.calendar.selectedEvent,
    countAllAssigned: state.estimators.all_assigned,
    countAllEstimates: state.estimators.all_estimates,
    countUnassigned: state.estimators.unassigned,
    sizes: state.calendar.sizes,
    focusUnassigned: state.calendar.focusUnassigned,
    timeSlotsData: state.calendar.timeSlotsData,
    userUnavailable: state.userUnavailable
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
