import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Badge, Well } from 'react-bootstrap';
import { BorderedWrapper } from 'shared/bordered-wrapper';
import { renderContacts } from 'shared/render-contacts';
import { selectAllContacts } from 'api/client';
import { Address } from './address';
import { selectOptionOrOther } from 'helpers/ViewHelpers';
import { clientPath } from 'helpers/PathHelpers';

class ClientContactInfo extends React.Component {
  render() {
    const space = ' ';
    const { client, className, note, showDetail, jobAddress, vertical } = this.props;
    const address = jobAddress ? jobAddress : client.address;

    return (
      <BorderedWrapper className={className}>
        <Row>
          <Col md={ vertical ? 0 : 12 }>
            <h3 className="margin-top-0 text-muted">
              <a href={ clientPath(client.id) }>
                {client.name}
              </a>
            </h3>
          </Col>
          <Col md={ vertical ? 0 : 8 }>
            <div>
              {
                showDetail && client.company_name && (
                  <p>
                    {<b>{selectOptionOrOther('client_position', client)}</b> || <i>Position</i>} at <b>{client.company_name}</b>
                  </p>
                )
              }

              { renderContacts({value: selectAllContacts(client.phones), isPhone: true }) }

              { renderContacts({value: selectAllContacts(client.emails, 'email'), isEmail: true }) }

              {
                showDetail && client.primary_contact_form &&
                <p>Primary Contact Form: {client.primary_contact_form}</p>
              }

              {
                showDetail && selectOptionOrOther('referral_resource', client, true) &&
                <p>Referral Source: <b>{selectOptionOrOther('referral_resource', client, true)}</b></p>
              }
            </div>
            {
              vertical &&
              <Address address={ address } />
            }
          </Col>
          {
            !vertical &&
            <Col md={4}>
              <Address address={ address } />
            </Col>
          }
        </Row>
        {
          showDetail && note &&
          <Row>
            <Col md={12}>
              <Well>
                <div>{note.created_at}, {space}
                  <u>{note.user && note.user.name}</u> {space}
                  <Badge>{note.note_type}</Badge>
                </div>
                <div className='wrap-word'>{note.note}</div>
              </Well>
            </Col>
          </Row>
        }
      </BorderedWrapper>
    )
  }
}

ClientContactInfo.defaultProps = {
  client: {},
  className: '',
  jobAddress: {},
  showDetail: true
};

ClientContactInfo.propTypes = {
  client: PropTypes.object.isRequired,
  className: PropTypes.string,
  jobAddress: PropTypes.object,
  showDetail: PropTypes.bool
};

export default ClientContactInfo;
