export const INITIALIZE_SEARCH_FORM = 'INITIALIZE_SEARCH_FORM';
export const SET_SORT_PARAMS = "SET_SORT_PARAMS";

export const initializeSearchForm = (data) => {
  return {
    type: INITIALIZE_SEARCH_FORM,
    payload: data
  }
};

export const setSortParams = (sortParams) => {
  return {
    type: SET_SORT_PARAMS,
    payload: sortParams
  }
};
