import React from "react";
import moment from 'moment';
import { Button, ButtonToolbar, Row, Col } from 'react-bootstrap'
import Modal from 'react-responsive-modal';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import { JOB_ESTIMATE_FORM_NAME } from "helpers/FormNames"
import { validate } from 'form-validations/validation-job-estimate-form'
import { renderDatePicker, RenderTextArea } from "shared/render-field";

class JobEstimateModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    Modal.prototype.componentWillUnmount = () => {}
  }

  render() {
    const { show, close, submitting, handleSubmit } = this.props;
    return (
      <Modal
        little
        closeOnEsc={false}
        closeOnOverlayClick={false}
        open={show} onClose={close}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <h3 className="margin-top-0">Mark job as estimated</h3>
            </Col>
          </Row>
          <Row>
            <Col md={5} sm={7}>
              <Field label="Estimate sent" name="date_estimated" component={renderDatePicker} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Field name="note" label="Estimate notes" component={RenderTextArea} />
            </Col>
          </Row>
          <ButtonToolbar className="pull-right">
            <Button onClick={close}>Close</Button>
            <button className="btn btn-primary" type="submit" disabled={submitting}>Save</button>
          </ButtonToolbar>
        </form>
      </Modal>
    )
  }
}

JobEstimateModal = reduxForm({
  form: JOB_ESTIMATE_FORM_NAME,
  validate,
  enableReinitialize : true
})(JobEstimateModal);
JobEstimateModal = connect(
  state => ({
    initialValues: state.jobEstimate.data,
  })
)(JobEstimateModal);

export default JobEstimateModal;
