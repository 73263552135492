import React from 'react';
import { Field } from 'redux-form';
import { RenderTextArea} from '../shared/render-field'
import { Row, Col } from 'react-bootstrap'

export default class ClientNote extends React.Component {
  render() {
    return (
      <div>
          <h3>Client Notes</h3>
          <Row>
              <Col sm={12}>
                  <Field name="note" rows="6" component={RenderTextArea}/>
              </Col>
          </Row>
      </div>
    )
  }
}

