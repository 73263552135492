export const SEARCH_FORM = 'search-form';
export const GENERAL_SEARCH_FORM = 'general-search-form';
export const CALL_FORM_NAME = 'call-form';
export const NOTE_FORM_NAME = 'note-form';
export const TODO_FORM_NAME = 'todo-form';
export const EDIT_TODO_FORM_NAME = 'edit-todo-form';
export const CALLBACK_FORM_NAME = 'callback-form';
export const CALLBACK_CLOSE_FORM_NAME = 'callback-close-form';
export const JOB_EDIT_FORM_NAME = 'job-edit-form';
export const JOB_SOLD_FORM_NAME = 'job-sold-form';
export const JOB_ESTIMATE_FORM_NAME = 'job-estimate-form';
export const JOB_LOST_FORM_NAME = 'job-lost-form';
export const USER_UNAVAILABLE_FORM_NAME = 'user-unavailable-form';
export const EDIT_CLIENT_FORM = 'edit-client-form';
