import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Spinner from 'shared/spinner'
import { OverflowScroll } from "shared/oferflow-scroll";
import TodoForm from "./todo-form";
import TodoList from "./todo-list";
import { editTodo, cancelEditTodo, updateTodo } from "actions/todo-actions";
import { formatDateToISO } from "helpers/DateTimeHelpers";

class TodosComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addTodo = this.addTodo.bind(this);
    this.updateTodo = this.updateTodo.bind(this);
    this.renderTodoList = this.renderTodoList.bind(this);
  }

  addTodo(todoParams) {
    const { addTodo, id } = this.props;
    const dataToSend = { ...todoParams, job_id: id, due_date: formatDateToISO(todoParams.due_date) };
    addTodo(dataToSend)
  }

  updateTodo(todo) {
    this.props.dispatch(updateTodo(todo))
  }

  renderTodoList() {
    const { users, todos, completeTodo, canAddTodo } = this.props;
    const form = (
      <div>
        <TodoForm onSubmit={this.addTodo} users={users.list} />
        <hr/>
      </div>
    );
    return (
      <div className="margin-top-10">
        { canAddTodo && form}
        <TodoList
          {...this.props}
          users={users.list}
          todos={todos}
          onSubmit={this.updateTodo}
          editTodo={editTodo}
          cancelEditTodo={cancelEditTodo}
          completeTodo={completeTodo}
        />
      </div>
    )

  }

  render() {
    const { isJobPage } = this.props;
    if (!isJobPage) {
      return (
        <OverflowScroll>
          {this.renderTodoList()}
        </OverflowScroll>
      )
    }
    return this.renderTodoList();
  }
}

TodosComponent.defaultProps = {
  addTodo: () => {},
  updateTodo: () => {},
  completeTodo: () => {},
  users: {},
  todos: {list: []},
  job: {},
  canAddTodo: false
};

TodosComponent.propTypes = {
  addTodo: PropTypes.func.isRequired,
  completeTodo: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  todos: PropTypes.object.isRequired,
  canAddTodo: PropTypes.bool
};

export default connect()(TodosComponent);
