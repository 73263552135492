import { createStore, compose } from "redux"

import JobsListReducer from "reducers/jobs-list-reducer"
import configureMiddleware from "store/configure-middleware";

const configureJobsListStore = preloadedState => {
  const store = createStore(
    JobsListReducer,
    preloadedState,
    compose(
      configureMiddleware()
    )
  );

  return store
};

export default configureJobsListStore
