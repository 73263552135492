import { createStore, compose } from "redux"

import EstimatesReducer from "reducers/estimates-reducers"
import configureMiddleware from "store/configure-middleware";

const configureEstimatesStore = preloadedState => {
  return createStore(
    EstimatesReducer,
    preloadedState,
    compose(
      configureMiddleware()
    )
  )
};

export default configureEstimatesStore
