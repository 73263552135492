import {reset} from 'redux-form';

import { TODO_FORM_NAME } from "helpers/FormNames";
import TodoApi from 'api/TodoApi';

export const TODOS_REQUEST = 'TODOS_REQUEST';
export const TODOS_REQUEST_SUCCESS = 'TODOS_REQUEST_SUCCESS';
export const TODOS_REQUEST_FAILURE = 'TODOS_REQUEST_FAILURE';

export const CREATE_TODO_REQUEST = 'CREATE_TODO_REQUEST';
export const CREATE_TODO_SUCCESS = 'CREATE_TODO_SUCCESS';
export const CREATE_TODO_FAILURE = 'CREATE_TODO_FAILURE';

export const EDIT_TODO = 'EDIT_TODO';
export const CANCEL_EDIT_TODO = 'CANCEL_EDIT_TODO';

export const UPDATE_TODO_REQUEST = 'UPDATE_TODO_REQUEST';
export const UPDATE_TODO_SUCCESS = 'UPDATE_TODO_SUCCESS';
export const UPDATE_TODO_FAILURE = 'UPDATE_TODO_FAILURE';

export const COMPLETE_TODO_SUCCESS = 'COMPLETE_TODO_SUCCESS';

export const todosRequest = () => {
  return {
    type: TODOS_REQUEST
  }
};

export const todosRequestSuccess = (todos) => {
  return {
    type: TODOS_REQUEST_SUCCESS,
    payload: todos
  }
};

export const todosRequestFailure = (error) => {
  return {
    type: TODOS_REQUEST_FAILURE,
    payload: error
  }
};

const createTodoRequest = () => {
  return {
    type: CREATE_TODO_REQUEST
  }
};

const createTodoSuccess = (todo) => {
  return {
    type: CREATE_TODO_SUCCESS,
    payload: todo
  };
};

const completeTodoSuccess = (todo) => {
  return {
    type: COMPLETE_TODO_SUCCESS,
    payload: todo
  };
};

const createTodoFailure = (error) => {
  return {
    type: CREATE_TODO_FAILURE,
    payload: error
  };
};

export const editTodo = (todo) => {
  return {
    type: EDIT_TODO,
    payload: addUserIdField(todo)
  }
};

export const cancelEditTodo = () => {
  return {
    type: CANCEL_EDIT_TODO
  }
};

export const updateTodoRequest = () => {
  return {
    type: UPDATE_TODO_REQUEST,
  }
};

export const updateTodoSuccess = (todo) => {
  return {
    type: UPDATE_TODO_SUCCESS,
    payload: todo
  };
};

export const updateTodoFailure = (error) => {
  return {
    type: UPDATE_TODO_FAILURE,
    payload: error
  };
};

const todoApi = new TodoApi();

export const fetchJobTodos = (jobId) => {
  return todosRequestHandler(todoApi.index(jobId))
};

export const fetchClientTodos = (clientId) => {
  return todosRequestHandler(todoApi.clientTodos(clientId))
};

export const fetchDashboardTodos = () => {
  return todosRequestHandler(todoApi.dashboardTodos())
};

const todosRequestHandler = (request) => {
  return (dispatch) => {
    dispatch(todosRequest());
    return request
      .then((data) => {
        dispatch(todosRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(todosRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const addTodo = (todoParams, callback) => {
  return (dispatch) => {
    dispatch(createTodoRequest());
    return todoApi.create(todoParams)
      .then((data) => {
        dispatch(createTodoSuccess(data));
        dispatch(reset(TODO_FORM_NAME));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(createTodoFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const completeTodo = (todo_id, callback) => {
  return (dispatch) => {
    dispatch(updateTodoRequest());
    return todoApi.complete(todo_id)
      .then((data) => {
        dispatch(completeTodoSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(updateTodoFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const updateTodo = (todo, callback) => {
  return (dispatch) => {
    dispatch(updateTodoRequest());
    return todoApi.update(todo)
      .then((data) => {
        dispatch(updateTodoSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(updateTodoFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

const addUserIdField = (todo) => {
  return {...todo, user_id: todo.user.id}
};
