import { parseDataToForm } from '../api/client';
import { loadAddress } from './addresses';

export const CLIENTS_REQUEST = 'CLIENTS_REQUEST';
export const CLIENTS_REQUEST_SUCCESS = 'CLIENTS_REQUEST_SUCCESS';
export const CLIENTS_REQUEST_FAILURE = 'SEARCH_REQUEST_FAILURE';

export const LOAD_CLIENT = 'LOAD_CLIENT';
export const LOAD_CLIENT_TO_FORM = 'LOAD_CLIENT_TO_FORM';
export const LOAD_CLIENT_TO_JOB_FORM = 'LOAD_CLIENT_TO_JOB_FORM';
export const RESET_CLIENT_EDIT_FORM = 'RESET_CLIENT_EDIT_FORM';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_REQUEST_SUCCESS = 'UPDATE_CLIENT_REQUEST_SUCCESS';
export const UPDATE_CLIENT_REQUEST_ERROR = 'UPDATE_CLIENT_REQUEST_ERROR';

export const clientsRequest = (searchParams) => {
  return {
    type: CLIENTS_REQUEST,
    payload: searchParams
  }
};

export const clientsRequestSuccess = (newJob) => {
  return {
    type: CLIENTS_REQUEST_SUCCESS,
    payload: newJob
  };
};

export const clientsRequestFailure = (error) => {
  return {
    type: CLIENTS_REQUEST_FAILURE,
    payload: error
  };
};


export const loadClient = (client) => {
  return {
    type: LOAD_CLIENT,
    payload: client
  }
};

export const loadClientToForm = (data) => {
  return {
    type: LOAD_CLIENT_TO_FORM,
    payload: parseDataToForm(data)
  };
};

export const loadClientToJobForm = (data) => {
  return {
    type: LOAD_CLIENT_TO_JOB_FORM,
    payload: parseDataToForm(data)
  };
};

export const resetClientEditForm = () => {
  return {
    type: RESET_CLIENT_EDIT_FORM
  };
};


export const updateClientRequest = () => {
  return {
    type: UPDATE_CLIENT_REQUEST
  }
};

export const updateClientRequestSuccess = (data) => {
  return {
    type: UPDATE_CLIENT_REQUEST_SUCCESS,
    payload: data
  }
};

export const updateClientRequestError = (error) => {
  return {
    type: UPDATE_CLIENT_REQUEST_ERROR,
    payload: error
  }
};
