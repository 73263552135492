import React from 'react';
import { FormGroup, ControlLabel, FormControl, InputGroup, Button, Glyphicon,  Checkbox, MenuItem } from 'react-bootstrap'
import { Field } from 'redux-form';
import { isUndefined } from 'lodash'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TimePicker from 'react-bootstrap-time-picker';

import {
  DAY_START,
  DAY_END,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  DEFAULT_TIME_INTERVAL,
  DATE_FORMAT_II,
  ISO_DATE_FORMAT_II,
  DATE_TIME_FORMAT_II,
  setDatePickerDate
} from "helpers/DateTimeHelpers";
import { OTHER_SELECT_OPTION } from "helpers/AppConstants";

const bootstrapFormValidation = ({touched, error, warning}) => {
  return touched && ((error && 'error') || (warning && 'warning')) || null
};
const bootstrapFormValidationMessage = ({touched, error, warning}) => {
  return (
    <span className="input-error-message">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </span>
  )
};

export const RenderField = ({input, label, placeholder, disabled, type, meta: { touched, error, warning } }) => {
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...input} type={type} placeholder={placeholder} disabled={disabled} />
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const RenderSearchField = ({input, label, placeholder, meta: { touched, error, warning } }) => {
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup>
        <InputGroup.Addon><Glyphicon glyph="search" /></InputGroup.Addon>
        <FormControl {...input} type="text" placeholder={placeholder} />
      </InputGroup>
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const RenderSelect = ({input, label, disabled, option, options, hideEmptyOption, showOtherOption, meta: { touched, error, warning } }) => {
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        componentClass="select"
        value={input.value}
        disabled={disabled}
        onChange={(value) => input.onChange(value)}
        onBlur={(value) => input.onBlur(value)}
      >
        {!hideEmptyOption && <option value="">{option ? option : `Select ${label || ''}`}</option>}
        {options.map((option, i) => (
          <option value={option.id || option} key={i} disabled={option.disabled}>
            {option.name || option}
          </option>
        ))}
        { showOtherOption && <option value={OTHER_SELECT_OPTION}>Other</option>}
      </FormControl>
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const RenderTextArea = ({input, label, autoFocus, disabled, rows, meta: { touched, error, warning } }) => {
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl {...input} disabled={disabled} autoFocus={autoFocus} componentClass="textarea" rows={rows} />
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const RenderInputGroup = ({input, label, placeholder, type, action, disabled, meta: { touched, error, warning } }) => {
  let formControll = <FormControl {...input} type={type} placeholder={placeholder} disabled={disabled} />;
  let group = null;
  if(action) {
    group = (
      <InputGroup>
        {formControll}
        <InputGroup.Button>
          <Button onClick={action}><Glyphicon glyph="remove" /></Button>
        </InputGroup.Button>
      </InputGroup>
    )
  } else {
    group = formControll
  }
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      <ControlLabel>{label}</ControlLabel>
      {group}
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const RenderRadio = (props) => {
  const { label, name, disabled, value, hideBlankLine, className } = props;
  return (
    <div className={`radio ${className || ''}`}>
      {!hideBlankLine && <br />}
      <label>
        <Field name={name} component="input" type="radio" disabled={disabled} value={value} />
        {' ' + label}
      </label>
    </div>
  )};

export const RenderCheckbox = ({input, label, disabled, hideBlankLine, meta: { touched, error, warning } }) => {
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      {!hideBlankLine && <br />}
      <Checkbox {...input} checked={input.value} disabled={disabled}>
        {label}
      </Checkbox>
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const RenderControlledCheckbox = ({title, input, label, disabled, hideBlankLine, checked, meta: { touched, error, warning } }) => {
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      {!hideBlankLine && <br />}
      {title}
      <Checkbox {...input} checked={checked} disabled={disabled}>
        {label}
      </Checkbox>
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const RenderAutocompleteInput = ({input, label, disabled, showSearchIcon, options=[], onClick, onKeyDown, meta: { touched, error, warning } }) => {
  const formInput =  <FormControl {...input} disabled={disabled} autoComplete="off" onKeyDown={(e) =>onKeyDown(e)} type='text' />;
  const withIcon = (
    <InputGroup>
      <InputGroup.Addon><Glyphicon glyph="search" /></InputGroup.Addon>
      {formInput}
    </InputGroup>
  );
  return (
    <FormGroup className="relative-element" controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      {label && <ControlLabel>{label}</ControlLabel>}
      {showSearchIcon ? withIcon : formInput}
      {options.length > 0 &&
      <ul className="dropdown-menu show autocomplete-list">
        { options.map((option, i) => {
          return <MenuItem onClick={() => onClick(option) } active={option.active} key={i}>{ option.name }</MenuItem>
        })}
      </ul>
      }
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const renderDatePicker = ({input, label, disabled, placeholder, disablePrevious,
                                   showTimeSelect, showYearDropdown, showMonthDropdown, shouldCloseOnSelect, popperProps = {},
                                   meta: { touched, error, warning }}) => {

  const dateFormat = showTimeSelect ? DATE_TIME_FORMAT_II : DATE_FORMAT_II;
  let minDate = disablePrevious && setDatePickerDate(disablePrevious)

  let selectedValue = null
  
  if(input.value != '' && (typeof input.value.isValid === "function") && input.value.isValid()) {
    selectedValue = input.value.toDate()
  }
  else if(Object.prototype.toString.call(input.value) === '[object Date]') {
    selectedValue = input.value
  }

  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      <ControlLabel>{label}</ControlLabel>
      <DatePicker
        {...input}
        {...popperProps}
        disabled={disabled}
        dateFormat={dateFormat} isClearable
        selected={selectedValue}
        className="form-control"
        placeholderText={ placeholder ? placeholder : " /  /" }
        minDate={minDate}
        showTimeSelect={showTimeSelect}
        timeFormat={TIME_FORMAT}
        timeIntervals={DEFAULT_TIME_INTERVAL}
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        shouldCloseOnSelect={shouldCloseOnSelect}
      />
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};

export const renderTimePicker = ({input, label, disabled, step=DEFAULT_TIME_INTERVAL, meta: { touched, error, warning } }) => {
  return (
    <FormGroup controlId={input.name} validationState={bootstrapFormValidation({ touched, error, warning })}>
      <ControlLabel>{label}</ControlLabel>
      <TimePicker {...input} disabled={disabled} start={DAY_START} end={DAY_END} step={step}/>
      { bootstrapFormValidationMessage({ touched, error, warning }) }
    </FormGroup>
  )};
