import {reset} from 'redux-form';

import { CALLBACK_FORM_NAME } from "helpers/FormNames";
import { formatDateToISO } from "helpers/DateTimeHelpers";
import { clientData } from './client'
import { parseDataToForm as parseClientDataToForm } from "./client";
import { formatHours, formatTimeToTimePicker } from "helpers/DateTimeHelpers";
import { callbackParams } from "helpers/Callback";
import { jobsRequest, jobsRequestSuccess, jobsRequestFailure,
  jobRequest, jobRequestSuccess, jobRequestFailure,
  createJob, createJobSuccess, createJobFailure,
  updateJobRequest, updateJobRequestSuccess, updateJobRequestFailure
} from 'actions/jobs-actions';
import { onOtherOptionSelect, otherOptionValueIfNeed, setOtherField } from "helpers/FormHelpers";

import JobApi from 'api/JobApi'

const jobApi = new JobApi();

export const fetchJobs = (searchParams={}) => {
  return function (dispatch) {
    dispatch(jobsRequest(searchParams));
    return jobApi.index(searchParams)
      .then(function(data) {
        dispatch(jobsRequestSuccess(data));
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(jobsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const fetchJob = (id, callback) => {
  return (dispatch) => {
    dispatch(jobRequest(id));
    return jobApi.show(id)
      .then((data) => {
        dispatch(jobRequestSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(jobRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const fetchClientJobs = (clientId) => {
  if (!clientId) return;
  return (dispatch) => {
    dispatch(jobsRequest(clientId));
    return jobApi.clientJobs(clientId)
      .then(function(data) {
        dispatch(jobsRequestSuccess(data));
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(jobsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const addJob = (job, callback) => {
  let dataToSend = jobData(job);
  return (dispatch) => {
    dispatch(createJob());
    return jobApi.create(dataToSend)
      .then(function(data) {
        dispatch(createJobSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(createJobFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const addCallback = (job, callback) => {
  let dataToSend = callbackParams(job);
  return (dispatch) => {
    dispatch(createJob());
    return jobApi.addCallback(dataToSend)
      .then(function(data) {
        dispatch(createJobSuccess(data));
        dispatch(reset(CALLBACK_FORM_NAME));
        if (typeof callback === 'function') callback(data);
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(createJobFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const updateJob = (jobParams, callback) => {
  const dataToSend = updateJobData(jobParams);
  return jobUpdateRequest(jobApi.update(dataToSend), callback)
};

export const jobUpdateRequest = (action, callback) => {
  return function (dispatch) {
    dispatch(updateJobRequest());
    return action
      .then(function(data) {
        dispatch(updateJobRequestSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(updateJobRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

const jobToSend = (job) => {
  return {
    building_type_id: onOtherOptionSelect(job.buildingType),
    building_type_other: setOtherField(job.buildingType, job.building_type_other),
    roof_type_id: onOtherOptionSelect(job.roofType),
    roof_type_other: setOtherField(job.roofType, job.roof_type_other),
    job_type_id: onOtherOptionSelect(job.jobType),
    job_type_other: setOtherField(job.jobType, job.job_type_other),
    stories_count_id: job.storiesCount,
    roof_access_id: onOtherOptionSelect(job.roofAccess),
    roof_access_other: setOtherField(job.roofAccess, job.roof_access_other),
    user_id: job.estimator,
    requested_estimator_id: job.requestedEstimator,
    appointment_date: formatDateToISO(job.appointmentDate),
    requested_appointment_date: formatDateToISO(job.requestedAppointmentDate),
    time_from: formatHours(job.timeFrom),
    time_to: formatHours(job.timeTo),
    urgent: job.urgent,
    address_same_as_contact_location: job.job_address.contact_location
  }
};

const updateJobData = (job) => {
  return {
    ...job.job_address,
    ...jobToSend(job),
    id: job.id
  };
};

const jobData = (data) => {
  let result = {
    ...jobToSend(data),
    client_id: data.client_id,
    client_attributes: clientData(data),
    note: data.note
  };
  result = {...result, ...jobAddress(data)};
  return result;
};

const jobAddress = (obj) => {
  let address = {};
  if (obj.job_address && obj.job_address.contact_location) {
    address = {...address, ...obj.contact_address}
  } else {
    address = {...address, ...obj.job_address}
  }
  return address;
};

export const parseJobToForm = (job) => {
  if (!job) return {};
  return {
    id: job.id,
    job_address: job.address_same_as_contact_location ? job.client.address : job.address,
    buildingType: otherOptionValueIfNeed(job, "building_type"),
    building_type_other: job.building_type_other,
    roofType: otherOptionValueIfNeed(job, "roof_type"),
    roof_type_other: job.roof_type_other,
    jobType: otherOptionValueIfNeed(job, "job_type"),
    job_type_other: job.job_type_other,
    storiesCount: job.stories_count && job.stories_count.id,
    roofAccess: otherOptionValueIfNeed(job, "roof_access"),
    roof_access_other: job.roof_access_other,
    urgent: job.urgent,
    requestedEstimator: job.requested_estimator && job.requested_estimator.id,
    requestedAppointmentDate: job.requested_appointment_date,
    estimator: job.user && job.user.id,
    appointmentDate: job.appointment_date,
    timeFrom: formatTimeToTimePicker(job.time_from),
    timeTo: formatTimeToTimePicker(job.time_to),
    contact_address: job.client ? job.client.address : job.address,
    address_same_as_contact_location: job.address_same_as_contact_location,
    disableJobAddressForm: job.address_same_as_contact_location
  }
};

export const parseResponseJobToForm = (response) => {

  const client = parseClientDataToForm(response.client);
  const job = parseJobToForm(response);
  return {...client, ...job}
};
