import {
  OPEN_JOB_SOLD_MODAL,
  CLOSE_JOB_SOLD_MODAL,
  JOB_SOLD_REQUEST,
  JOB_SOLD_REQUEST_SUCCESS,
  JOB_SOLD_REQUEST_FAILURE,
  SOLD_DETAILS_REQUEST,
  SOLD_DETAILS_REQUEST_SUCCESS,
  SOLD_DETAILS_REQUEST_FAILURE,
} from "actions/job-status-actions";
import { jobToSoldForm } from "helpers/Job";

const initMaterials = { materials: [{}]};
const INITIAL_STATE = { data: initMaterials, soldDetails: null, showModal: false, loading: false, error: null };

const JobSoldReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case OPEN_JOB_SOLD_MODAL:
      return {...state, data: {...initMaterials, ...jobToSoldForm(action.payload)},  showModal: true, loading: false };
    case CLOSE_JOB_SOLD_MODAL:
      return {...state, data: initMaterials, showModal: false, loading: false };

    case JOB_SOLD_REQUEST:
      return {...state, loading: true };
    case JOB_SOLD_REQUEST_SUCCESS:
      return {...state, data: initMaterials, soldDetails: action.payload, showModal: false, loading: false };
    case JOB_SOLD_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, showModal: true, loading: false };

    case SOLD_DETAILS_REQUEST:
      return {...state, loading: true, error: null };
    case SOLD_DETAILS_REQUEST_SUCCESS:
      return {...state, soldDetails: action.payload, loading: false, };
    case SOLD_DETAILS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false, };

    default:
      return state;
  }
};
export default JobSoldReducer
