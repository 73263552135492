import { ADD_MATERIAL, REMOVE_MATERIAL, CHANGE_MATERIAL_BRAND } from "actions/brand-series-actions";
import { arrayPush, removeArrayItem } from "helpers/ListHelpers";

const INITIAL_STATE = { series: [[]] };

const BrandSeriesReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case ADD_MATERIAL:
      return {...state, series: arrayPush(state.series, []) };
    case REMOVE_MATERIAL:
      return {...state, series: removeArrayItem(state.series, action.payload) };
    case CHANGE_MATERIAL_BRAND:
      return {...state, series: action.payload };

    default:
      return state;
  }
};
export default BrandSeriesReducer
