import React from "react";
import { Row, Col, Nav, NavItem, Tab } from 'react-bootstrap';
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

import Spinner from 'shared/spinner';
import EditClientModal from "shared/edit-client-modal";
import ClientsTable from './clients-table';
import SearchClientsForm from './search-client-form';
import ClientApi from 'api/ClientApi'
import { SEARCH_FORM } from "helpers/FormNames";
import { ACTIVE_CLIENTS, ARCHIVED_CLIENTS, ORDER_ASC, CLIENT_NAME, PAGINATION_START_PAGE } from "helpers/AppConstants";
import { perfomAsync } from "helpers/Async";

class ClientsList extends React.Component {
  constructor(props) {
    super(props);
    this.clientApi = new ClientApi();
    this.onSelectTab = this.onSelectTab.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.submitSearchForm = this.submitSearchForm.bind(this);
    this.onTableSort = this.onTableSort.bind(this);
  }

  componentWillMount() {
    const { setSortParams } = this.props;
    setSortParams(this.initialSortParams())
  }

  componentDidMount() {
    perfomAsync(() => {
      const { fetchClients, searchParams, search } = this.props;
      fetchClients(this.params(searchParams, search.sort))
    })
  }

  initialSortParams() {
    return { sortBy: CLIENT_NAME, orderBy: ORDER_ASC }
  }

  typeFilter(currentTab) {
    const archived = currentTab === ARCHIVED_CLIENTS ? '1' : '0';
    return { archived }
  }

  params(searchParams, sortParams, tab, page = PAGINATION_START_PAGE) {
    const archived = this.typeFilter(tab);
    return {...searchParams, ...sortParams, ...archived, page}
  }

  submitSearchForm(params) {
    const { fetchClients, search, tabs } = this.props;
    fetchClients(this.params(params, search.sort, tabs.selected))
  }

  onSelectTab(tab) {
    const { selectTab, setSortParams } = this.props;
    selectTab(tab);
    setSortParams(this.initialSortParams());
    perfomAsync(() => {
      const { fetchClients, searchParams, resetTabsData, search } = this.props;
      resetTabsData();
      fetchClients(this.params(searchParams, search.sort, tab, PAGINATION_START_PAGE ))
    })
  }

  onPageChange(page) {
    const { searchParams, fetchClients, tabs, search } = this.props;
    fetchClients(this.params(searchParams, search.sort, tabs.selected, page))
  }

  onTableSort(sortParams) {
    const { setSortParams, searchParams, tabs, clients, fetchClients } = this.props;
    setSortParams(sortParams);
    const page = clients.meta.current_page;
    fetchClients(this.params(searchParams, sortParams, tabs.selected, page))
  }

  render() {
    const {
      clients, loadAddress, loadToEditForm, editClient, searchParams, resetEditForm,
      updateClient, toggleArchive, clientOptions, filters, tabs, search
    } = this.props;
    const showEditModal = editClient.showModal;
    const showEditLoader = editClient.loading;
    const clientsTable = (
      <ClientsTable
        clients={clients}
        clientOptions={clientOptions}
        loadToEditForm={loadToEditForm}
        toggleArchive={toggleArchive}
        type={tabs.selected}
        onPageChange={this.onPageChange}
        onTableSort={this.onTableSort}
        search={search}
      />
    );

    return (
      <div>
        <Spinner active={showEditLoader} ><span>Loading...</span></Spinner>
        <Row>
          <Col md={12}>
            <h2 className="col-md-6 page-title">Clients</h2>
            <SearchClientsForm filters={filters} onSubmit={this.submitSearchForm} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3>
              <small className="pull-right">Download CSV:{' '}
                <a href={this.clientApi.csvUrl(searchParams)} download>Emails | Contact info</a>
              </small>
            </h3>
          </Col>
        </Row>
        <Row>
          <Tab.Container id="jobs-tabs" onSelect={this.onSelectTab} defaultActiveKey={tabs.selected || ACTIVE_CLIENTS}>
            <Row className="clearfix padding-top-10">
              <Col md={12}>
                <Nav bsStyle="pills" navbar>
                  <NavItem eventKey={ACTIVE_CLIENTS}>Active clients</NavItem>
                  <NavItem eventKey={ARCHIVED_CLIENTS}>Archived clients</NavItem>
                </Nav>
              </Col>
              <Col md={12} className="margin-top-20">
                <Tab.Content animation mountOnEnter unmountOnExit>
                  <Tab.Pane eventKey={ACTIVE_CLIENTS}>
                    {clientsTable}
                  </Tab.Pane>
                  <Tab.Pane eventKey={ARCHIVED_CLIENTS}>
                    {clientsTable}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <EditClientModal
            show={showEditModal}
            close={resetEditForm}
            loadAddress={loadAddress}
            onSubmit={updateClient}
            clientOptions={clientOptions}
          />
        </Row>
      </div>
    )
  }
}

const selector = formValueSelector(SEARCH_FORM);
ClientsList = connect(state => {
  const { query, search_by } = selector(state, 'query', 'search_by');
  return {
    searchParams: {
      query,
      search_by
    }
  };
})(ClientsList);

export default ClientsList;
