import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import { JobReducer } from './job-reducers'
import NotesReducer from "./notes-reducer";
import UsersReducer from "./users-reducer";

const CallbackCloseReducers = combineReducers({
  job: JobReducer,
  notes: NotesReducer,
  users: UsersReducer,
  form: reduxFormReducer
});

export default CallbackCloseReducers
