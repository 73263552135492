import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import { CLIENTS_REQUEST, CLIENTS_REQUEST_SUCCESS, CLIENTS_REQUEST_FAILURE,
         UPDATE_CLIENT_REQUEST_SUCCESS, UPDATE_CLIENT_REQUEST_ERROR
       } from 'actions/clients-actions';
import { updateList } from "helpers/ListHelpers";
import EditClientReducer from './edit-client-reducer'
import { SearchReducer } from "reducers/search-reducer";
import TabsReducer from "reducers/tabs-reducer";

const INITIAL_STATE = { list: [], meta: {}, error: null, loading: false };

export const ClientsReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case CLIENTS_REQUEST:
      return {...state, loading: true };
    case CLIENTS_REQUEST_SUCCESS:
      return {...state, list: action.payload.clients, meta: action.payload.meta, error:null, loading: false };
    case CLIENTS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message}; //2nd one is network or server down errors
      return {...state, error: error, loading: false };

    case UPDATE_CLIENT_REQUEST_SUCCESS:
      return {...state, list: updateList(state.list, action.payload) };
    case UPDATE_CLIENT_REQUEST_ERROR:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error};

    default:
      return state;
  }
};

const ClientsListReducer = combineReducers({
  clients: ClientsReducer,
  editClient: EditClientReducer,
  tabs: TabsReducer,
  search: SearchReducer,
  form: reduxFormReducer, // mounted under "form"
});

export default ClientsListReducer


