import { applyMiddleware } from "redux"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"

const configureMiddleware = () => {
  if (process.env.NODE_ENV === `development` || process.env.NODE_ENV === `test`) {
    return applyMiddleware(thunk, createLogger())
  }
  return applyMiddleware(thunk)
};

export default configureMiddleware;
