import React from "react";
import { Row, Col, Tab } from 'react-bootstrap'

import Sidebar from './sidebar'
import TabContent from './tab-content'

class EstimatesList extends React.Component {
  constructor (props) {
    super(props);
    this.onTabSelect = this.onTabSelect.bind(this);
    this.assignEstimator = this.assignEstimator.bind(this);
    this.unassignEstimator = this.unassignEstimator.bind(this);
    this.tab = { type: 'unassigned' }
  }

  componentWillMount() {
    this.props.fetchEstimatesSidebar(this.tab);
    this.props.fetchEstimates(this.tab)
  }

  onTabSelect(tab) {
    this.tab.type = tab;
    this.props.fetchEstimates(this.tab);
  }

  assignEstimator(data) {
    this.props.assignEstimator(data, this.props.fetchEstimatesSidebar)
  }

  unassignEstimator(data) {
    this.props.unassignEstimator(data, this.props.fetchEstimatesSidebar)
  }

  render() {
    const { sidebarData, estimates, setAppointment } = this.props;
    return (
      <div>
        <Row>
          <Col md={12}>
            <h2 className="col-md-6 page-title">Estimates</h2>
          </Col>
        </Row>
        <Tab.Container id="estimates" onSelect={this.onTabSelect} defaultActiveKey={this.tab.type}>
          <Row className="clearfix">
            <Sidebar sidebarData={sidebarData} />
            <TabContent {...this.props}
                        estimators={sidebarData.estimators}
                        estimates={estimates}
                        selectedEstimate={estimates.selected}
                        assignEstimator={this.assignEstimator}
                        unassignEstimator={this.unassignEstimator}
                        setAppointment={setAppointment}
            />
          </Row>
        </Tab.Container>
      </div>
    )
  }
}
export default EstimatesList;

