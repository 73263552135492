import React from "react";
import { Button, ButtonToolbar } from 'react-bootstrap'
import Modal from 'react-responsive-modal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import ClientInfo from "./client-info";
import ContactInfo from "./contact-info";

import { validate } from 'form-validations/validation-client-edit-form';


class EditClientModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    Modal.prototype.componentWillUnmount = () => {}
  }

  render() {
    const { show, close, submitting, handleSubmit, loadAddress, clientOptions } = this.props;
    return (
      <Modal closeOnEsc={false} closeOnOverlayClick={false} open={show} onClose={close}>
        <form onSubmit={handleSubmit} className="validation-inputs">
          <ClientInfo clientOptions={clientOptions} />
          <ContactInfo
            loadAddress={loadAddress}
          />
          <ButtonToolbar className="pull-right">
            <Button onClick={close}>Close</Button>
            <button className="btn btn-primary" type="submit" disabled={submitting}>Save</button>
          </ButtonToolbar>
        </form>
      </Modal>

    )
  }
}

EditClientModal = reduxForm({
  form: 'edit-client-form', // a unique identifier for this form
  validate,                // <--- validation function given to redux-form
  enableReinitialize : true
})(EditClientModal);
// initialize form state
EditClientModal = connect(
  state => ({
    initialValues: state.editClient.data,
  })
)(EditClientModal);

export default EditClientModal;

