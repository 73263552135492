import React from "react";
import { Row, Col, Breadcrumb } from 'react-bootstrap'

import Spinner from '../shared/spinner'
import CustomerInfo from './customer-info'
import ClientJobs from './client-jobs'
import { ActivityTabs } from "activity-tabs/activity-tabs";
import { TYPE_CLIENT } from "helpers/AppConstants";

class ClientInfoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addNote = this.addNote.bind(this);
    this.completeTodo = this.completeTodo.bind(this);
    this.fetchActivityLog = this.fetchActivityLog.bind(this);
  }

  componentWillMount() {
    const {
      loadClient, clientFromProps, fetchClientJobs,
      fetchCallbacks, fetchTodos, fetchUsers
    } = this.props;
    if (clientFromProps) {
      loadClient(clientFromProps);
      fetchClientJobs(clientFromProps.id);
      fetchCallbacks(clientFromProps.id);
      fetchTodos(clientFromProps.id);
    }
    this.fetchActivityLog();
    fetchUsers();
  }

  addNote(data) {
    const { addNote } = this.props;
    addNote(data, this.fetchActivityLog)
  }

  completeTodo(data) {
    const { completeTodo } = this.props;
    completeTodo(data, this.fetchActivityLog)
  }

  fetchActivityLog() {
    const { clientFromProps, fetchActivityLog } = this.props;
    if (clientFromProps) fetchActivityLog(clientFromProps.id);
  }

  jobAddresses(jobs = []) {
    return jobs.map((job) => job.address)
  }

  render() {
    const { client, editClient, loadAddress, jobs } = this.props;
    const showEditModal = editClient.showModal;
    const showEditLoader = editClient.loading;
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="/clients">
            Clients
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            { client.data.name }
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col md={4} className="right-divider">
            <CustomerInfo
              {...this.props}
              client={client.data}
              loadAddress={loadAddress}
              addresses={[]}
              showEditModal={showEditModal}
              showEditLoader={showEditLoader}
            />
          </Col>
          <Col md={4} className="right-divider">
            <ClientJobs
              jobs={jobs.list}
              client={client.data}
            />
          </Col>
          <Col md={4} className="right-divider">
            <ActivityTabs
              {...this.props}
              id={this.props.clientFromProps.id}
              type={TYPE_CLIENT}
              addNote={this.addNote}
              completeTodo={this.completeTodo}
            />
          </Col>
        </Row>
        <Spinner active={showEditLoader} ><span>Loading...</span></Spinner>
      </div>
    )
  }
}
export default ClientInfoComponent;

