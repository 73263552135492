import React from "react";
import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap'
import { Field } from 'redux-form';

import { RenderSelect, RenderField, renderDatePicker } from 'shared/render-field';
import SearchForm from 'shared/search-form';
import {
  CALLBACK, JOB, CALLED_IN, LAST_ACTIVITY, JOB_STATUS_ESTIMATED, JOB_STATUS_ASSIGNED,
  CALLBACK_STATUS_CLOSED, CALLBACK_STATUS_OPEN, JOB_STATUS_UNASSIGNED,
  JOB_STATUS_SCHEDULED, JOB_STATUS_SOLD, JOB_STATUS_LOST
} from "helpers/AppConstants";
import { stringToSelectObj } from "helpers/FormHelpers";

class SearchJobForm extends React.Component {
  constructor(props) {
    super(props);
  }

  typeFilters() {
    return [
      {name: JOB, id: '0'},
      {name: CALLBACK, id: '1'}
      ]
  }

  dateRangeFilters() {
    return [JOB_STATUS_ASSIGNED, CALLED_IN, JOB_STATUS_ESTIMATED, LAST_ACTIVITY].map(item => stringToSelectObj(item))
  }

  statusFilters() {
    const statuses = [
      JOB_STATUS_UNASSIGNED,
      JOB_STATUS_ASSIGNED,
      JOB_STATUS_SCHEDULED,
      JOB_STATUS_ESTIMATED,
      JOB_STATUS_SOLD,
      JOB_STATUS_LOST,
      CALLBACK_STATUS_OPEN,
      CALLBACK_STATUS_CLOSED
    ];
    return statuses.map(status => stringToSelectObj(status))
  }

  render() {
    const {
      onSubmit,
      filters,
      initialParams ,
      materialBrands,
      brandSeries
    } = this.props;

    return (
      <SearchForm onSubmit={onSubmit} filters={filters} initialParams={initialParams}>
        <div>
          <Row className="clearfix" >
            <Col xs={12}>
              <ul>
                <li><Field name="callback" component={RenderSelect} option="Type" options={this.typeFilters()} type="text" /></li>
                <li>
                  <Field name="roof_type_id" component={RenderSelect}
                         option="Roof type" options={filters.roof_types} />
                </li>
                <li>
                  {/*<Field name="building_type_id" component={RenderSelect}*/}
                         {/*option="Type of building" options={filters.building_types} type="text" />*/}
                  <Field name="status" component={RenderSelect}
                         option="Status" options={this.statusFilters()} />
                </li>
                <li>
                  <Field name="user_id" component={RenderSelect}
                         option="Estimator" options={filters.estimators}/>
                </li>
                <li>
                  <Field name="date_filter" component={RenderSelect}
                        hideEmptyOption options={this.dateRangeFilters()} />
                </li>
                <li>
                  <Field
                    name="date_from"
                    component={renderDatePicker}
                    normalize={ (value) =>  moment(value) }
                    type="text" placeholder="From"
                    showYearDropdown
                    showMonthDropdown
                  />
                </li>
                <li>
                  <Field
                    name="date_to"
                    component={renderDatePicker}
                    normalize={ (value) =>  moment(value) }
                    type="text" placeholder="To"
                    showYearDropdown
                    showMonthDropdown
                  />
                </li>
              </ul>
            </Col>
            </Row>
            <Row>
              <Col xs={6}>
              <ul>
                <li>
                  <Field
                    name="material_brand_id"
                    option="Select Brand"
                    placeholder="Select Brand"
                    component={RenderSelect}
                    options={materialBrands}
                  />
                </li>
                <li>
                  <Field
                    name="brand_item_id"
                    option="Select Series"
                    placeholder="Select Series"
                    component={RenderSelect}
                    options={brandSeries}
                  />
                </li>
                <li>
                  <Field
                    name="color"
                    component={RenderField}
                  />
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </SearchForm>
    )
  }
}

SearchJobForm.defaultProps = {
  initialParams: {}
};

SearchJobForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  initialParams: PropTypes.object
};

export default SearchJobForm;
