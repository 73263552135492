import { OTHER_SELECT_OPTION } from "helpers/AppConstants";

export const validate = values => {
  const required = 'Required';
  const errors = {};
  if (!values.job_sold_date) {
    errors.job_sold_date = required
  }
  if (!values.estimator) {
    errors.estimator = required
  }
  if (!values.roof_type) {
    errors.roof_type = required
  }
  if (values.roof_type && values.roof_type === OTHER_SELECT_OPTION) {
    if (!values.sale_roof_type_other) errors.sale_roof_type_other = required
  }
  if (!values.total_pitch) {
    errors.total_pitch = required
  }
  if (!values.total_flat) {
    errors.total_flat = required
  }
  if (!values.roof_pitch) {
    errors.roof_pitch = required
  }
  if (values.roof_pitch && values.roof_pitch === OTHER_SELECT_OPTION) {
    if (!values.roof_pitch_other) errors.roof_pitch_other = required
  }
  if (!values.note) {
    errors.note = required
  }

  if (values.materials) {
    const materialsArrayErrors = [];
    values.materials.forEach((material, index) => {
      const materialErrors = {};
      if (!material.brand) {
        materialErrors.brand = required;
        materialsArrayErrors[index] = materialErrors;
      }
      if (!material.brand_series) {
        materialErrors.brand_series = required;
        materialsArrayErrors[index] = materialErrors;
      }
      if (!material.color) {
        materialErrors.color = required;
        materialsArrayErrors[index] = materialErrors;
      }
    });
    if (materialsArrayErrors.length) {
      errors.materials = materialsArrayErrors;
    }
  }

  return errors
};
