import { OTHER_SELECT_OPTION } from "helpers/AppConstants";

export const baseClientValidation = values => {
  const required = 'Required';
  const requiredAddress =  {
    street: required,
    city: required,
    state: required,
    country: required
  };

  const errors = {};
  if (!values.first_name) {
    errors.first_name = required
  }
  if (!values.last_name) {
    errors.last_name = required
  }
  // if (!values.company_name ) {
  //   errors.company_name = required
  // }
  // if (!values.position) {
  //   errors.position = required
  // }
  if (values.position && values.position === OTHER_SELECT_OPTION) {
    if (!values.client_position_other) errors.client_position_other = required
  }
  // if (!values.referralSource) {
  //   errors.referralSource = required
  // }
  if (values.referralSource && values.referralSource === OTHER_SELECT_OPTION) {
    if (!values.referral_resource_other) errors.referral_resource_other = required
  }
  if (!values.contact_address ) {
    errors.contact_address = requiredAddress
  }

  if (values.phones) {
    const phonesArrayErrors = [];
    values.phones.forEach((phone, index) => {
      const phoneErrors = {};
      if (!phone || !phone.phone) {
        phoneErrors.phone = required;
        phonesArrayErrors[index] = phoneErrors;
      }
    });
    if (phonesArrayErrors.length) {
      errors.phones = phonesArrayErrors;
    }
  }

  return errors
};
