import React from "react";
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap'

import Callback from "callbacks/callback"

const CallbacksList = (props) => {
  const { callbacks, isJobPage, convertCallbackToJob } = props;
  return (
    <ListGroup>
      {
        callbacks.map(callback =>{
          return (
            <Callback
              key={callback.id}
              Wrapper={ListGroupItem}
              callback={callback}
              isJobPage={isJobPage}
              convertCallbackToJob={convertCallbackToJob} />
          )
        })
      }
    </ListGroup>
  )
};

CallbacksList.defaultProps = {
  callbacks: [],
  convertCallbackToJob: () => {}
};

CallbacksList.propTypes = {
  callbacks: PropTypes.array.isRequired,
  convertCallbackToJob: PropTypes.func.isRequired
};

export default CallbacksList
