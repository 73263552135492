import {
  DOCUMENTS_REQUEST, DOCUMENTS_REQUEST_SUCCESS, DOCUMENTS_REQUEST_FAILURE,
  CREATE_DOCUMENT_REQUEST , CREATE_DOCUMENT_SUCCESS, CREATE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_REQUEST , DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE,
  DROP_DOCUMENTS
} from "actions/document-actions";

import { removeElementFromList, addElementToList } from "helpers/ListHelpers";

const INITIAL_STATE = { list: [], droppedDocuments: [], error: null, loading: false };

const DocumentsReducers = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case DOCUMENTS_REQUEST:
      return { ...state, error: null, loading: true };
    case DOCUMENTS_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false };
    case DOCUMENTS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    case DROP_DOCUMENTS:
      return { ...state, droppedDocuments: action.payload };

    case CREATE_DOCUMENT_REQUEST:
      return { ...state, error: null, loading: true };
    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        list: addElementToList(state.list, action.payload),
        droppedDocuments: removeElementFromList(state.droppedDocuments, action.payload.name, "name"),
        loading: state.droppedDocuments.length > 1
      };
    case CREATE_DOCUMENT_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false };

    case DELETE_DOCUMENT_REQUEST:
      return { ...state, error: null };
    case DELETE_DOCUMENT_SUCCESS:
      return {...state, list: removeElementFromList(state.list, action.payload) };
    case DELETE_DOCUMENT_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error };


    default:
      return state;
  }
};

export default DocumentsReducers

