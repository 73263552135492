export const LOAD_ADDRESS = 'LOAD_ADDRESS';
import { CALL_FORM_NAME, JOB_EDIT_FORM_NAME } from 'helpers/FormNames';
import { parseJobToForm } from '../api/job';

export const loadAddress = (data) => {
  return {
    type: LOAD_ADDRESS,
    payload: data
  }
};

export const addressToString = (address) => {
  if (!address) return;
  let address_string = '';
  if (address.street) address_string += address.street + ', ';
  if (address.city) address_string += address.city + ', ';
  if (address.state) address_string += address.state;
  if (address.zip) address_string +=', ' + address.zip;

  return address_string;
};

export const parseAddress = (data = {}, key) => {
  let componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    neighborhood: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  let transformed = {};
  data.address_components.forEach((component) => {
    let addressType = component.types[0];
    if (componentForm[addressType]) {
      transformed[addressType] = component[componentForm[addressType]];
    }
  });
  return { [key]: {
    city: transformed.neighborhood || transformed.locality || null,
    street: transformed.route && `${transformed.street_number ? `${transformed.street_number} ` : '' } ${transformed.route}`,
    country: transformed.country || null,
    zip: transformed.postal_code || null,
    state: transformed.administrative_area_level_1 || null,
    lat: data.geometry.location.lat(),
    lng: data.geometry.location.lng()
  }}
};

export const copyClientAddressToJob = () => {
  return (dispatch, getState) => {
    const currentState = getState().form[CALL_FORM_NAME].values;
    copyClientAddress(dispatch, currentState, "job_address");
  }
};

export const copyClientAddressToEditJob = () => {
  return (dispatch, getState) => {
    const currentState = getState().form[JOB_EDIT_FORM_NAME].values;
    copyClientAddress(dispatch, currentState, "job_address");
  }
};

export const copyClientAddress = (dispatch, currentState, addressType) => {
  const address = Object.assign({}, currentState.contact_address);
  const data = currentState;
  data[addressType] = address;
  return dispatch(loadAddress(data));
};
