import MaterialBrandsApi from "api/MaterialBrandsApi";

export const MATERIAL_BRANDS_REQUEST = 'MATERIAL_BRANDS_REQUEST';
export const MATERIAL_BRANDS_REQUEST_SUCCESS = 'MATERIAL_BRANDS_REQUEST_SUCCESS';
export const MATERIAL_BRANDS_REQUEST_FAILURE = 'MATERIAL_BRANDS_REQUEST_FAILURE';

const materialBrandsRequest = () => {
  return {
    type: MATERIAL_BRANDS_REQUEST,
  }
};

const materialBrandsRequestSuccess = (materialBrands) => {
  return {
    type: MATERIAL_BRANDS_REQUEST_SUCCESS,
    payload: materialBrands
  }
};

const materialBrandsRequestFailure = (error) => {
  return {
    type: MATERIAL_BRANDS_REQUEST_FAILURE,
    payload: error
  }
};

const materialBrandsApi = new MaterialBrandsApi();

export const fetchMaterialBrands = () => {
  return (dispatch) => {
    dispatch(materialBrandsRequest());
    return materialBrandsApi.index()
      .done((data) => {
        dispatch(materialBrandsRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(materialBrandsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
