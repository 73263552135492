import {
  OPEN_JOB_ESTIMATE_MODAL,
  CLOSE_JOB_ESTIMATE_MODAL,
  JOB_ESTIMATE_REQUEST,
  JOB_ESTIMATE_REQUEST_SUCCESS,
  JOB_ESTIMATE_REQUEST_FAILURE
} from "actions/job-status-actions";

const INITIAL_STATE = { data: {}, showModal: false, loading: false };

const JobEstimateReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case OPEN_JOB_ESTIMATE_MODAL:
      return {...state, data: action.payload,  showModal: true, loading: false };
    case CLOSE_JOB_ESTIMATE_MODAL:
      return {...state, data: {}, showModal: false, loading: false };

    case JOB_ESTIMATE_REQUEST:
      return {...state, loading: true };
    case JOB_ESTIMATE_REQUEST_SUCCESS:
      return {...state, data: {}, showModal: false, loading: false };
    case JOB_ESTIMATE_REQUEST_FAILURE:
      return {...state, showModal: true, loading: false };

    default:
      return state;
  }
};
export default JobEstimateReducer
