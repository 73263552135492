import ActivityLogApi from "api/ActivityLogApi";

export const ACTIVITY_LOG_REQUEST = 'ACTIVITY_LOG_REQUEST';
export const ACTIVITY_LOG_REQUEST_SUCCESS = 'ACTIVITY_LOG_REQUEST_SUCCESS';
export const ACTIVITY_LOG_REQUEST_FAILURE = 'ACTIVITY_LOG_REQUEST_FAILURE';
export const ACTIVITY_LOG_SCROLL_PAGINATION = 'ACTIVITY_LOG_SCROLL_PAGINATION';

const activityLogRequest = () => {
  return {
    type: ACTIVITY_LOG_REQUEST,
  }
};

const activityLogRequestSuccess = (activityLog) => {
  return {
    type: ACTIVITY_LOG_REQUEST_SUCCESS,
    payload: activityLog
  }
};

const activityLogRequestFailure = (error) => {
  return {
    type: ACTIVITY_LOG_REQUEST_FAILURE,
    payload: error
  }
};

const activityLogScrollPagination = (activityLog) => {
  return {
    type: ACTIVITY_LOG_SCROLL_PAGINATION,
    payload: activityLog
  }
};

const activityLogApi = new ActivityLogApi();

export const fetchJobActivityLog = (jobId) => {
  return activitiesRequestHandler(activityLogApi.jobActivities(jobId))
};

export const fetchClientActivityLog = (clientId) => {
  return activitiesRequestHandler(activityLogApi.clientActivities(clientId))
};

export const fetchDashboardActivityLog = (page) => {
  const successAction = page === 1 ? activityLogRequestSuccess : activityLogScrollPagination;
  return activitiesRequestHandler(activityLogApi.dashboardActivities(page), successAction);
};

const activitiesRequestHandler = (request, succesAction = activityLogRequestSuccess) => {
  return (dispatch) => {
    dispatch(activityLogRequest());
    return request
      .then((data) => {
        dispatch(succesAction(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(activityLogRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
