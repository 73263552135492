import ApiClient from './ApiClient'

class TodoApi extends ApiClient {
  constructor() {
    super({url:'todos', dataWrapperKey: 'todo' })
  }

  index(jobId) {
    return super.index({job_id: jobId})
  }

  clientTodos(clientId) {
    return super.show(clientId, 'client_todos')
  }

  dashboardTodos() {
    return super.index({}, 'dashboard_todos')
  }

  complete(id) {
    return super.putNestedAction('/complete', {id})
  }
}

export default TodoApi
