import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import DropZone from "documents/dropzone";
import DocumentsList from "documents/documents-list";
import {
  fetchDocuments, addDocument, deleteDocument, dropDocuments
} from "actions/document-actions";

class DocumentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addDocument = this.addDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.dropDocuments = this.dropDocuments.bind(this);
  }

  componentWillMount() {
    const { dispatch, jobId } = this.props;
    jobId && dispatch(fetchDocuments(jobId));
  }

  addDocument(document) {
    const { dispatch, jobId} = this.props;
    const dataToSent = {attachment: document, jobId};
    dispatch(addDocument(dataToSent));
  }

  deleteDocument(documentId) {
    const { dispatch } = this.props;
    dispatch(deleteDocument(documentId));
  }

  dropDocuments(documents) {
    const { dispatch } = this.props;
    dispatch(dropDocuments(documents));
  }

  render() {
    const { documents } = this.props;
    return (
      <div className="margin-top-10 padding-bottom-30">
        <DropZone
          addDocument={this.addDocument}
          dropDocuments={this.dropDocuments}
          droppedDocuments={documents.droppedDocuments}
          loading={documents.loading} />
        <DocumentsList documents={documents.list} deleteDocument={this.deleteDocument}/>
      </div>
    )
  }
}

DocumentComponent.defaultProps = {
  documents: []
};

DocumentComponent.propTypes = {
  jobId: PropTypes.any.isRequired,
  documents: PropTypes.object.isRequired
};
export default connect()(DocumentComponent);
