import { LOAD_CLIENT_TO_FORM, RESET_CLIENT_EDIT_FORM, UPDATE_CLIENT_REQUEST,
         UPDATE_CLIENT_REQUEST_SUCCESS, UPDATE_CLIENT_REQUEST_ERROR
       } from 'actions/clients-actions';
import { LOAD_ADDRESS } from 'actions/addresses';

const INITIAL_STATE = { data: {}, showModal: false, loading: false };

const EditClientReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case LOAD_CLIENT_TO_FORM:
      return {...state, data: action.payload,  showModal: true, loading: false };
    case RESET_CLIENT_EDIT_FORM:
      return {...state, data: {}, showModal: false, loading: false };

    case UPDATE_CLIENT_REQUEST:
      return {...state, loading: true };
    case UPDATE_CLIENT_REQUEST_SUCCESS:
      return {...state, data: {}, showModal: false, loading: false };
    case UPDATE_CLIENT_REQUEST_ERROR:
      return {...state, showModal: true, loading: false}

    case LOAD_ADDRESS:
      return {...state, data: action.payload};

    default:
      return state;
  }
};
export default EditClientReducer
