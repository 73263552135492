import { connect } from 'react-redux';

import ClientInfoComponent from 'presentational/client-info/client-info-component';
import { loadClient, loadClientToForm, resetClientEditForm, loadClientToJobForm } from 'actions/clients-actions';
import { clientAddress, updateClient, toggleArchive } from 'api/client';
import { fetchClientJobs } from 'api/job';
import { fetchNotes, fetchNoteTypes, addNote } from "actions/notes-actions";
import {completeTodo, fetchClientTodos} from "actions/todo-actions";
import { fetchClientCallbacks } from 'actions/callback-actions';
import { fetchClientActivityLog } from "actions/activity-log-actions";
import { fetchUsers } from "actions/user-actions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientJobs: (clientId) => {
      dispatch(fetchClientJobs(clientId))
    },
    loadClient: (client) => {
      dispatch(loadClient(client))
    },
    loadAddress: (data, key) => {
      dispatch(clientAddress(data, key));
    },
    loadToEditForm: (client) => {
      dispatch(loadClientToForm(client))
    },
    loadClientToJobForm: (client) => {
      dispatch(loadClientToJobForm(client))
    },
    resetEditForm: () => {
      dispatch(resetClientEditForm())
    },
    updateClient: (data) => {
      dispatch(updateClient(data))
    },
    toggleArchive: (data) => {
      dispatch(toggleArchive(data))
    },
    fetchNotes: (type, id) => {
      dispatch(fetchNotes(type, id))
    },
    fetchNoteTypes: () => {
      dispatch(fetchNoteTypes())
    },
    addNote: (data, callback) => {
      dispatch(addNote(data, callback))
    },
    fetchTodos: (clientId) => {
      dispatch(fetchClientTodos(clientId))
    },
    fetchCallbacks: (clientId) => {
      dispatch(fetchClientCallbacks(clientId))
    },
    completeTodo: (todoId, callback) => {
      dispatch(completeTodo(todoId, callback))
    },
    fetchActivityLog: (clientId) => {
      dispatch(fetchClientActivityLog(clientId))
    },
    fetchUsers: () => {
      dispatch(fetchUsers())
    }
  }
};

function mapStateToProps(state) {
  return {
    client: state.client,
    editClient: state.editClient,
    jobs: state.jobs,
    notesData: state.notes,
    todos: state.todos,
    users: state.users,
    callbacks: state.callbacks,
    activityLog: state.activityLog
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfoComponent);
