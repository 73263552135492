import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import EstimatorsPanel from './estimators-panel';
import EstimatesPanel from './estimates-panel';
import Calendar from './calendar';
import UserUnavailable from './user-not-available';
import { scrollToTop } from 'helpers/ScreenHelpers';
import { onlyAssignedEstimates, filterByAppointmentStatus, unassignedEstimates } from 'helpers/Estimates';
import { JOB_STATUS_ASSIGNED, UNASSIGNED_ESTIMATES, ALL_ESTIMATES } from 'helpers/AppConstants';
import { formatTimeSlotsData, checkRelevantTime } from 'helpers/Calendar';
import { scrollToElement } from 'helpers/ScreenHelpers';
import { perfomAsync } from 'helpers/Async';
import { filterUserNotAvailableList } from 'helpers/Calendar';
import { ISO_DATE_TIME_FORMAT } from 'helpers/DateTimeHelpers';

class CalendarComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      estimatesPanelTitle: '',
      extremeAppointmentDates: this.extremeAppointmentDates(moment())
    };
    this.onSelectNavItem = this.onSelectNavItem.bind(this);
    this.setEstimatesPanelTitle = this.setEstimatesPanelTitle.bind(this);
    this.shouldFetchEstimates = this.shouldFetchEstimates.bind(this);
    this.toggleEstimatesPanel = this.toggleEstimatesPanel.bind(this);
    this.assignEstimator = this.assignEstimator.bind(this);
    this.unassignEstimator = this.unassignEstimator.bind(this);
    this.onSelectSlot = this.onSelectSlot.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.setAppointment = this.setAppointment.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.setExtremeAppointmentDates = this.setExtremeAppointmentDates.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
  }

  componentWillMount() {
    this.initCalendar(ALL_ESTIMATES);

    setInterval(() => {
      this.props.updateCalendarIfEstimatesUpdated({ type: ALL_ESTIMATES,
                                                    estimated: true,
                                                    start_date: this.state.extremeAppointmentDates.startDate,
                                                    end_date: this.state.extremeAppointmentDates.endDate })
        .then(() => {
          if (this.props.calendarUpdated) {
            this.initCalendar(this.checkSelectedNav(this.props.selectedNavItem));
          }
        });
    }, 60000);
  }

  initCalendar(estimatesType) {
    this.fetchEstimators();
    this.fetchEstimates(estimatesType);
    this.props.fetchUserNotAvailables();
    this.setEstimatesPanelTitle(this.props.selectedNavItem);
  }

  fetchEstimators() {
    this.props.fetchEstimators();
  }

  fetchEstimates(estimatesType) {
    this.props.fetchEstimates({ type: estimatesType,
                                estimated: true,
                                start_date: this.state.extremeAppointmentDates.startDate,
                                end_date: this.state.extremeAppointmentDates.endDate });
  }

  extremeAppointmentDates(date) {
    return {
      startDate: moment(date).subtract(1,'months').startOf('month').format(ISO_DATE_TIME_FORMAT),
      endDate: moment(date).add(1,'months').endOf('month').format(ISO_DATE_TIME_FORMAT)
    }
  }

  setEstimatesPanelTitle(navKey) {
    const estimators = this.props.estimators;
    let title;
    if (typeof navKey === 'string') {
      title = `All ${navKey === UNASSIGNED_ESTIMATES ? ' unassigned' : ''}`
    } else {
      const estimator = estimators.find(item => item.id === navKey);
      title = estimator.current_user ? 'My' : estimator.name;
    }
    this.setState({estimatesPanelTitle: title});
  }

  checkSelectedNav(eventKey) {
    if (eventKey === UNASSIGNED_ESTIMATES) return ALL_ESTIMATES;
    return eventKey;
  }

  shouldFetchEstimates(selected, newData) {
    if (selected !== newData) {
      this.props.selectEstimator(newData);
      this.fetchEstimates(this.checkSelectedNav(newData));
    }
  }

  toggleEstimatesPanel(data) {
    const { sizes, closeEstimatesPanel, selectEstimator } = this.props;
    sizes.showEstimatesPanel ? closeEstimatesPanel() : selectEstimator(data);
  }

  onSelectNavItem(data) {
    const { selectedNavItem } = this.props;
    if (selectedNavItem === data) this.toggleEstimatesPanel(data);
    this.shouldFetchEstimates(selectedNavItem, data);
    this.setEstimatesPanelTitle(data);
  }

  unassignEstimator(data, callback) {
    const callbacks = () => {
      callback();
      this.fetchEstimators();
    };
    this.props.unassignEstimator(data, callbacks)
  }

  assignEstimator(data) {
    const { assignEstimator, clearTimeSlotsData } = this.props;
    const callbacks = () => {
      clearTimeSlotsData();
      this.fetchEstimators();
    };
    assignEstimator(data, callbacks)
  }

  onSelectSlot(data) {
    const { selectedNavItem, resetSelectedEstimate, selectEstimator, selectTimeSlots, estimates } = this.props;
    const formData = formatTimeSlotsData(data);
    const filteredEstimates = this.showFilter(estimates.list);
    if (checkRelevantTime(formData) && filterByAppointmentStatus(filteredEstimates, JOB_STATUS_ASSIGNED).length) {
      scrollToTop();
      resetSelectedEstimate();
      this.setEstimatesPanelTitle(selectedNavItem);
      selectEstimator(selectedNavItem);
      selectTimeSlots(formData);
    }
  }

  onSelectEvent(event) {
    const { selectEstimate, selectEvent, selectedNavItem, selectEstimator } = this.props;
    this.setEstimatesPanelTitle(selectedNavItem);
    selectEstimator(selectedNavItem);
    selectEvent(event);
    selectEstimate(event);
    perfomAsync(() => scrollToElement(`estimate_${event.id}`));
  }

  setAppointment(data, callback) {
    const {setAppointment, clearTimeSlotsData , resetSelectedEvent} = this.props;
    const mixCallbacks = () => {
      if (typeof callback === 'function') callback();
      clearTimeSlotsData();
      resetSelectedEvent();
    };
    setAppointment(data, mixCallbacks)
  }

  showFilter(estimates) {
    const { selectedNavItem } = this.props;
    if (selectedNavItem === UNASSIGNED_ESTIMATES) return unassignedEstimates(estimates);
    return onlyAssignedEstimates(estimates);
  }

  setExtremeAppointmentDates(date) {
    const extremeAppointmentDates = this.extremeAppointmentDates(date);

    return new Promise((resolve, reject) => {
      this.setState({
        extremeAppointmentDates: {
          startDate: extremeAppointmentDates.startDate,
          endDate: extremeAppointmentDates.endDate
        }
      });
      resolve();
    });
  }

  onNavigate(date) {
    this.setExtremeAppointmentDates(date).then(() => {
      this.initCalendar(this.checkSelectedNav(this.props.selectedNavItem));
    });
  }

  render() {
    const {
      estimators, estimates, sizes, closeEstimatesPanel, selectedNavItem, userUnavailable,
      openUserUnavailableModal, closeUserUnavailableModal, addUserNotAvailable, showUnavailableDateModal,
      closeRemoveUnavailableDateModal, openRemoveUnavailableDateModal, event, removeUnavailableDate,
      openJobDetailsModal, showJobDetailsModal, closeJobDetailsModal
    } = this.props;
    const { estimatesPanelTitle } = this.state;
    const notAvailableList = filterUserNotAvailableList(userUnavailable.list, selectedNavItem);

    return (
      <Row className="column-reverse">
        <div>
          <Col md={sizes.estimatorsPanelWidth}>
            <div className="margin-bottom-10 padding-left-15">
              <UserUnavailable
                openModal={openUserUnavailableModal}
                onCloseModal={closeUserUnavailableModal}
                show={userUnavailable.showModal}
                onSubmit={addUserNotAvailable}
              />
            </div>
            <EstimatorsPanel
              {...this.props}
              estimators={estimators}
              onSelectNavItem={this.onSelectNavItem}
              selectedItem={selectedNavItem}
            />
          </Col>
          { sizes.showEstimatesPanel &&
            <Col md={sizes.estimatesPanelWidth}>
              <EstimatesPanel
                {...this.props}
                unassignEstimator={this.unassignEstimator}
                assignEstimator={this.assignEstimator}
                estimates={this.showFilter(estimates.list)}
                estimators={estimators}
                closePanel={closeEstimatesPanel}
                title={estimatesPanelTitle}
                selectedEstimate={estimates.selected}
                setAppointment={this.setAppointment}
              />
            </Col> }
        </div>

        <Col md={sizes.calendarWidth}>
          <Calendar
            removeUnavailableDate={removeUnavailableDate}
            openRemoveUnavailableDateModal={openRemoveUnavailableDateModal}
            showUnavailableDateModal={showUnavailableDateModal}
            closeRemoveUnavailableDateModal={closeRemoveUnavailableDateModal}
            openJobDetailsModal={openJobDetailsModal}
            showJobDetailsModal={showJobDetailsModal}
            closeJobDetailsModal={closeJobDetailsModal}
            estimates={onlyAssignedEstimates(estimates.list)}
            notAvailableList={notAvailableList}
            onSelectSlot={this.onSelectSlot}
            onSelectEvent={this.onSelectEvent}
            onNavigate={this.onNavigate}
            event={event}
          />
        </Col>
      </Row>
    )
  }
}

CalendarComponent.propTypes = {
  estimators: PropTypes.array.isRequired,
  estimates: PropTypes.object.isRequired,
};

export default CalendarComponent;
