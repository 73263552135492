import ApiClient from 'api/ApiClient'

class ActivityLogApi extends ApiClient {
  constructor() {
    super({url:'job_activities'})
  }

  jobActivities(jobId) {
    return super.show(jobId)
  }

  clientActivities(clientId) {
    return super.show(clientId, 'client_activities')
  }

  dashboardActivities(offsetStartId) {
    return super.index({ offset_start: offsetStartId }, 'dashboard_activities')
  }
}

export default ActivityLogApi
