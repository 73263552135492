import { ESTIMATORS_REQUEST, ESTIMATORS_REQUEST_SUCCESS, ESTIMATORS_REQUEST_FAILURE
} from "../actions/user-actions";

const INITIAL_STATE = { estimators: [], loading: false, error: null };

export const EstimatorsReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case ESTIMATORS_REQUEST:
      return {...state, loading: true };
    case ESTIMATORS_REQUEST_SUCCESS:
      return {...state, ...action.payload, loading: false};
    case ESTIMATORS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};

    default:
      return state;
  }
};