import { createStore, compose } from "redux"

import CallFormReducer from "reducers/call-form-reducers"
import configureMiddleware from "store/configure-middleware";

const configureCallFormStore = preloadedState => {
  return createStore(
    CallFormReducer,
    preloadedState,
    compose(
      configureMiddleware()
    )
  )
};

export default configureCallFormStore
