import { connect } from 'react-redux';
import JobsList from 'presentational/jobs/jobs-component';
import { fetchJobs, updateJob } from "api/job";
import { loadJobToEditForm, resetJobEditForm } from "actions/jobs-actions"
import { fetchHeatmaps } from "actions/statistics/heatmaps-actions";
import { fetchMaterialBrands } from "actions/material-brands-actions";
import { selectTab, resetTabsData } from "actions/jobs-tabs-actions";
import { fetchStatusesCountsByEstimator } from "actions/statistics/statuses-counts-by-estimator-actions";
import { fetchTimelineData } from "actions/statistics/timeline-actions";
import { fetchStatusesCounts } from "actions/statistics/statuses-counts-actions";
import { fetchDistributionsData } from "actions/statistics/distributions-actions";
import { setSortParams } from "actions/search-actions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchJobs: (params) => {
      dispatch(fetchJobs(params))
    },
    loadJobToEditForm: (job) => {
      dispatch(loadJobToEditForm(job))
    },
    resetJobEditForm: () => {
      dispatch(resetJobEditForm())
    },
    updateJob: (data) => {
      dispatch(updateJob(data))
    },
    selectTab: (selectedTab) => {
      dispatch(selectTab(selectedTab))
    },
    fetchHeatmaps: (params) => {
      dispatch(fetchHeatmaps(params))
    },
    fetchStatusesCountsByEstimator: (params) => {
      dispatch(fetchStatusesCountsByEstimator(params))
    },
    fetchStatusesCounts: (params) => {
      dispatch(fetchStatusesCounts(params))
    },
    fetchTimelineData: (params) => {
      dispatch(fetchTimelineData(params))
    },
    fetchDistributionsData: (params) => {
      dispatch(fetchDistributionsData(params))
    },
    resetTabsData: () => {
      dispatch(resetTabsData())
    },
    setSortParams: (sortParams) => {
      dispatch(setSortParams(sortParams))
    },
    fetchMaterialBrands: () => {
      dispatch(fetchMaterialBrands())
    },
  }
};

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs,
    editJob: state.editJob,
    heatmaps: state.heatmaps,
    statusesCountsByEstimator: state.statusesCountsByEstimator,
    statusesCounts: state.statusesCounts,
    materialBrands: state.materialBrands.list,
    timeline: state.timeline,
    distributions: state.distributions,
    tabs: state.tabs,
    search: state.search
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsList);
