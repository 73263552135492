import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import {
  JOB_REQUEST, JOB_REQUEST_SUCCESS, JOB_REQUEST_FAILURE,
  UPDATE_JOB_REQUEST_SUCCESS, UPDATE_JOB_REQUEST_FAILURE,
  DISABLE_ADDRESS_INPUT
} from "actions/jobs-actions";
import {
  JOB_ESTIMATE_REQUEST_SUCCESS,
  JOB_ESTIMATE_REQUEST_FAILURE,
  JOB_SOLD_REQUEST_SUCCESS
} from "actions/job-status-actions";
import {
  JOB_LOST_REQUEST_SUCCESS,
  JOB_LOST_REQUEST_FAILURE
} from "actions/job-lost-actions";

import { JOB_STATUS_SOLD } from "helpers/AppConstants";

import EditJobReducer from 'reducers/edit-job-reducer';
import NotesReducer from 'reducers/notes-reducer';
import UsersReducer from 'reducers/users-reducer';
import TodosReducers from 'reducers/todos-reducers';
import CallbacksReducer from 'reducers/callbacks-reducer';
import JobSoldReducer from "reducers/job-sold-reducer";
import RoofPitchesReducer from "reducers/roof-pitches-reducer";
import SaleRoofTypesReducer from "reducers/sale-roof-types-reducer";
import MaterialBrandsReducer from "reducers/material-brands-reducer";
import JobEstimateReducer from "reducers/job-estimate-reducer";
import BrandSeriesReducer from "reducers/brand-series-reducer";
import LostReasonsReducer from "reducers/lost-reasons-reducer"
import JobLostReducer from "reducers/job-lost-reducer"
import ActivityLogReducer from "reducers/activity-log-reducer"
import DocumentsReducers from "reducers/documents-reducer";

const INITIAL_STATE = { data: {}, error: null, loading: false };

export const JobReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case JOB_REQUEST:
      return { ...state, error: null, loading: true };
    case JOB_REQUEST_SUCCESS:
      return {...state, data: {...action.payload, disableJobAddressForm: action.payload.address_same_as_contact_location}, loading: false };
    case JOB_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message}; //2nd one is network or server down errors
      return {...state, error: error, loading: false };

    case UPDATE_JOB_REQUEST_SUCCESS:
      return {...state, data: {...action.payload, disableJobAddressForm: action.payload.address_same_as_contact_location} };
    case UPDATE_JOB_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error };

    case JOB_ESTIMATE_REQUEST_SUCCESS:
      return {...state, data: action.payload };
    case JOB_ESTIMATE_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error };

    case JOB_LOST_REQUEST_SUCCESS:
      return {...state, data: action.payload };
    case JOB_LOST_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error };

    case JOB_SOLD_REQUEST_SUCCESS:
      return {...state, data: {...state.data, status: JOB_STATUS_SOLD} };

    case DISABLE_ADDRESS_INPUT:
      return {...state, data: {...state.data, disableJobAddressForm: action.payload} };

    default:
      return state;
  }
};

const JobReducers = combineReducers({
  job: JobReducer,
  editJob: EditJobReducer,
  jobEstimate: JobEstimateReducer,
  jobLost: JobLostReducer,
  jobSold: JobSoldReducer,
  notes: NotesReducer,
  users: UsersReducer,
  todos: TodosReducers,
  callbacks: CallbacksReducer,
  materialBrands: MaterialBrandsReducer,
  roofPitches: RoofPitchesReducer,
  lostReasons: LostReasonsReducer,
  saleRoofTypes: SaleRoofTypesReducer,
  materialsBrandSeries: BrandSeriesReducer,
  activityLog: ActivityLogReducer,
  documents: DocumentsReducers,
  form: reduxFormReducer
});

export default JobReducers
