import { jobLostParams } from "helpers/Job";
import JobApi from "api/JobApi";

export const OPEN_JOB_LOST_MODAL = 'OPEN_JOB_LOST_MODAL';
export const CLOSE_JOB_LOST_MODAL = 'CLOSE_JOB_LOST_MODAL';
export const JOB_LOST_REQUEST = 'JOB_LOST_REQUEST';
export const JOB_LOST_REQUEST_SUCCESS = 'JOB_LOST_REQUEST_SUCCESS';
export const JOB_LOST_REQUEST_FAILURE = 'JOB_LOST_REQUEST_FAILURE';

export const openJobLostModal = (job) => {
  return {
    type: OPEN_JOB_LOST_MODAL,
    payload: job
  }
};

export const closeJobLostModal = () => {
  return {
    type: CLOSE_JOB_LOST_MODAL
  };
};

const jobLostRequest = () => {
  return {
    type: JOB_LOST_REQUEST,
  }
};

const jobLostRequestSuccess = (data) => {
  return {
    type: JOB_LOST_REQUEST_SUCCESS,
    payload: data
  };
};

const jobLostRequestFailure = (error) => {
  return {
    type: JOB_LOST_REQUEST_FAILURE,
    payload: error
  };
};

const jobApi = new JobApi();

export const lostJob = (lostParams, callback) => {
  const dataToSend = jobLostParams(lostParams);
  return (dispatch) => {
    dispatch(jobLostRequest());
    return jobApi.lost(dataToSend)
      .done(function(data) {
        dispatch(jobLostRequestSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(jobLostRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
