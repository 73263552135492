import React from "react";
import { Table } from "react-bootstrap";

import { selectMainContact } from "api/client";
import ArchiveClientAlert from "shared/archive-client-alert";
import { FaIcon } from "shared/fa-icon";
import { CAN_ARCHIVE_CLIENT } from "helpers/Permissions";
import { clientPath } from "helpers/PathHelpers";
import Spinner from 'shared/spinner';
import { Pagination } from "shared/pagination";
import { Sorting } from "shared/sorting";
import { addressToString } from "actions/addresses";
import { CLIENT_NAME, ADDRESS, BUSINESS_NAME, ARCHIVED_CLIENTS } from "helpers/AppConstants";
import { extractNumbersFromStringForPhone } from "helpers/ViewHelpers";

class ClientsTable extends React.Component {
  constructor(props) {
    super(props);
    this.renderArchiveButton = this.renderArchiveButton.bind(this);
    this.selectActions = this.selectActions.bind(this);
    this.state = {
      showAlert: false,
      client: {}
    };
  }

  editContact(client) {
    this.props.loadToEditForm(client)
  }

  showToggleAlert(client) {
    this.setState({
      client: client,
      showAlert: true
    });
  }

  hideAlert() {
    this.setState({showAlert: false})
  }

  toggleArchive(client) {
    this.props.toggleArchive(client);
    this.setState({showAlert: false})
  }

  renderArchiveButton(client) {
    let archivedIcon = <FaIcon size="lg" icon="archive"/>;
    let archivedClass = 'text-danger';
    if (client.archived) {
      archivedIcon = (
        <span>
          <FaIcon size="lg" icon="reply"/>
          <FaIcon size="lg" icon="archive"/>
        </span>
      );
      archivedClass = 'text-success';
    }
    const link = <a className={archivedClass} onClick={() => this.showToggleAlert(client) }>{archivedIcon}</a>;
    return CAN_ARCHIVE_CLIENT && link
  }

  selectActions(client) {
    const editIcon = <a className="text-muted" onClick={() => this.editContact(client) }><FaIcon size="lg" icon="pencil"/></a>;
    return (
      <div className={`edit-icons${client.archived || !this.renderArchiveButton(client) ? '-flex-end' : ''}`}>
        { !client.archived && editIcon }
        { this.renderArchiveButton(client) }
      </div>
    );
  }

  renderSortComponent(title, sortBy) {
    const { onTableSort, search } = this.props;
    return(
      <Sorting
        title={title}
        onClick={onTableSort}
        sortBy={sortBy}
        orderBy={search.sort.orderBy}
        selectedSortKey={search.sort.sortBy}
      />
    )
  }

  renderRow(client) {
    return (
      <tr key={client.id}>
        <td><a href={clientPath(client.id)}>{ client.name }</a></td>
        <td>{ client.company_name }</td>
        <td>{ addressToString(client.address) }</td>
        <td>
          <a href={`tel:${extractNumbersFromStringForPhone(selectMainContact(client.phones))}`}>
            { selectMainContact(client.phones) }
          </a>
        </td>
        <td>{ selectMainContact(client.emails, 'email') }</td>
        <td >{this.selectActions(client)}</td>
      </tr>
    )
  }

  filterClients(clients) {
    const { type } = this.props;
    const archived = type === ARCHIVED_CLIENTS;
    return clients.filter(client => client.archived === archived);
  }

  renderBody(clients) {
    return (
      this.filterClients(clients).map((job)=> this.renderRow(job))
    )
  }

  render() {
    const { clients, onPageChange } = this.props;
    return (
      <div>
        <Table striped bordered condensed hover>
          <thead>
          <tr>
            <th className="col-xs-3">{this.renderSortComponent("Client's name", CLIENT_NAME)}</th>
            <th className="col-xs-2">{this.renderSortComponent("Business name", BUSINESS_NAME)}</th>
            <th className="col-xs-3">Address</th>
            <th className="col-xs-2">Phone number</th>
            <th className="col-xs-2">Email</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {!clients.loading && this.renderBody(clients.list)}
          </tbody>
        </Table>
        <Spinner active={clients.loading} search><span>Loading...</span></Spinner>
        <Pagination hide={clients.loading} paginationData={clients.meta} onPageChange={onPageChange} />
        <ArchiveClientAlert
          client={this.state.client}
          show={this.state.showAlert}
          onConfirm={() => this.toggleArchive(this.state.client)}
          onCancel={() => this.hideAlert()}
        />
      </div>
    )
  }
}

export default ClientsTable
