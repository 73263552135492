import { JOB_STATUS_SCHEDULED } from 'helpers/AppConstants';
import { parseDate, parseTime } from 'helpers/DateTimeHelpers';
import { isJobEstimated } from 'helpers/Job';

export const onlyAssignedEstimates = (estimates = []) => {
  return estimates.filter((estimate) => estimate.user);
};

export const onlyActiveEstimates = (estimates = []) => {
  return estimates.filter((estimate) => {
    return !isJobEstimated(estimate);
  });
};

export const unassignedEstimates = (estimates = []) => {
  return estimates.filter((estimate) => !estimate.user);
};

export const filterByAppointmentStatus = (estimates, status=JOB_STATUS_SCHEDULED) => {
  const scheduled = status === JOB_STATUS_SCHEDULED;
  return estimates.filter((estimate) => {
    return scheduled ? estimate.appointment_date : !estimate.appointment_date;
  });
};

export const selectedCurrent = (selected, current) => {
  return selected.id === current.id;
};

export const formatEstimates = (estimates) => {
  return estimates.map((item) => {
    return {
      ...item,
      title: parseEstimateTitle(item),
      start: parseDate(item.appointment_date, item.time_from),
      end: parseDate(item.appointment_date, item.time_to),
      selectable: true
    }
  });
};

const parseEstimateTitle = (data) => {
  return `${data.client.name}, ${data.address.city}, ${data.address.street}`;
};

