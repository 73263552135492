import StatisticsApi from "api/StatisticsApi";

export const STATUSES_COUNTS_BY_ESTIMATOR_REQUEST = 'STATUSES_COUNTS_BY_ESTIMATOR_REQUEST';
export const STATUSES_COUNTS_BY_ESTIMATOR_REQUEST_SUCCESS = 'STATUSES_COUNTS_BY_ESTIMATOR_REQUEST_SUCCESS';
export const STATUSES_COUNTS_BY_ESTIMATOR_REQUEST_FAILURE = 'STATUSES_COUNTS_BY_ESTIMATOR_REQUEST_FAILURE';

const statusesCountsRequest = () => {
  return {
    type: STATUSES_COUNTS_BY_ESTIMATOR_REQUEST,
  }
};

const statusesCountsRequestSuccess = (data) => {
  return {
    type: STATUSES_COUNTS_BY_ESTIMATOR_REQUEST_SUCCESS,
    payload: data
  }
};

const statusesCountsFailure = (error) => {
  return {
    type: STATUSES_COUNTS_BY_ESTIMATOR_REQUEST_FAILURE,
    payload: error
  }
};

const statisticsApi = new StatisticsApi();

export const fetchStatusesCountsByEstimator = (params) => {
  return (dispatch) => {
    dispatch(statusesCountsRequest());
    return statisticsApi.estimators(params)
      .then((data) => {
        dispatch(statusesCountsRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(statusesCountsFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
