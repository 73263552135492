import React from "react";
import PropTypes from 'prop-types';

import { FaIcon } from "./fa-icon";
import { jobPath } from "helpers/PathHelpers";

export const JobLink = (props) => {
    const { job, linkText, className, titleAsMain } = props;
    const title = job.callback ? "Show Callback" : "Show Job";
    return (
      <a href={jobPath(job.id)} className={`${className}`} title={title}>
        { titleAsMain ? title : linkText }
      </a>
    );
};

JobLink.defaultProps = {
  linkText: <FaIcon size="lg" icon="info-circle"/>,
  className: '',
  titleAsMain: false
};

JobLink.propTypes = {
  job: PropTypes.object.isRequired,
  linkText: PropTypes.any,
  className: PropTypes.string,
  titleAsMain: PropTypes.bool
};
