import React from "react";
import PropTypes from 'prop-types';

import { FaIcon } from "shared/fa-icon";
import { Appointment } from "shared/appointment";
import { OverflowScroll } from "shared/oferflow-scroll";
import { EstimateRow } from "./estimate-row";
import { FocusElement } from "shared/focus-element";
import { formatHours } from "helpers/DateTimeHelpers";
import {
  filterByAppointmentStatus,
  unassignedEstimates,
  onlyAssignedEstimates,
  onlyActiveEstimates,
  formatEstimates
} from "helpers/Estimates";
import AssignEstimatorForm from 'estimates/assign-estimator-form'
import { JOB_STATUS_ASSIGNED, JOB_STATUS_SCHEDULED } from "helpers/AppConstants";
import { JobLink } from "shared/job-link"

class EstimatesPanel extends React.Component {
  constructor(props) {
    super(props);
    this.closePanelIcon = this.closePanelIcon.bind(this);
    this.renderUnassigned = this.renderUnassigned.bind(this);
    this.renderAssigned = this.renderAssigned.bind(this);
    this.renderUnscheduled = this.renderUnscheduled.bind(this);
    this.focusText = this.focusText.bind(this);
    this.closeFocusOverlay = this.closeFocusOverlay.bind(this);
  }

  renderDivider(title, icon, className='') {
    return (
      <div className={`bottom-divider bolder-text margin-top-10 ${className}`}>
        {title}
        {icon && <span className={`pull-right`}>{icon}</span> }
      </div>
    )
  }

  closePanelIcon() {
    const { closePanel } = this.props;
    return (
      <span>
        { closePanel &&
        <a className="text-danger" onClick={() => closePanel() }>
          <FaIcon size="lg" icon="caret-left"/>
        </a>}
      </span>
    )
  }

  renderUnassigned(estimate) {
    const { estimators, assignEstimator, timeSlotsData, selectedEstimate, selectEstimate } = this.props;
    return(
      <EstimateRow key={estimate.id} estimate={estimate} active={selectedEstimate.id === estimate.id}>
        <AssignEstimatorForm
          form={`assign_estimator_${estimate.id}`}
          estimators={estimators}
          estimate={estimate}
          onSubmit={assignEstimator}
          initializeData={timeSlotsData}
          onSelect={selectEstimate}
          selectedEstimate={selectedEstimate}
        />
        <div className="position-right job-link-button">
          <JobLink className="btn btn-sm btn-default" titleAsMain job={estimate} />
        </div>
      </EstimateRow>
    )
  }

  renderAssigned(estimate) {
    const { selectEstimate } = this.props;
    return (
      <div id={`estimate_${estimate.id}`} key={estimate.id}>
        <Appointment
          {...this.props}
          estimate={estimate}
          Wrapper={EstimateRow}
          onSelect={()=> selectEstimate(estimate)}
        />
      </div>
    )
  }

  closeFocusOverlay() {
    const { clearTimeSlotsData, resetSelectedEstimate } = this.props;
    clearTimeSlotsData();
    resetSelectedEstimate();
  }

  renderUnscheduled(estimates, arrowDown) {
    const { focusUnassigned } = this.props;
    const unassigned = unassignedEstimates(estimates);
    const unscheduled = filterByAppointmentStatus(onlyAssignedEstimates(estimates), JOB_STATUS_ASSIGNED);
    return (
      <div>
        <FocusElement focus={focusUnassigned} text={this.focusText()} onClose={this.closeFocusOverlay}>
          {!!unassigned.length && unassigned.map((estimate) =>this.renderUnassigned(estimate))}
          {!!unscheduled.length &&
          <div>
            {this.renderDivider('Unscheduled estimates', arrowDown)}
            {unscheduled.map((estimate) =>this.renderAssigned(estimate))}
          </div>}
        </FocusElement>
      </div>
    )
  }

  focusText() {
    const { timeSlotsData } = this.props;
    return (
        <span>
          Select estimate to schedule: Date {timeSlotsData.date},
          From {formatHours(timeSlotsData.start)},  To {formatHours(timeSlotsData.end)}
        </span>
    )
  }

  render() {
    const { estimates, title } = this.props;
    const closeIcon = this.closePanelIcon();
    const arrowDown = <FaIcon size="lg" icon="arrow-down" />;
    const scheduledEstimates =
      filterByAppointmentStatus(
        onlyActiveEstimates(formatEstimates(estimates)),
        JOB_STATUS_SCHEDULED
      );
    return (
      <div>
        {this.renderDivider(`${title} estimates`, closeIcon, 'large')}
        <OverflowScroll>
          {this.renderUnscheduled(estimates, arrowDown)}
          {!!scheduledEstimates.length && this.renderDivider('Scheduled estimates', arrowDown)}
          {scheduledEstimates.map((estimate) => this.renderAssigned(estimate))}
        </OverflowScroll>
      </div>
    )
  }
}

EstimatesPanel.defaultProps = {
  estimates: [],
  estimators: [],
  focusUnassigned: false
};

EstimatesPanel.propTypes = {
  focusUnassigned: PropTypes.bool,
  estimates: PropTypes.array,
  closePanel: PropTypes.func,
  title: PropTypes.string.isRequired

};

export default EstimatesPanel;
