import {
  HEATMAPS_REQUEST,
  HEATMAPS_REQUEST_SUCCESS,
  HEATMAPS_REQUEST_FAILURE
} from "actions/statistics/heatmaps-actions";
import {RESET_DATA} from "actions/jobs-tabs-actions";

const INITIAL_STATE = { list: [], error: null, loading: false };

const HeatmapsReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case HEATMAPS_REQUEST:
      return {...state, list: [], error: null, loading: true};
    case HEATMAPS_REQUEST_SUCCESS:
      return {...state, list: action.payload, loading: false};
    case HEATMAPS_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};
    case RESET_DATA:
      return {...state, list: []};

    default:
      return state;
  }
};

export default HeatmapsReducer
