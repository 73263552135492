import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from 'redux-form';
import { Row, Col, ButtonToolbar } from 'react-bootstrap'
import { Debounce } from 'react-throttle';
import PropTypes from "prop-types";

import { RenderSelect, RenderSearchField } from './render-field'
import { SEARCH_FORM } from "helpers/FormNames";
import { initializeSearchForm } from "actions/search-actions";

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.resetForm = this.resetForm.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleSubmit = this.props.handleSubmit;
    this.state = {
      advanced: false
    }
  }

  componentWillMount() {
    const { initialParams, dispatch } = this.props;
    dispatch(initializeSearchForm(initialParams));
  }

  resetForm() {
    const { initialParams, reset, onSubmit } = this.props;
    reset();
    onSubmit(initialParams);
  }

  toggleSearch() {
    this.setState(prev => ({
      advanced: !prev.advanced
    }));
  }

  simpleSearch() {
    return (
      <div className="simple-search-form">
        <Debounce time="400" handler="onChange">
          <form onSubmit={ this.handleSubmit } onChange={this.handleSubmit}>
            <Field name="query" component={RenderSearchField} type="text" placeholder="Enter keyword"/>
          </form>
        </Debounce>
      </div>
    )
  }

  advancedSearch(pristine, submitting, children) {
    const { filters } = this.props;
    return (
      <div className="advanced-search-form">
        <form onSubmit={ this.handleSubmit }>
          <Row>
            <Col sm={2}><h3>Search in</h3></Col>
            <Col sm={2}>
              <Field name="search_by" component={RenderSelect}
                     option="All fields" options={filters.search_fields} type="text" />
            </Col>
            <Col sm={4}>
              <Field name="query" component={RenderSearchField} type="text" placeholder="Enter keyword"/>
            </Col>
          </Row>
          <Row className="filters">
          {children}
          </Row>
          <Row>
            <Col sm={12}>
              <ButtonToolbar className="pull-right">
                <button className="btn btn-success" type="submit" disabled={submitting}>Apply</button>
                <button className="btn btn-danger" type="button"
                        disabled={pristine || submitting}
                        onClick={this.resetForm}>Clear filters</button>
              </ButtonToolbar>
            </Col>
          </Row>
        </form>
      </div>
    )
  }

  render() {
    const { pristine, submitting, children, filters } = this.props;
    const { advanced } = this.state;
    let form = this.simpleSearch();
    if(advanced) form = this.advancedSearch(pristine, submitting, children);
    return (
      <div>
        {filters && <div className="margin-top-30 toggle-search-link pull-right">
          <a onClick={() => this.toggleSearch()}>{advanced ? 'Hide advanced search' : 'Advanced search'}</a>
        </div>}
        {form}
      </div>

    )
  }
}

SearchForm.defaultProps = {
  initialParams: {}
};

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialParams: PropTypes.object
};

SearchForm = reduxForm({
  form: SEARCH_FORM,
  enableReinitialize : true
})(SearchForm);

SearchForm = connect(
  state => ({
    initialValues: state.search.initialParams,
  })
)(SearchForm);

export default SearchForm


