import {reset} from 'redux-form';

import { NOTE_FORM_NAME } from "../helpers/FormNames";
import NotesApi from 'api/NotesApi'

export const NOTES_REQUEST = 'NOTES_REQUEST';
export const NOTES_REQUEST_SUCCESS = 'NOTES_REQUEST_SUCCESS';
export const NOTES_REQUEST_FAILURE = 'NOTES_REQUEST_FAILURE';

export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_FAILURE = 'CREATE_NOTE_FAILURE';

export const NOTE_TYPES_REQUEST = 'NOTE_TYPES_REQUEST';
export const NOTE_TYPES_REQUEST_SUCCESS = 'NOTE_TYPES_REQUEST_SUCCESS';
export const NOTE_TYPES_REQUEST_FAILURE = 'NOTE_TYPES_REQUEST_FAILURE';


const notesRequest = () => {
  return {
    type: NOTES_REQUEST
  }
};

const notesRequestSuccess = (data) => {
  return {
    type: NOTES_REQUEST_SUCCESS,
    payload: data
  };
};

const notesRequestFailure = (error) => {
  return {
    type: NOTES_REQUEST_FAILURE,
    payload: error
  };
};

const createNoteRequest = () => {
  return {
    type: CREATE_NOTE_REQUEST
  }
};

const createNoteSuccess = (data) => {
  return {
    type: CREATE_NOTE_SUCCESS,
    payload: data
  };
};

const createNoteFailure = (error) => {
  return {
    type: CREATE_NOTE_FAILURE,
    payload: error
  };
};

const noteTypesRequest = () => {
  return {
    type: NOTE_TYPES_REQUEST
  }
};

const noteTypesRequestSuccess = (data) => {
  return {
    type: NOTE_TYPES_REQUEST_SUCCESS,
    payload: data
  };
};

const noteTypesRequestFailure = (error) => {
  return {
    type: NOTE_TYPES_REQUEST_FAILURE,
    payload: error
  };
};

const notesApi = new NotesApi();

export const fetchNotes = (type='job', id) => {
  return (dispatch) => {
    dispatch(notesRequest());
    return notesApi.index(type, id)
      .then(function(data) {
        dispatch(notesRequestSuccess(data));
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(notesRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const fetchNoteTypes = () => {
  return (dispatch) => {
    dispatch(noteTypesRequest());
    return notesApi.noteTypes()
      .then(function(data) {
        dispatch(noteTypesRequestSuccess(data));
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(noteTypesRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const addNote = (noteParams, callback) => {
  return (dispatch) => {
    dispatch(createNoteRequest());
    return notesApi.create(noteParams)
      .then((data) => {
        dispatch(createNoteSuccess(data));
        dispatch(reset(NOTE_FORM_NAME));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(createNoteFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
