import React from "react";
import PropTypes from 'prop-types';
import { Row, Col } from "react-bootstrap";

import Wrapper from "statistics/wrapper"
import PieChart from "statistics/pie-chart"

class Distributions extends React.Component {
  constructor(props) {
    super(props);
  }

  chartWrapper(chart) {
    return (
      <Col md={6}>
        <div className="bordered margin-bottom-30">
          {chart}
        </div>
      </Col>
    )
  }

  render() {
    const { distributions } = this.props;
    const distributionsData = distributions.data;
    return (
      <Wrapper loading={distributions.loading}>
        <Row>
          {this.chartWrapper(
            <PieChart chartData={distributionsData.building_types} title="Building types" />
          )}
          {this.chartWrapper(
            <PieChart chartData={distributionsData.roof_types} title="Roof types" />
          )}
          {this.chartWrapper(
            <PieChart chartData={distributionsData.job_types} title="Job types" />
          )}
          {this.chartWrapper(
            <PieChart chartData={distributionsData.stories_counts} title="Number of Stories" />
          )}
          {this.chartWrapper(
            <PieChart chartData={distributionsData.referral_resources} title="Referral Sources" />
          )}
          {this.chartWrapper(
            <PieChart chartData={distributionsData.client_positions} title="Client positions" />
          )}
        </Row>
      </Wrapper>
      )
  }
}

Distributions.defaultProps = {
  distributions: {}
};

Distributions.propTypes = {
  distributions: PropTypes.object
};

export default Distributions;
