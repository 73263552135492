import { connect } from 'react-redux';
import GeneralSearch from 'presentational/general-search-bar/search-component';
import { fetchSearchBarJobs } from "actions/general-search-bar-actions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchBarJobs: (params, callback) => {
      dispatch(fetchSearchBarJobs(params, callback))
    }
  }
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSearch);
