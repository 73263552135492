export const validate = values => {
  const required = 'Required';

  const errors = {};
  if (!values.appointmentDate) {
    errors.appointmentDate = required
  }
  if (!values.timeFrom) {
    errors.timeFrom = required
  }
  if (!values.timeTo ) {
    errors.timeTo = required
  }

  return errors
};