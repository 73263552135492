import React from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';

import { OverflowScroll } from 'shared/oferflow-scroll';
import { calculateFullHeight } from 'helpers/ScreenHelpers';
import {
  parseDate,
  parseTime,
  CALENDAR_START_TIME,
  DAY_END
} from 'helpers/DateTimeHelpers';
import RemoveUnavailableDateModal from 'shared/remove-unavailable-date-modal';
import JobDetailsModal from 'shared/job-details-modal';
import { formatEstimates } from 'helpers/Estimates';
import { isJobEstimated } from 'helpers/Job';

BigCalendar.setLocalizer(
  BigCalendar.momentLocalizer(moment)
);

class Calendar extends React.Component {
  constructor (props) {
    super(props);
    this.eventStyles = this.eventStyles.bind(this);
    this.onSelectSlot = this.onSelectSlot.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.onDoubleClickEvent = this.onDoubleClickEvent.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
    this.state = {
      style: {
        minHeight: '100%'
      }
    }
  }

  componentDidMount() {
   this.setState({style: {minHeight: `${this.calculateHeight()}px`}})
  }

  calculateHeight() {
    return calculateFullHeight(this.refs.calendar)
  }

  parseUserNotAvailableTitle(data) {
    return `${data.user && data.user.name}, NOT available, ${data.note}`
  }

  eventStyles(event) {
    return {
      style: {
        color:      'black',
        background: event.user && event.user.color,
        fontSize:   'small',
        opacity:    isJobEstimated(event) ? '0.25' : '1'
      }
    }
  }

  formatNotAvailableList(notAvailableList) {
    return notAvailableList.map((item) => {
      return {
        ...item,
        title: this.parseUserNotAvailableTitle(item),
        start: parseDate(item.start_date),
        end: parseDate(item.end_date),
        selectable: false
      }
    })
  }

  calendarEvents(estimates, notAvailableList) {
    return formatEstimates(estimates).concat(this.formatNotAvailableList(notAvailableList));
  }

  onSelectSlot(slotInfo) {
    this.props.onSelectSlot(slotInfo);
  }

  onSelectEvent(event) {
    if(event.selectable) {
      this.props.onSelectEvent(event);
      this.props.openJobDetailsModal(event);
    }
  }

  onDoubleClickEvent(event) {
    this.props.openRemoveUnavailableDateModal(event);
  };

  calendarFormats() {
    return {
      dayFormat: (date, culture , localizer) => { return localizer.format(date, 'M/DD', culture) }
    }
  }

  render() {
    const { estimates, notAvailableList, showUnavailableDateModal, showJobDetailsModal, closeJobDetailsModal,
            closeRemoveUnavailableDateModal, removeUnavailableDate, onNavigate, event } = this.props;

    const { style } = this.state;
    return (
      <OverflowScroll>
        <div ref='calendar' >
          <BigCalendar
            style={style}
            popup
            showMultiDayTimes
            defaultView='week'
            selectable
            min={parseTime(CALENDAR_START_TIME)}
            max={parseTime(DAY_END)}
            events={this.calendarEvents(estimates, notAvailableList)}
            onSelectEvent={this.onSelectEvent}
            onSelectSlot={this.onSelectSlot}
            eventPropGetter={this.eventStyles}
            onNavigate={onNavigate}
            onDoubleClickEvent={(event) => event.note && this.onDoubleClickEvent(event)}
            formats={this.calendarFormats()}
          />
          <RemoveUnavailableDateModal
            removeUnavailableDate={removeUnavailableDate}
            show={showUnavailableDateModal}
            close={closeRemoveUnavailableDateModal}
            event={event}
          />
          <JobDetailsModal
            show={showJobDetailsModal}
            close={closeJobDetailsModal}
            event={event}
          />
        </div>
      </OverflowScroll>
    )
  }
}

Calendar.defaultProps = {
  onSelectSlot: () => {},
  onSelectEvent: () => {},
  onNavigate: () => {},
  notAvailableList: []
};

Calendar.propTypes = {
  estimates: PropTypes.array.isRequired,
  notAvailableList: PropTypes.array,
  onSelectSlot: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onNavigate: PropTypes.func
};

export default Calendar;
