import React from "react";
import PropTypes from 'prop-types';

import { FaIcon } from "./fa-icon";
import AppointmentForm from './appointment-form'
import BounceBackForm from './bounceback-form'
import { renderAppointmentTime } from "helpers/DateTimeHelpers";
import { canEditEstimate } from "helpers/Permissions";
import { JobLink } from "shared/job-link"
import { selectedCurrent } from "helpers/Estimates";

export class Appointment extends React.Component {
  constructor (props) {
    super(props);
    this.onUnassign             = this.onUnassign.bind(this);
    this.onAppointment          = this.onAppointment.bind(this);
    this.toggleAppointmentForm  = this.toggleAppointmentForm.bind(this);
    this.openAppointmentForm    = this.openAppointmentForm.bind(this);
    this.closeAppointmentForm   = this.closeAppointmentForm.bind(this);
    this.openBounceBackForm     = this.openBounceBackForm.bind(this);
    this.closeBounceBackForm    = this.closeBounceBackForm.bind(this);
    this.submitAppointmentForm  = this.submitAppointmentForm.bind(this);
    this.submitBounceBackForm   = this.submitBounceBackForm.bind(this);
    this.scheduledAppointment   = this.scheduledAppointment.bind(this);
    this.unscheduledAppointment = this.unscheduledAppointment.bind(this);
    this.initialState = {
      showAppointmentForm: false,
      showBounceBackForm: false
    };
    this.state = this.initialState
  }

  componentDidMount() {
    this.mounted = true;
    const { openAppointment, selectedEstimate } = this.props;
    this.shouldOpenAppointment(openAppointment, selectedEstimate)
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const { selectedEstimate, openAppointment } = nextProps;
    if (selectedEstimate.id !== this.props.estimate.id) {
      this.setState(this.initialState);
    }
    this.shouldOpenAppointment(openAppointment, selectedEstimate)
  }

  shouldOpenAppointment(openAppointment, estimate) {
    if(openAppointment && canEditEstimate(estimate)) {
      this.openAppointmentForm()
    }
  }

  openAppointmentForm() {
    if (!this.mounted) return;
    this.setState(prev => ({
      showAppointmentForm: true
    }));
  }

  closeAppointmentForm() {
    if (!this.mounted) return;
    const { onCloseAppointmentForm } = this.props;
    onCloseAppointmentForm();
    this.setState({
      showAppointmentForm: false
    });
  }

  toggleAppointmentForm(estimate) {
    this.state.showAppointmentForm ? this.closeAppointmentForm() : this.onAppointment(estimate)
  }

  openBounceBackForm() {
    if (!this.mounted) return;
    this.setState(prev => ({
      showBounceBackForm: true,
      showAppointmentForm: false
    }));
  }

  closeBounceBackForm() {
    if (!this.mounted) return;
    const { onCloseBounceBackForm } = this.props;
    onCloseBounceBackForm();
    this.setState(prev => ({
      showBounceBackForm: !prev.showBounceBackForm
    }));
  }

  submitAppointmentForm(data) {
    this.props.setAppointment(data, this.closeAppointmentForm)
  }

  submitBounceBackForm(data) {
    this.props.unassignEstimator(data, this.closeBounceBackForm)
  }

  onUnassign(estimate) {
    if (canEditEstimate(estimate)) {
      this.props.onSelect();
      this.openBounceBackForm()
    }
  }

  onAppointment(estimate) {
    if (canEditEstimate(estimate)) {
      this.props.onSelect();
      this.openAppointmentForm()
    }
  }

  unassignLink(estimate) {
    const link = (
      <a className="text-danger" onClick={() => this.onUnassign(estimate)} title="Bounce back">
        <FaIcon size="lg" icon="minus-circle"/>
      </a>
    );
    return canEditEstimate(estimate) && link;
  }

  appointmentLink(estimate, icon = 'pencil') {
    const title = `${estimate.appointment_date ? "Edit" : "Add"} appointment`;
    const link = (
      <a className="text-muted" onClick={() => this.onAppointment(estimate)} title={title}>
        <FaIcon size="lg" icon={icon}/>
      </a>
    );
    return canEditEstimate(estimate) && link;
  }

  scheduledAppointment(estimate) {
    const { Wrapper } = this.props;
    return !Wrapper && (
      <div> { renderAppointmentTime(estimate)}
        <div className={`edit-icons pull-right ${Wrapper ? 'position-right' : ''}`}>
          <JobLink job={estimate} />
          { this.appointmentLink(estimate) }
          { this.unassignLink(estimate) }
        </div>
      </div>
    );
  }

  unscheduledAppointment(estimate) {
    const { Wrapper } = this.props;
    const link = canEditEstimate(estimate) ? <a onClick={() => this.onAppointment(estimate)}>Set appointment</a> : 'Unscheduled';
    return !Wrapper && (
      <div>
        { link }
        <div className={`edit-icons ${Wrapper ? 'position-right' : ''} pull-right`}>
          <JobLink job={estimate} />
          { Wrapper && this.appointmentLink(estimate, 'clock-o')}
          { this.unassignLink(estimate) }
        </div>
      </div>
    );
  }

  showData(estimate) {
    return estimate.appointment_date ? this.scheduledAppointment(estimate) : this.unscheduledAppointment(estimate)
  }

  render() {
    const { estimate, Wrapper, timeSlotsData, selectedEstimate } = this.props;
    const { showAppointmentForm, showBounceBackForm } = this.state;
    const showActionButtons = !(showAppointmentForm || showBounceBackForm);
    const active = selectedCurrent(selectedEstimate, estimate);
    const appointmentForm = <AppointmentForm form={`appointment_form_${estimate.id}`}
                                             onSubmit={this.submitAppointmentForm}
                                             estimate={estimate}
                                             timeSlotsData={timeSlotsData}
                                             onClose={this.closeAppointmentForm}/>;
    const bounceBackForm = <BounceBackForm form={`bounce_back_form_${estimate.id}`}
                                             onSubmit={this.submitBounceBackForm}
                                             estimate={estimate}
                                             onClose={this.closeBounceBackForm}/>;
    const toShow = active && (showAppointmentForm && appointmentForm ||
                              showBounceBackForm && bounceBackForm) ||
                              this.showData(estimate);
    return (
      <div>
        { Wrapper && <Wrapper active={active}
                              estimate={estimate}
                              showActionButtons={showActionButtons}
                              onAppointmentClick={()=> this.onAppointment(estimate)}
                              onBounceBackClick={()=> this.onUnassign(estimate)}
                     >
          { toShow }
        </Wrapper> || toShow }
      </div>
    )
  }
}

Appointment.defaultProps = {
  onCloseBounceBackForm: () => {},
  onCloseAppointmentForm: () => {}
};

Appointment.propTypes = {
  openAppointment: PropTypes.bool,
  Wrapper: PropTypes.func,
  estimate: PropTypes.object.isRequired,
  selectedEstimate: PropTypes.object,
  setAppointment: PropTypes.func.isRequired,
  unassignEstimator: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCloseBounceBackForm: PropTypes.func.isRequired,
  onCloseAppointmentForm: PropTypes.func.isRequired
};
