import React from "react";
import { Field, FieldArray, FormSection } from "redux-form";
import { Row, Col, Well } from "react-bootstrap";

import { RenderInputGroup, RenderRadio, RenderCheckbox, RenderControlledCheckbox, RenderSelect } from "shared/render-field";
import AddressInputs from "shared/address-inputs";
import { normalizePhone } from "helpers/FormHelpers";

const PRIMARY_CONTACT_FORM_OPTIONS = ["phone", "email", "text"];

export default class ContactInfo extends React.Component {
  constructor(props) {
    super(props);
    this.handleCopyClientAddressToJob = this.handleCopyClientAddressToJob.bind(this);
  }

  handleCopyClientAddressToJob() {
    const { copyClientAddressToJob, disableJobAddressForm } = this.props;
    if (disableJobAddressForm) {
      setTimeout(() => {
        copyClientAddressToJob();
      }, 50)
    }
  }

  renderPhones({ fields }) {
    return (
      <Row>
        {fields.map((phone, index) => (
          <div className="clearfix" key={index}>
            <FormSection name={phone}>
              <Col sm={8}>
                <Field
                  label="Phone"
                  name='phone'
                  component={RenderInputGroup} type="text"
                  normalize={normalizePhone}
                  placeholder="(___) ___-____"
                  action={ (fields.length > 1) && (() => fields.remove(index)) }
                />
                {addMoreFieldLink(fields, index, 'Add Another Phone')}
              </Col>
            </FormSection>
            <Col sm={4}>
             <RenderRadio label="Primary" value={index + ''} name="primary_phone" />
            </Col>
          </div>
        ))}
      </Row>
    )
  }

  renderEmails({ fields }) {
    return (
      <Row>
        {fields.map((phone, index) => (
          <div className="clearfix" key={index}>
            <FormSection name={phone}>
              <Col sm={8}>
                <Field label="Email" name='email' component={RenderInputGroup} type="email"
                       action={ (fields.length > 1) && (() => fields.remove(index)) }/>
                {addMoreFieldLink(fields, index, 'Add Another Email')}
              </Col>
            </FormSection>
            <Col sm={4}>
              <RenderRadio label="Primary" value={index + ''} name="primary_email" />
            </Col>
          </div>
        ))}
      </Row>
    )
  }

  render() {
    const { disableFields } = this.props;
    return (
      <div>
        <Well>
          {/*<h3>Contact info</h3>*/}
          <Row>
            <Col md={6}>
              <FieldArray name="phones" component={this.renderPhones} />
            </Col>
            <Col md={6}>
              <FieldArray name="emails" component={this.renderEmails} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                label="Primary contact form"
                name="primary_contact_form"
                component={RenderSelect}
                options={PRIMARY_CONTACT_FORM_OPTIONS}
              />
            </Col>
          </Row>
          <AddressInputs
            type="contact"
            loadAddress={this.props.loadAddress}
            onFieldChange={() => this.handleCopyClientAddressToJob()}
            onAddressSelect={() => this.handleCopyClientAddressToJob()}
          />
        </Well>
      </div>
    )
  }
}

const addMoreFieldLink = (fields, index, linkTitle) => {
  const link = (
    <div className="form-link">
      <a onClick={() => fields.push({})}><small>+ {linkTitle}</small></a>
    </div>
  );
  return (fields.length - 1 === index) && link;
};
