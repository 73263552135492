import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap'

import { jobPath, clientPath } from "helpers/PathHelpers";

class JobBreadcrumb extends React.Component {
  render() {
    const { job } = this.props;
    const originalJobLink = (
      <Breadcrumb.Item href={jobPath(job.job_id)}>
        Original Job
      </Breadcrumb.Item>
    );
    const currentName = job.callback ? 'Callback' : 'Job';
    return (
      <Breadcrumb>
        <Breadcrumb.Item href="/clients">
          Clients
        </Breadcrumb.Item>
        <Breadcrumb.Item href={clientPath(job.client && job.client.id)}>
          { job.client && job.client.name }
        </Breadcrumb.Item>
        {job.callback && originalJobLink}
        <Breadcrumb.Item active>
          {currentName}
        </Breadcrumb.Item>
      </Breadcrumb>
    )
  }
}

JobBreadcrumb.propTypes = {
  job: PropTypes.object
};

export default JobBreadcrumb

