import { LOAD_JOB_TO_EDIT_FORM, RESET_JOB_EDIT_FORM, UPDATE_JOB_REQUEST,
         UPDATE_JOB_REQUEST_SUCCESS, UPDATE_JOB_REQUEST_FAILURE
       } from "actions/jobs-actions"
import { LOAD_ADDRESS } from 'actions/addresses';

const INITIAL_STATE = { data: {}, showModal: false, loading: false };

const EditJobReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case LOAD_JOB_TO_EDIT_FORM:
      return {...state, data: action.payload,  showModal: true, loading: false };
    case RESET_JOB_EDIT_FORM:
      return {...state, data: {}, showModal: false, loading: false };

    case UPDATE_JOB_REQUEST:
      return {...state, loading: true };
    case UPDATE_JOB_REQUEST_SUCCESS:
      return {...state, data: {}, showModal: false, loading: false };
    case UPDATE_JOB_REQUEST_FAILURE:
      return {...state, showModal: true, loading: false};

    case LOAD_ADDRESS:
      return {...state, data: action.payload};

    default:
      return state;
  }
};
export default EditJobReducer