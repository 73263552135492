import React from "react";
import SweetAlert from 'sweetalert2-react';

const ArchiveClientAlert = props => {
  const type = props.client.archived ? 'unarchive' : 'archive';
  return (
    <SweetAlert
      show={props.show}
      title={`Are you sure you want to ${type} ${props.client.name}?`}
      type="warning"
      onConfirm={() => props.onConfirm()}
      onCancel={() => props.onCancel()}
      confirmButtonText="Yes"
      cancelButtonText="No"
      confirmButtonClass="btn btn-lg btn-primary"
      cancelButtonClass="btn btn-lg  btn-danger"
      buttonsStyling={false}
      showCancelButton
    />
  )
}
export default ArchiveClientAlert;

