import JobApi from "api/JobApi";
import { formatJobsToAutoComplete } from "helpers/SearchBar";

export const SEARCH_BAR_JOBS_REQUEST = 'SEARCH_BAR_JOBS_REQUEST';
export const SEARCH_BAR_JOBS_REQUEST_SUCCESS = 'SEARCH_BAR_JOBS_REQUEST_SUCCESS';
export const SEARCH_BAR_JOBS_REQUEST_FAILURE = 'SEARCH_BAR_JOBS_REQUEST_FAILURE';
export const RESET_SEARCH_BAR_JOBS_LIST = 'RESET_SEARCH_BAR_JOBS_LIST';

const searchBarJobsRequest = () => {
  return {
    type: SEARCH_BAR_JOBS_REQUEST,
  }
};

const searchBarJobsRequestSuccess = (jobs) => {
  return {
    type: SEARCH_BAR_JOBS_REQUEST_SUCCESS,
    payload: jobs
  }
};

const searchBarJobsRequestFailure = (error) => {
  return {
    type: SEARCH_BAR_JOBS_REQUEST_FAILURE,
    payload: error
  }
};


const jobsApi = new JobApi();

export const fetchSearchBarJobs = (query, callback) => {
  return (dispatch) => {
    dispatch(searchBarJobsRequest());
    return jobsApi.index({query})
      .done((data) => {
        dispatch(searchBarJobsRequestSuccess(data));
        if (typeof callback === 'function') callback(formatJobsToAutoComplete(data.jobs));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(searchBarJobsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
