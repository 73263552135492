import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import { EstimatorsReducer } from "./estimators-reducer";
import { EstimatesReducer } from "./estimates-reducers";
import { UserUnavailableReducer } from "./user-unavailable-reducer";
import {
  UPDATE_CALENDAR, RESET_UPDATE_CALENDAR,
  SELECT_ESTIMATOR, CLOSE_ESTIMATES_PANEL, SELECT_TIME_SLOTS,
  CLEAR_TIME_SLOTS_DATA, SELECT_EVENT, RESET_SELECTED_EVENT,
  CLOSE_REMOVE_UNAVALIABLE_DATE_MODAL, OPEN_REMOVE_UNAVALIABLE_DATE_MODAL,
  OPEN_JOB_DETAILS_MODAL, CLOSE_JOB_DETAILS_MODAL
} from "actions/calendar-actions";
import { UNASSIGNED_ESTIMATES } from "helpers/AppConstants";


const INITIAL_STATE = {
  calendarUpdated: false,
  showUnavailableDateModal: false,
  showJobDetailsModal: false,
  selectedNavItem: UNASSIGNED_ESTIMATES,
  focusUnassigned: false,
  timeSlotsData: {},
  selectedEvent: {},
  sizes: {
    showEstimatesPanel: true,
    calendarWidth: 7,
    estimatorsPanelWidth: 2,
    estimatesPanelWidth: 3
  }
};

export const CalendarReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case UPDATE_CALENDAR:
      return { ...state, calendarUpdated: true };
    case RESET_UPDATE_CALENDAR:
      return { ...state, calendarUpdated: false };
    case SELECT_ESTIMATOR:
      return { ...state, sizes: {...state.sizes, showEstimatesPanel: true, calendarWidth: 7 },
                        selectedNavItem: action.payload };
    case SELECT_EVENT:
      return { ...state, selectedEvent: action.payload };
    case RESET_SELECTED_EVENT:
      return { ...state, selectedEvent: {} };
    case CLOSE_ESTIMATES_PANEL:
      return { ...state, sizes: {...state.sizes, showEstimatesPanel: false, calendarWidth: 10 } };
    case SELECT_TIME_SLOTS:
      return { ...state, focusUnassigned: true, timeSlotsData: action.payload };
    case CLEAR_TIME_SLOTS_DATA:
      return { ...state, focusUnassigned: false, timeSlotsData: {} };
    case CLOSE_REMOVE_UNAVALIABLE_DATE_MODAL:
      return { ...state, showUnavailableDateModal: false };
    case OPEN_REMOVE_UNAVALIABLE_DATE_MODAL:
      return { ...state, showUnavailableDateModal: true, event: action.payload };
    case OPEN_JOB_DETAILS_MODAL:
      return { ...state, showJobDetailsModal: true, event: action.payload };
    case CLOSE_JOB_DETAILS_MODAL:
      return { ...state, showJobDetailsModal: false };
    default:
      return state;
  }
};

const CalendarReducers = combineReducers({
  calendar: CalendarReducer,
  estimates: EstimatesReducer,
  estimators: EstimatorsReducer,
  userUnavailable: UserUnavailableReducer,
  form: reduxFormReducer
});

export default CalendarReducers
