import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";

import Wrapper from "statistics/wrapper"
import {
  JOB_STATUS_UNASSIGNED, JOB_STATUS_ASSIGNED, JOB_STATUS_SCHEDULED, JOB_STATUS_ESTIMATED,
  JOB_STATUS_SOLD, JOB_STATUS_LOST, CALLBACK_STATUS_OPEN, CALLBACK_STATUS_CLOSED
} from "helpers/AppConstants";

class StatusChart extends React.Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.setData = this.setData.bind(this);
    this.formatSeries = this.formatSeries.bind(this);
  }

  componentDidMount() {
    const { chartData } = this.props;
    this.renderChart();
    this.setData(chartData);
  }

  componentWillUpdate(nextProps) {
    const { chartData } = nextProps;
    if (chartData !== this.props.chartData) this.setData(chartData);
  }

  getCategories() {
    return [JOB_STATUS_UNASSIGNED, JOB_STATUS_ASSIGNED, JOB_STATUS_SCHEDULED, JOB_STATUS_ESTIMATED,
      JOB_STATUS_SOLD, JOB_STATUS_LOST, CALLBACK_STATUS_OPEN, CALLBACK_STATUS_CLOSED]
  }

  formatSeries(chartData) {
    const series = this.initialSeries();
    this.getCategories().forEach((category) => {
      series[0].data.push(this.getCounts(chartData, category))
    });
    return series;
  }

  getCounts(data, key) {
    return data[key] || 0
  }

  initialSeries() {
    return [
      {
        name: 'Jobs',
        type: 'column',
        colorByPoint: true,
        data: [],
        showInLegend: true
      }
    ]
  }

  setData(chartData) {
    this.chart.update({
      series: this.formatSeries(chartData)
    })
  }

  renderChart() {
    this.chart =  Highcharts.chart(this.refs.chart, {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.getCategories()
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Jobs count'
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: this.initialSeries()
    });
  }

  render() {
    const { loading } = this.props;
    return (
      <Wrapper loading={loading}>
        <div ref="chart" />
      </Wrapper>
    )
  }
}

StatusChart.defaultProps = {
  chartData: {},
  loading: false
};

StatusChart.propTypes = {
  chartData: PropTypes.object,
  loading: PropTypes.bool
};

export default StatusChart;
