import {reset} from 'redux-form';

import DocumentApi from "api/JobDocumentApi";
import { documentFormData } from "helpers/Document";

export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_REQUEST_SUCCESS = 'DOCUMENTS_REQUEST_SUCCESS';
export const DOCUMENTS_REQUEST_FAILURE = 'DOCUMENTS_REQUEST_FAILURE';

export const DROP_DOCUMENTS = "DROP_DOCUMENTS";

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';


export const documentsRequest = () => {
  return {
    type: DOCUMENTS_REQUEST
  }
};

export const documentsRequestSuccess = (documents) => {
  return {
    type: DOCUMENTS_REQUEST_SUCCESS,
    payload: documents
  }
};

export const documentsRequestFailure = (error) => {
  return {
    type: DOCUMENTS_REQUEST_FAILURE,
    payload: error
  }
};

export const dropDocuments = (documents) => {
  return {
    type: DROP_DOCUMENTS,
    payload: documents
  }
};

const createDocumentRequest = () => {
  return {
    type: CREATE_DOCUMENT_REQUEST
  }
};

const createDocumentSuccess = (document) => {
  return {
    type: CREATE_DOCUMENT_SUCCESS,
    payload: document
  };
};

const createDocumentFailure = (error) => {
  return {
    type: CREATE_DOCUMENT_FAILURE,
    payload: error
  };
};

const deleteDocumentRequest = () => {
  return {
    type: DELETE_DOCUMENT_REQUEST
  }
};

const deleteDocumentSuccess = (id) => {
  return {
    type: DELETE_DOCUMENT_SUCCESS,
    payload: id
  };
};

const deleteDocumentFailure = (error) => {
  return {
    type: DELETE_DOCUMENT_FAILURE,
    payload: error
  };
};


const documentApi = new DocumentApi();

export const fetchDocuments = (jobId) => {
  return (dispatch) => {
    dispatch(documentsRequest());
    return documentApi.documents(jobId)
      .then((data) => {
        dispatch(documentsRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(documentsRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const addDocument = (documentParams, callback) => {
  const dataToSend = documentFormData(documentParams);
  return (dispatch) => {
    dispatch(createDocumentRequest());
    return documentApi.addDocument(documentParams.jobId, dataToSend)
      .then((data) => {
        dispatch(createDocumentSuccess(data));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(createDocumentFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};


export const deleteDocument = (documentId, callback) => {
  return (dispatch) => {
    dispatch(deleteDocumentRequest());
    return documentApi.deleteDocument(documentId)
      .then((data) => {
        dispatch(deleteDocumentSuccess(documentId));
        if (typeof callback === 'function') callback(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(deleteDocumentFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

