import React from 'react';
import { render } from 'react-dom';
import Modal from 'react-responsive-modal';
import { Button, ButtonToolbar } from 'react-bootstrap';

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

export default class RemoveUnavailableDateModal extends React.Component {

  handleClickRemoveButton = () => {
    const { removeUnavailableDate, event } = this.props;
    removeUnavailableDate(event);
  };

  render() {
    const { show, close, event } = this.props;
    return (
      <div style={styles}>
        <ButtonToolbar className="pull-right">
          <Button onClick={close}>Close</Button>
        </ButtonToolbar>
        {
          event &&
          <Modal open={show} onClose={close} center>
            <div>
              <h3>{event.title}</h3>
              <p>From {event.start_date} to {event.end_date}</p>
              <p>Are you sure that you want to remove ?</p>
            </div>
            <Button className="pull-right" onClick={this.handleClickRemoveButton}>Remove</Button>
          </Modal>
        }
      </div>
    );
  }
}
