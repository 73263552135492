import React from 'react';
import { Table } from 'react-bootstrap'
import PropTypes from 'prop-types';

import { redirectTo, jobPath } from "helpers/PathHelpers";
import { FaIcon } from "shared/fa-icon"
import { addressToString } from 'actions/addresses';
import { selectOptionOrOther } from "helpers/ViewHelpers";
import Spinner from 'shared/spinner';
import { Pagination } from "shared/pagination";
import { Sorting } from "shared/sorting";
import {
  CALLBACK, JOB, CLIENT_NAME, ROOF_TYPE, BUILDING_TYPE,
  ADDRESS, CREATED_AT, ESTIMATOR, TYPE, STATUS
} from "helpers/AppConstants";

import { findItemById } from "helpers/ListHelpers";

class JobsTable extends React.Component {
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
    this.renderSortComponent = this.renderSortComponent.bind(this)
  }

  matchMaterialBrandName(materialBrandId) {
    const { materialBrands } = this.props;
    const brand = findItemById(materialBrands, materialBrandId);

    return brand.name
  }

  matchMaterialBrandSeriasName(materialBrandId, materialBrandSeriesId) {
    const { materialBrands } = this.props;
    const brand = findItemById(materialBrands, materialBrandId);
    const brandSeries = [...brand.brand_items]
    const bs = findItemById(brandSeries, materialBrandSeriesId);
    return bs.name
  }

  renderMaterialBrands(sale) {
    return (
      <span>
        {(!sale || !sale.materials) && <p>N/A</p>}
        {sale  && sale.materials && sale.materials.map((material, i) => (
          <p>
            {this.matchMaterialBrandName(material.material_brand_id)}
          </p>
        ))}
      </span>
    )
  }

  renderColor(sale) {
    return (
      <span>
        {(!sale || !sale.materials) && <p>N/A</p>}
        {sale && sale.materials && sale.materials.map((material, i) => (
          <p>
            {material.color}
          </p>
        ))}
      </span>
    )
  }

  renderMaterialBrandSeries(sale) {
    return (
      <span>
        {(!sale || !sale.materials) && <p>N/A</p>}
        {sale  && sale.materials && sale.materials.map((material, i) => (
          <p>
            {this.matchMaterialBrandName(material.material_brand_id)}->{this.matchMaterialBrandSeriasName(material.material_brand_id, material.brand_item_id)}
          </p>
        ))}
      </span>
    )
  }

  renderRow(job) {
    const { loadJobToEditForm } = this.props;
    return (
      <tr key={job.id} className="pointer-cursor">
        <td onClick={() => redirectTo(jobPath(job.id))}>{ job.client && job.client.name }</td>
        <td onClick={() => redirectTo(jobPath(job.id))}>{ addressToString(job.address) }</td>
        <td onClick={() => redirectTo(jobPath(job.id))}>{ selectOptionOrOther('roof_type', job) }</td>
        <td onClick={() => redirectTo(jobPath(job.id))}>{ this.renderMaterialBrands(job.sale) }</td>
        <td onClick={() => redirectTo(jobPath(job.id))}>{ this.renderMaterialBrandSeries(job.sale) }</td>
        <td onClick={() => redirectTo(jobPath(job.id))}>{ this.renderColor(job.sale) }</td>
        <td>
          <a onClick={() => loadJobToEditForm(job)} className="text-muted"><FaIcon icon="pencil"/></a>
        </td>
      </tr>
    )
  }

  renderBody(jobs) {
    return (
      jobs.map((job)=> this.renderRow(job))
    )
  }

  renderSortComponent(title, sortBy) {
    const { onTableSort, search } = this.props;
    return(
      <Sorting
        title={title}
        onClick={onTableSort}
        sortBy={sortBy}
        orderBy={search.sort.orderBy}
        selectedSortKey={search.sort.sortBy}
      />
    )
  }

  render() {
    const { jobs, onPageChange } = this.props;
    return (
      <div>
        <Table striped bordered condensed hover>
          <thead>
          <tr>
            <th className="col-md-2">{this.renderSortComponent("Client's name", CLIENT_NAME)}</th>
            <th className="col-md-3">{this.renderSortComponent("Address", ADDRESS)}</th>
            <th className="col-md-2">{this.renderSortComponent("Roof type", ROOF_TYPE)}</th>
            <th className="col-md-2">Brand(s)</th>
            <th className="col-md-2">Brand Series(s)</th>
            <th className="col-md-2">Color</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {!jobs.loading && this.renderBody(jobs.list)}
          </tbody>
        </Table>
        <Spinner active={jobs.loading} search><span>Loading...</span></Spinner>
        <Pagination hide={jobs.loading} paginationData={jobs.meta} onPageChange={onPageChange} />
      </div>
    )
  }
}

JobsTable.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onTableSort: PropTypes.func.isRequired,
  loadJobToEditForm: PropTypes.func.isRequired,
  jobs: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired
};

export default JobsTable
