import { createStore, compose } from "redux"

import ClientInfoReducers from "reducers/client-info-reducers"
import configureMiddleware from "store/configure-middleware";

const configureClientInfoStore = preloadedState => {
  return createStore(
    ClientInfoReducers,
    preloadedState,
    compose(
      configureMiddleware()
    )
  );
};

export default configureClientInfoStore
