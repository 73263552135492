import React from "react";
import PropTypes from 'prop-types';

import Spinner from 'shared/spinner'
import { OverflowScroll } from "shared/oferflow-scroll";
import NoteForm from "./note-form";
import NoteList from "./note-list";

class NotesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addNote = this.addNote.bind(this)
  }

  componentWillMount() {
    const { fetchNotes, fetchNoteTypes, id, type } = this.props;
    if (id) {
      fetchNotes(type, id);
    }
    fetchNoteTypes();
  }

  addNote(data = {}) {
    const { addNote, id, type } = this.props;
    const dataToSend = {...data, resource_type: type, resource_id: id };
    addNote(dataToSend)
  }

  render() {
    const { notesData, isJobPage } = this.props;
    const noteTypes = notesData.noteTypes.list;
    const notes = notesData.notes.list;
    const notesList = (
      <div className="margin-top-10">
        <NoteForm onSubmit={this.addNote} noteTypes={noteTypes} />
        <hr/>
        <NoteList notes={notes} />
      </div>
    );
    if (!isJobPage) {
      return (
        <OverflowScroll>
          {notesList}
        </OverflowScroll>
      )
    }
    return notesList
  }
}

NotesComponent.defaultProps = {
  addNote: () => {},
  fetchNotes: () => {},
  fetchNoteTypes: () => {},
};

NotesComponent.propTypes = {
  id: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  addNote: PropTypes.func.isRequired,
  fetchNotes: PropTypes.func.isRequired,
  fetchNoteTypes: PropTypes.func.isRequired,
};
export default NotesComponent
