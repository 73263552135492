import React from "react";
import PropTypes from 'prop-types';
import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";

import { renderAppointmentTime } from "helpers/DateTimeHelpers";
import { jobPath } from "helpers/PathHelpers";
import { canEditEstimate } from "helpers/Permissions";

export const EstimateRow = (props) => {
  const { estimate, active, showActionButtons, onAppointmentClick, onBounceBackClick } = props;
  const linkName = estimate.callback ? 'Callback' : 'Job';
  const disableButtons = !canEditEstimate(estimate);
  return (
    <div className="estimate padding-left-10 relative-element">
      <div className={`bottom-divider padding-bottom-10 estimate-row padding-top-10 padding-h-5 ${active ? 'active-element' : ''}`}>
        <div className="text">
          <span className={`bolder-text ${estimate.appointment_date ? 'text-success' : 'text-warning'}`}>{estimate.client.name}</span>
          <br/>
          <span className="small">{estimate.address.city}, </span>
          <span className="small">{estimate.address.street} </span>
          <br/>
          <span className="small"><b>{renderAppointmentTime(estimate)}</b></span>
          { props.children }
        </div>
      </div>
      {
        showActionButtons && (
          <div className="actions">
            <ButtonToolbar>
              <ButtonGroup bsSize="small">
                <Button href={jobPath(estimate.id)}>{linkName}</Button>
                <Button disabled={disableButtons} onClick={onAppointmentClick}>Edit</Button>
                <Button disabled={disableButtons} onClick={onBounceBackClick}>Bounce Back</Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>
        )
      }
    </div>
  )
};

EstimateRow.defaultProps = {
  onAppointmentClick: () => {},
  onBounceBackClick: () => {},
  active: false,
  showActionButtons: false
};

EstimateRow.propTypes = {
  estimate: PropTypes.object,
  onAppointmentClick: PropTypes.func,
  onBounceBackClick: PropTypes.func,
  active: PropTypes.bool,
  showActionButtons: PropTypes.bool
};
