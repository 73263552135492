import { jobToString } from "helpers/Job";

const AUTOCOMPLETE_LIMIT = 20

export const formatJobsToAutoComplete = (jobs) => {
  setAutocompleteMaxLength(jobs);
  return jobs.map((job) => {
    return {
      ...job,
      name: jobToString(job)
    }
  })
};

export const setAutocompleteMaxLength = (arr) => {
  if (arr.length > AUTOCOMPLETE_LIMIT) {
    arr.length = AUTOCOMPLETE_LIMIT
  }
};
