import React from "react";
import { Table } from 'react-bootstrap'

import AssignEstimatorForm from './assign-estimator-form'
import { unassignedEstimates } from "helpers/Estimates";

class UnassignedEstimates extends React.Component {
  constructor (props) {
    super(props);
    this.renderTableRow = this.renderTableRow.bind(this)
  }

  renderTableRow(estimate, estimators, submit) {
    const { selectEstimate, selectedEstimate } =  this.props
    return (
      <tr key={estimate.id}>
        <td>{ estimate.client && estimate.client.name }</td>
        <td>{ estimate.address && estimate.address.city }</td>
        <td>{ estimate.created_at }</td>
        <td>{ estimate.requested_estimator && estimate.requested_estimator.name }</td>
        <td>
          <AssignEstimatorForm
            form={`assign_estimator_${estimate.id}`}
            estimators={estimators}
            estimate={estimate}
            onSubmit={submit}
            onSelect={selectEstimate}
            selectedEstimate={selectedEstimate}
          />
        </td>
      </tr>
    )
  }

  render() {
    const { estimators, estimates, assignEstimator } = this.props;
    return (
      <Table striped bordered condensed hover>
        <thead>
        <tr>
          <th>Client's name</th>
          <th>Location</th>
          <th>Called in</th>
          <th>Required estimator</th>
        </tr>
        </thead>
        <tbody>
        { unassignedEstimates(estimates).map((estimate) => this.renderTableRow(estimate, estimators, assignEstimator)) }
        </tbody>
      </Table>
    )
  }
}

export default UnassignedEstimates;
