import React from "react";

import SearchForm from '../shared/search-form'


class SearchClientForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onSubmit, filters } = this.props;

    return (
      <SearchForm onSubmit={onSubmit} filters={ filters }>

      </SearchForm>
    )
  }
}
export default SearchClientForm;