import React from "react";
import moment from 'moment';
import { Col, Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import { AppointmentTimeInputs } from "./appointment-time-inputs";
import { renderDatePicker } from "./render-field";

export const AppointmentFormFields = props => {
  return (
    <Row>
      <AppointmentTimeInputs appointmentTimeFrame={props.appointmentTimeFrame} />
      <Col md={12}>
        <Field label="Appointment Date" name="appointmentDate" disablePrevious component={renderDatePicker}  normalize={ (value) =>  moment(value) }  />
      </Col>
    </Row>
  )
};
