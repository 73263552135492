import ApiClient from './ApiClient';

class UserApi extends ApiClient {
  constructor() {
    super({url: 'users'});
  }

  estimators(params) {
    return super.index(params, 'estimators');
  }
}

export default UserApi;
