import { CREATE_JOB, CREATE_JOB_FAILURE, CREATE_JOB_SUCCESS,
         RESET_NEW_JOB, CHOOSE_JOB_FOR_CALLBACK, DISABLE_ADDRESS_INPUT
       } from 'actions/jobs-actions';
import { LOAD_CLIENT_TO_JOB_FORM } from 'actions/clients-actions';
import { parseResponseJobToForm } from "api/job";

import { LOAD_ADDRESS } from 'actions/addresses';


const INITIAL_STATE = { data: {}, disableSubmitButton: false, error: null, loading: false, disableJobAddressForm: false };

const NewJobReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {

    case CREATE_JOB:
      return {...state, loading: true};
    case CREATE_JOB_SUCCESS:
      return {
        ...state,
        data: parseResponseJobToForm(action.payload),
        disableSubmitButton: true, error: null, loading: false
      };
    case CREATE_JOB_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, data: {}, error:error, loading: false };
    case RESET_NEW_JOB:
      return {...state, data: {}, error: null, loading: false };
    case LOAD_CLIENT_TO_JOB_FORM:
      return {...state, data: action.payload, error: null, loading: false };
    case LOAD_ADDRESS:
      return {...state, data: action.payload, error: null, loading: false };
    case CHOOSE_JOB_FOR_CALLBACK:
      return {...state, data: action.payload, error: null, loading: false };

    case DISABLE_ADDRESS_INPUT:
      return {...state, disableJobAddressForm: action.payload };

    default:
      return state;
  }
};

export default NewJobReducer
