import React from "react";
import Dropzone from "react-dropzone";
import { ListGroup, ListGroupItem } from "react-bootstrap";

import Spinner from "shared/spinner"

class DropZone extends React.Component{
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(accepted, rejected) {
    const { addDocument, dropDocuments } = this.props;
    dropDocuments(accepted);
    setTimeout(()=>{
      const { droppedDocuments } = this.props;
      droppedDocuments.forEach(document => addDocument(document))
    })
  }

  render() {
    const { droppedDocuments, loading } = this.props;
    const dropZoneText =(
      <div>
        <h3 className="margin-top-0">Drop some files here, or click to select.</h3>
        <p className="text-center text-warning">Maximum file size 10MB</p>
      </div>
    );
    const spinner = <Spinner active={true} search><span>Loading...</span></Spinner>;
    const fileMaxSize = Math.pow(2, 20) * 10; // 10MB
    return (
      <div>
        <Dropzone
          name="attachment"
          onDrop={this.onDrop}
          className="drop-zone"
          activeClassName="drop-zone-active"
          disabledClassName="drop-zone-disabled"
          disabled={loading}
          maxSize={fileMaxSize}
        >
          {loading ? spinner : dropZoneText}
        </Dropzone>
        {droppedDocuments && Array.isArray(droppedDocuments) && (
          <ListGroup>
            { droppedDocuments.map((file, i) => <ListGroupItem key={i}>{file.name}</ListGroupItem>) }
          </ListGroup>
        )}
      </div>
    );
  }
}

export default DropZone
