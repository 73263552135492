export const TYPE_JOB = 'job';
export const TYPE_CLIENT = 'client';
export const CALLBACK_STATUS_CLOSED = 'Closed';
export const CALLBACK_STATUS_OPEN = 'Open';
export const JOB_STATUS_UNASSIGNED = 'Unassigned';
export const JOB_STATUS_ASSIGNED = 'Assigned';
export const JOB_STATUS_SCHEDULED = 'Scheduled';
export const JOB_STATUS_ESTIMATED = 'Estimated';
export const JOB_STATUS_SOLD = 'Sold';
export const JOB_STATUS_LOST = 'Lost';

// document mime type matchers
export const IMAGE_MIME_TYPE = "image/*";
export const PDF_MIME_TYPE = "application/pdf";
export const DOC_MIME_TYPE = "application/msword";
export const EXCEL_MIME_TYPE = "application/vnd.ms-excel";
export const ZIP_MIME_TYPE = "application/zip";
export const VIDEO_MIME_TYPE = "video/*";
export const AUDIO_MIME_TYPE = "audio/*";

// scroll height to make fixed note input field
export const SCROLL_HEIGHT_TO_FIX_NOTE_INPUT = 80;

// pagination
export const PAGINATION_START_PAGE = 1;
export const ELASTICSEARCH_MAX_RESPONSE = 10000;
export const PER_PAGE_DEFAULT = 10000;

// sorting
export const ORDER_ASC = "asc";
export const ORDER_DESC = "desc";
export const CLIENT_NAME = "client_name";
export const BUSINESS_NAME = "business_name";
export const ROOF_TYPE = "roof_type";
export const BUILDING_TYPE = "building_type";
export const ADDRESS = "address";
export const CREATED_AT = "created_at";
export const ESTIMATOR = "estimator";
export const TYPE = "type";
export const STATUS = "status";
// sorting end

export const ALL_ESTIMATES = "all";
export const UNASSIGNED_ESTIMATES = "unassigned";
export const ALL_ASSIGNED_ESTIMATES = "all_assigned";

// jobs page tab keys
export const JOBS_TAB = "jobs_tab";
export const JOBS_BY_REFERENCE_TAB = "jobs_by_reference";
export const HEATMAPS_TAB = "heatmaps_tab";
export const STATUSES_BY_ESTIMATOR_CHART_TAB = "statuses_by_estimator_chart_tab";
export const STATUSES_CHART_TAB = "statuses_chart_tab";
export const TIMELINE_CHART_TAB = "timeline_chart_tab";
export const DISTRIBUTIONS_CHARTS_TAB = "distributions_charts_tab";

// clients page tab keys
export const ACTIVE_CLIENTS = "active_clients";
export const ARCHIVED_CLIENTS = "archived_clients";

export const OTHERS = "Others";

export const CALLBACK = "Callback";
export const JOB =  "Job";

export const OTHER_SELECT_OPTION = "other";

export const CALLED_IN = "Called In";
export const LAST_ACTIVITY = "Last Activity";

// google API key

export const GOOGLE_API_KEY = "AIzaSyCk2lUqArTcOLs6WteLTkqU3vj7Repn4XE";
