import React from "react";
import { Provider } from "react-redux";

import configureJobsIndexStore from "app/store/configureJobsListStore";
import JobsList from "app/containers/JobsListContainer";

const store = configureJobsIndexStore();

class Jobs extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <JobsList filters={this.props.filters} jobOptions={this.props.job_options} />
      </Provider>
    )
  }
}
export default Jobs;

