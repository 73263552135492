import React from "react";
import PropTypes from 'prop-types';
import { Well, ButtonToolbar } from 'react-bootstrap'

import RenderJob from 'shared/render-job'
import { OverflowScroll } from "shared/oferflow-scroll";

const JobsPanel = (props) => {
  const { jobs, jobForCallback } = props;
  return (
    <div>
      {
        jobs.length > 0 &&
        <Well>
          <h3 className="margin-top-0">Select job to create a callback</h3>
          <OverflowScroll>
            {jobs.map(job => {
              return (
                <RenderJob key={job.id} job={job}>
                  <ButtonToolbar>
                    <button className="btn btn-default" type="button"
                            onClick={() => jobForCallback(job)} >
                      Choose job for callback
                    </button>
                  </ButtonToolbar>
                </RenderJob>
              )
            })}
          </OverflowScroll>
        </Well>
      }
    </div>
  )
}

JobsPanel.propTypes = {
  jobs: PropTypes.array.isRequired
};

export default JobsPanel;
