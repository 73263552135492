import {
  ACTIVITY_LOG_REQUEST,
  ACTIVITY_LOG_REQUEST_SUCCESS,
  ACTIVITY_LOG_REQUEST_FAILURE,
  ACTIVITY_LOG_SCROLL_PAGINATION
} from "actions/activity-log-actions";

const INITIAL_STATE = { list: [], meta: {}, error: null, loading: false };

const ActivityLogReducer = (state = INITIAL_STATE, action) => {
  let error;
  switch(action.type) {
    case ACTIVITY_LOG_REQUEST:
      return {...state, error: null, loading: true};
    case ACTIVITY_LOG_REQUEST_SUCCESS:
      return {...state, list: action.payload.activity_log, meta: action.payload.meta, loading: false};
    case ACTIVITY_LOG_REQUEST_FAILURE:
      error = action.payload || {message: action.payload.message};
      return {...state, error: error, loading: false};
    case ACTIVITY_LOG_SCROLL_PAGINATION:
      return {
        ...state,
        list: state.list.concat(action.payload.activity_log),
        meta: action.payload.meta,
        loading: false};

    default:
      return state;
  }
};

export default ActivityLogReducer
