import { connect } from 'react-redux';

import NewJob from 'presentational/call-form/new-job-component';
import { resetNewJob, loadAddressToForm,
         jobForCallback, disableAddressInput
       } from 'actions/jobs-actions';
import { copyClientAddressToJob } from 'actions/addresses';
import { fetchClients } from "api/client";
import { addJob, addCallback } from "api/job";

const mapDispatchToProps = (dispatch) => {
  return {
    resetMe: () => {
      dispatch(resetNewJob());
    },
    loadAddress: (data, key) => {
      dispatch(loadAddressToForm(data, key));
    },
    copyClientAddressToJob: () => {
      dispatch(copyClientAddressToJob());
    },
    searchClients: (params, callback) => {
      dispatch(fetchClients(params, callback))
    },
    addJob: (data, callback) => {
      dispatch(addJob(data, callback))
    },
    addCallback: (data, callback) => {
      dispatch(addCallback(data, callback))
    },
    jobForCallback: (job) => {
      dispatch(jobForCallback(job))
    },
    disableAddressInput: (data) => {
      dispatch(disableAddressInput(data));
    }
  }
};


function mapStateToProps(state) {
  return {
    job: state.job,
    jobs: state.jobs
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewJob);
