export const validate = values => {
  const required = 'Required';
  const errors = {};
  if (!values.date_estimated) {
    errors.date_estimated = required
  }
  if (!values.note) {
    errors.note = required
  }
  return errors
};
