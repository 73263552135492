import React from 'react';
import { Field, FormSection } from 'redux-form';
import { RenderField, RenderAutocompleteInput } from './render-field'
import { Row, Col } from 'react-bootstrap'


export default class AddressInputs extends React.Component {
  constructor(props) {
    super(props);
    this.searchPlacePredictions = this.searchPlacePredictions.bind(this);
    this.predictionsCallback = this.predictionsCallback.bind(this);
    this.geolocate = this.geolocate.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.keyHandlers = this.keyHandlers.bind(this);
    this.state = {
      autocompleteItems: []
    };
    this.sectionName = `${this.props.type}_address`
  }

  componentDidMount() {
    this.mounted = true;
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.autocompleteOK = google.maps.places.PlacesServiceStatus.OK;
    this.geocodeOK = google.maps.GeocoderStatus.OK;
    this.geocoder = new google.maps.Geocoder()
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  searchPlacePredictions(e, val, oldValue, fieldName) {
    let value = e.target.value;
    if (value.length) {
      this.autocompleteService.getPlacePredictions({
        bounds: this.state.bounds,
        input: value,
        componentRestrictions: { country: 'us' }
      }, this.predictionsCallback)
      this.props.onFieldChange(e, val, oldValue, fieldName);
    } else {
      this.resetAutocomplete();
    }
  }

  predictionsCallback(predictions, status) {
    if (status !== this.autocompleteOK) {
      this.resetAutocomplete();
      console.log('Error loading auto complete places: ' + status);
      return
    }
    // transform snake_case to camelCase
    const formattedSuggestion = (structured_formatting) => ({
      mainText: structured_formatting.main_text,
      secondaryText: structured_formatting.secondary_text,
    });
    this.setState({
      autocompleteItems: predictions.map((p, i) => ({
        index: i,
        active: i === 0,
        suggestion: p.description,
        name: p.description,
        place_id: p.place_id,
        formattedSuggestion: formattedSuggestion(p.structured_formatting),
      }))
    })
  }

  resetAutocomplete() {
    if (!this.mounted) return;
    this.setState({
      autocompleteItems: []
    })
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        let circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        this.setState({bounds: circle.getBounds()});
      });
    }
  }

  geocodeCallback(result, status) {
    if (status !== this.geocodeOK) {
      this.resetAutocomplete();
      console.log('Error loading geocode result: ' + status);
      return
    }
    this.props.loadAddress(result[0], this.sectionName);
    this.props.onAddressSelect();
    this.resetAutocomplete();
  }

  geocodePlace(place) {
    this.geocoder.geocode({placeId: place.place_id}, (result, status) => this.geocodeCallback(result, status))
  }


  handleClick(item) {
    this.geocodePlace(item);
  }

  handleBlur() {
    setTimeout(() => {
      this.resetAutocomplete()
    }, 200)
  }

  getActivePlace() {
    let active = this.state.autocompleteItems.find(item => item.active);
    if (!active) active = this.state.autocompleteItems[0];
    return active
  }

  setActivePlace(index) {
    this.setState({
      autocompleteItems: this.state.autocompleteItems.map((item, i) => {
        if (i === index) {
          return { ...item, active: true }
        } else {
          return { ...item, active: false }
        }
      }),
    })
  }

  activeNext(direction) {
    let nextIndex, autocompleteItems = this.state.autocompleteItems;
    if (autocompleteItems.length === 0) {
      return
    }
    let activePlace = this.getActivePlace();
    if(direction === 'down') {
      nextIndex = (activePlace.index + 1) % autocompleteItems.length;
    } else if(direction === 'up') {
      nextIndex = activePlace.index === 0 ? autocompleteItems.length - 1 : (activePlace.index - 1) % autocompleteItems.length
    }
    this.setActivePlace(nextIndex)
  }

  handleEnterKey() {
    if(!this.state.autocompleteItems.length) return;
    this.geocodePlace(this.getActivePlace());
  }

  keyHandlers(event) {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.handleEnterKey();
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.activeNext('down');
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.activeNext('up');
        break;
      case 'Escape':
        this.resetAutocomplete();
        break
    }
  }

  eventHandlers() {
    return {
      onChange: this.searchPlacePredictions,
      onFocus: this.geolocate,
      onClick: this.handleClick,
      onBlur: this.handleBlur,
      onKeyDown: this.keyHandlers
    }
  }

  render() {
    const { disableFields, onFieldChange } = this.props;
    const inputHandlers = this.eventHandlers();
    const { autocompleteItems } = this.state;
    return (
      <FormSection name={this.sectionName}>
        <Row>
          <Col sm={7}>
            <Field label="Street" name='street'
                   disabled={disableFields}
                   component={RenderAutocompleteInput}
                   options={autocompleteItems}
                   {...inputHandlers} />
          </Col>
          <Col sm={5}>
            { this.props.children }
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              label="City"
              name='city'
              disabled={disableFields}
              component={RenderField}
              type="text"
              onChange={onFieldChange}
             />
          </Col>
          <Col sm={4}>
            <Field
              label="State"
              name='state'
              disabled={disableFields}
              component={RenderField}
              type="text"
              onChange={onFieldChange}
            />
          </Col>
          <Col sm={4}>
            <Field
              label="Zip"
              name='zip'
              disabled={disableFields}
              component={RenderField}
              type="text"
              onChange={onFieldChange}
            />
          </Col>
        </Row>
      </FormSection>
    )
  }
}

AddressInputs.defaultProps = {
  onFieldChange: () => {},
  onAddressSelect: () => {}
};
