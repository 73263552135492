import React from "react";
import moment from 'moment';
import { Button, ButtonToolbar, Row, Col } from "react-bootstrap";
import Modal from "react-responsive-modal";
import { reduxForm, Field } from "redux-form";

import { USER_UNAVAILABLE_FORM_NAME } from "helpers/FormNames";
import { validate } from "form-validations/validation-user-not-available-form";
import { renderDatePicker, RenderTextArea } from "shared/render-field";

class UserUnavailable extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.state = {
      minEndDate: ''
    }
  }

  componentWillUnmount() {
    Modal.prototype.componentWillUnmount = () => {}
  }

  closeModal() {
    const { onCloseModal, reset } = this.props;
    reset();
    onCloseModal();
  }

  onStartDateChange(event, time) {
    if(time.isValid()) {
      this.setState({ minEndDate: time })
    }
  }

  render() {
    const { show, submitting, handleSubmit, openModal } = this.props;
    const { minEndDate } = this.state;
    return (
      <div>
        <Button onClick={openModal}>Not available</Button>
        <Modal
          closeOnEsc={false}
          closeOnOverlayClick={false}
          open={show}
          onClose={this.closeModal}
        >
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <h3 className="margin-top-0">Not available</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Field label="From" name="start_date"
                  onChange={this.onStartDateChange}
                  component={renderDatePicker}
                  shouldCloseOnSelect={true}
                  normalize={ (value) =>  moment(value) }
                  showTimeSelect
                  popperProps={{
                    popperClassName: "not-available-modal",
                    popperPlacement: "bottom-end"
                  }}
                 />
              </Col>
              <Col lg={6}>
                <Field label="To" name="end_date"
                  disablePrevious={minEndDate}
                  component={renderDatePicker}
                  shouldCloseOnSelect={true}
                  normalize={ (value) =>  moment(value) }
                  showTimeSelect
                  popperProps={{
                    popperClassName: "not-available-modal",
                    popperPlacement: "bottom-end"
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Field name="note" label="Note" rows="4" component={RenderTextArea} />
              </Col>
            </Row>
            <ButtonToolbar className="pull-right">
              <Button onClick={this.closeModal}>Close</Button>
              <button className="btn btn-primary" type="submit" disabled={submitting}>Save</button>
            </ButtonToolbar>
          </form>
        </Modal>
      </div>
    )
  }
}

UserUnavailable = reduxForm({
  form: USER_UNAVAILABLE_FORM_NAME,
  validate,
  shouldValidate: () => true
})(UserUnavailable);

export default UserUnavailable;
