import JobApi from 'api/JobApi'
import ClientApi from 'api/ClientApi'
import CallbackApi from 'api/CallbackApi'
import { redirectTo, jobPath } from "helpers/PathHelpers";
import { closeCallbackParams } from "helpers/Callback";

export const CALLBACKS_REQUEST = 'CALLBACKS_REQUEST';
export const CALLBACKS_REQUEST_SUCCESS = 'CALLBACKS_REQUEST_SUCCESS';
export const CALLBACKS_REQUEST_FAILURE = 'CALLBACKS_REQUEST_FAILURE';

export const CALLBACK_TO_JOB_REQUEST = 'CALLBACK_TO_JOB_REQUEST';
export const CALLBACK_TO_JOB_REQUEST_SUCCESS = 'CALLBACK_TO_JOB_REQUEST_SUCCESS';
export const CALLBACK_TO_JOB_REQUEST_FAILURE = 'CALLBACK_TO_JOB_REQUEST_FAILURE';

export const CALLBACK_CLOSE_REQUEST = 'CALLBACK_CLOSE_REQUEST';
export const CALLBACK_CLOSE_REQUEST_SUCCESS = 'CALLBACK_CLOSE_REQUEST_SUCCESS';
export const CALLBACK_CLOSE_REQUEST_FAILURE = 'CALLBACK_CLOSE_REQUEST_FAILURE';

export const callbacksRequest = () => {
  return {
    type: CALLBACKS_REQUEST
  }
};

export const callbacksRequestSuccess = (todos) => {
  return {
    type: CALLBACKS_REQUEST_SUCCESS,
    payload: todos
  }
};

export const callbacksRequestFailure = (error) => {
  return {
    type: CALLBACKS_REQUEST_FAILURE,
    payload: error
  }
};

export const callbackToJobRequest = () => {
  return {
    type: CALLBACK_TO_JOB_REQUEST
  }
};

export const callbackToJobRequestSuccess = (todos) => {
  return {
    type: CALLBACK_TO_JOB_REQUEST_SUCCESS,
    payload: todos
  }
};

export const callbackToJobRequestFailure = (error) => {
  return {
    type: CALLBACK_TO_JOB_REQUEST_FAILURE,
    payload: error
  }
};

export const closeCallbackRequest = () => {
  return {
    type: CALLBACK_CLOSE_REQUEST
  }
};

export const closeCallbackRequestSuccess = (callback) => {
  return {
    type: CALLBACK_CLOSE_REQUEST_SUCCESS,
    payload: callback
  }
};

export const closeCallbackRequestFailure = (error) => {
  return {
    type: CALLBACK_CLOSE_REQUEST_FAILURE,
    payload: error
  }
};

const jobApi = new JobApi();
const clientApi = new ClientApi();
const callbackApi = new CallbackApi();

export const fetchJobCallbacks = (job_id) => {
  return callBackRequestHandler(jobApi.callbacks(job_id))
};

export const fetchClientCallbacks = (client_id) => {
  return callBackRequestHandler(clientApi.callbacks(client_id))
};

const callBackRequestHandler = (request) => {
  return (dispatch) => {
    dispatch(callbacksRequest());
    return request
      .done((data) => {
        dispatch(callbacksRequestSuccess(data));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(callbacksRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
}

export const convertCallbackToJob = (callbackId) => {
  return (dispatch) => {
    dispatch(callbackToJobRequest());
    return jobApi.convertCallbackToJob(callbackId)
      .done(function(data) {
        dispatch(callbackToJobRequestSuccess(data));
        redirectTo(jobPath(data.id))
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        dispatch(callbackToJobRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const closeCallback = (callbackParams) => {
  const dataToSend = closeCallbackParams(callbackParams);
  return (dispatch) => {
    dispatch(closeCallbackRequest());
    return callbackApi.closeCallback(dataToSend)
      .done((data) => {
        dispatch(closeCallbackRequestSuccess(data));
        redirectTo(jobPath(data.id));
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(closeCallbackRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};
