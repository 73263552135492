import { createStore, compose } from "redux"

import CallbackCloseReducers from "reducers/callback-close-reducers"
import configureMiddleware from "store/configure-middleware";

const configureCallbackCloseStore = preloadedState => {
  return createStore(
    CallbackCloseReducers,
    preloadedState,
    compose(
      configureMiddleware()
    )
  );
};

export default configureCallbackCloseStore
