import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";

import { OTHERS } from "helpers/AppConstants";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.setData = this.setData.bind(this);
    this.formatSeries = this.formatSeries.bind(this);
  }

  componentDidMount() {
    const { chartData } = this.props;
    this.renderChart();
    this.setData(chartData);
  }

  componentWillUpdate(nextProps) {
    const { chartData } = nextProps;
    if (chartData !== this.props.chartData) this.setData(chartData);
  }

  formatSeries(chartData) {
    const series = this.initialSeries();
    chartData.forEach((data) => {
      series[0].data.push(this.setSeria(data))
    });
    return series;
  }

  setSeria(data) {
    return {
      name: data.name || OTHERS,
      y: data.count
    }
  }

  initialSeries() {
    return  [{
      name: 'Jobs',
      data: []
    }]
  }

  setData(chartData) {
    this.chart.update({
      series: this.formatSeries(chartData)
    })
  }

  renderChart() {
    const { title } = this.props;
    this.chart =  Highcharts.chart(this.refs.chart, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: title
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            },
            connectorColor: 'silver'
          }
        }
      },
      series: this.initialSeries()
    });
  }

  render() {
    return (
      <div ref="chart" />
    )
  }
}

PieChart.defaultProps = {
  chartData: []
};

PieChart.propTypes = {
  chartData: PropTypes.array,
  title: PropTypes.string
};

export default PieChart;
