import { dateToMilliseconds, newDateFromString } from "helpers/DateTimeHelpers";

export const validate = values => {
  const required = 'Required';
  const errors = {};
  if (!values.start_date) {
    errors.start_date = required
  }
  if (!values.end_date) {
    errors.end_date = required
  }
  if (values.start_date && values.end_date) {
    const start = dateToMilliseconds(newDateFromString(values.start_date));
    const end = dateToMilliseconds(newDateFromString(values.end_date));
    if (end < start) errors.end_date = "End date invalid";
  }
  if (!values.note) {
    errors.note = required
  }
  return errors
};
