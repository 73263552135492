import React from "react";
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { validate } from 'form-validations/validation-todo-form';
import { TODO_FORM_NAME } from "helpers/FormNames";
import TodoInputFields from './todo-input-fields'

class TodoForm extends React.Component {
  render() {
    const { submitting, handleSubmit, users } = this.props;
    return (
      <form onSubmit={handleSubmit} autoComplete="off" className="note-form">
        <TodoInputFields users={users} isJobPage>
          <button className="btn btn-success margin-top-15" type="submit" disabled={submitting}>Add a todo</button>
        </TodoInputFields>
      </form>
    )
  }
}

TodoForm = reduxForm({
  form: TODO_FORM_NAME,
  validate,
  enableReinitialize: true
})(TodoForm);

TodoForm = connect(
  state => ({
    initialValues: {
      user_id: state.job.data.user && state.job.data.user.id
    },
  }))(TodoForm);

TodoForm.defaultProps = {
  users: []
};

TodoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

export default TodoForm
