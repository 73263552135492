import { urlWithParams } from "helpers/PathHelpers";
import { formatDateToISO } from "helpers/DateTimeHelpers";
import { updateCalendar, resetUpdateCalendar } from '../actions/calendar-actions';
import {
  estimatesRequest, estimatesRequestSuccess,
  estimatesRequestFailure, allEstimatesRequestSuccess
} from '../actions/estimates-actions';
import { jobUpdateRequest } from "./job";
import { formatHours } from "helpers/DateTimeHelpers";
import { ALL_ESTIMATES } from "helpers/AppConstants";
import JobApi from './JobApi'

const jobApi = new JobApi();

export const fetchEstimates = (searchParams={}) => {
  return (dispatch) => {
    dispatch(estimatesRequest(searchParams));
    return jobApi.estimates(searchParams)
      .done((data) => {
        dispatch(estimatesRequestSuccess(data));
        if (searchParams.type === ALL_ESTIMATES) {
          dispatch(allEstimatesRequestSuccess(data));
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        dispatch(estimatesRequestFailure(textStatus + ': ' + errorThrown));
        console.log(textStatus + ': ' + errorThrown);
      });
  }
};

export const updateCalendarIfEstimatesUpdated = (searchParams={}) => {
  return (dispatch, getState) => {
    dispatch(resetUpdateCalendar());
    return jobApi.estimates(searchParams)
      .done((data) => {
        const stateEstimates = JSON.stringify(getState().estimates.all);
        const fetchedEstimates = JSON.stringify(data.jobs);
        if (stateEstimates !== fetchedEstimates) {
          dispatch(updateCalendar());
          dispatch(allEstimatesRequestSuccess(data));
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        console.log(textStatus + ': ' + errorThrown);
      });
  }
}

export const assignEstimator = (data, callback) => {
  let dataToSend = {
    id: data.estimate_id,
    user_id: data.estimator,
    appointment_date: formatDateToISO(data.appointmentDate),
    time_from: formatHours(data.timeFrom),
    time_to: formatHours(data.timeTo),
  };
  return jobUpdateRequest(jobApi.assignEstimator(dataToSend), callback )
};

export const unassignEstimator = (data, callback) => {
  let dataToSend = {
    id: data.estimate_id,
    bounce_back_message: data.message
  };
  return jobUpdateRequest(jobApi.unassignEstimator(dataToSend), callback)
};

export const setAppointment = (data, callback) => {
  let dataToSend = {
    id: data.estimate_id,
    appointment_date: formatDateToISO(data.appointmentDate),
    time_from: formatHours(data.timeFrom),
    time_to: formatHours(data.timeTo),
  };
  return jobUpdateRequest(jobApi.addAppointment(dataToSend), callback)
};
