import { createStore, compose } from "redux"

import ClientsListReducer from "reducers/clients-reducer"
import configureMiddleware from "store/configure-middleware";

const configureClientsListStore = preloadedState => {
  return createStore(
    ClientsListReducer,
    preloadedState,
    compose(
      configureMiddleware()
    )
  );
};

export default configureClientsListStore
