import ApiClient from "api/ApiClient"
const apiClient = new ApiClient({});

export const URL_NEW_LINE_SIGN = '%0D%0A';

export const redirectTo = (path) => {
  window.location.href = path;
};

export const parseSearchParams = () => {
  const searchQuery = window.location.search;
  const params = {};
  if (!searchQuery.length) return params;
  searchQuery.substring(1).split('&').forEach((query) => {
    const keyValue = query.split('=');
    params[keyValue[0]] = decodeURIComponent(keyValue[1]);
  });
  return params;
};

export const jobPath = (id) => `/jobs/${id}`;
export const callbackClosePath = (id) => `/jobs/${id}/close-callback`;
export const clientPath = (id) => `/clients/${id}`;
export const calendarPath = () => "/calendar";
export const jobsPath = (params) => {
  return apiClient.addParamsToUrl("/jobs", params);
};

export const googleCalendarEventEditPath = (text, date, location, details) => {
  return `https://calendar.google.com/calendar/r/eventedit?text=${text}&date=${date}Z/&location=${location}&details=${details}&sf=true`;
};

export const googleMapsPlacePath = () => {
  return 'http://www.google.com/maps/place';
};

export const convertStringAsUrlPart = (addressString) => {
  return addressString.replace(/\ /g,'+');
};
