class ApiClient {
  constructor({url, dataWrapperKey, apiVersion = 1}) {
    this.apiVersion = apiVersion;
    this.url = url;
    this.dataWrapperKey = dataWrapperKey;
  }

  index(params = {}, url = this.url) {
    let fullUrl = this.addParamsToUrl(this.setUrl(url), params);
    return $.ajax(fullUrl)
  }

  show(id, url = this.url) {
    return $.ajax(`${this.setUrl(url)}/${id}`)
  }

  create(data) {
    return $.ajax({
      method: 'POST',
      url: this.setUrl(),
      data: this.wrapData(data)
    })
  }

  update(data) {
    return $.ajax({
      method: 'PUT',
      url: `${this.setUrl()}/${data.id}`,
      data: this.wrapData(data)
    })
  }

  getNestedAction(id, nestedUrl) {
    return $.ajax(`${this.setUrl()}/${id}${nestedUrl}`)
  }

  putNestedAction(nestedUrl, data) {
    return $.ajax({
      method: 'PUT',
      url: `${this.setUrl()}/${data.id}${nestedUrl}`,
      data: this.wrapData(data)
    })
  }

  postNestedAction(nestedUrl, data, wrapperKey) {
    return $.ajax({
      method: 'POST',
      url: `${this.setUrl()}/${data.id}${nestedUrl}`,
      data: this.wrapData(data, wrapperKey)
    })
  }

  addParamsToUrl(url, paramsObj) {
    let params = Object.keys(paramsObj)
      .map(k => paramsObj[k] && k + '=' + paramsObj[k])
      .filter(item => item).join('&');
    return params.length > 0 ? `${url}?${params}`: url
  };

  setUrl(url = this.url) {
    return `/api/v${this.apiVersion}/${url}`
  }

  wrapData(data, wrapperKey=this.dataWrapperKey) {
    if (!wrapperKey) return data;
    const wrappedData = {};
    wrappedData[wrapperKey] = data;
    return wrappedData
  }
}

export default ApiClient;
