import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap'

import { jobPath, clientPath } from "helpers/PathHelpers";

class CallbackCloseBreadcrumb extends React.Component {
  render() {
    const { job } = this.props;
    return (
      <Breadcrumb>
        <Breadcrumb.Item href="/clients">
          Clients
        </Breadcrumb.Item>
        <Breadcrumb.Item href={clientPath(job.client && job.client.id)}>
          { job.client && job.client.name }
        </Breadcrumb.Item>
        <Breadcrumb.Item href={jobPath(job.job_id)}>
          Job
        </Breadcrumb.Item>
        <Breadcrumb.Item href={jobPath(job.id)}>
          Callback
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
         Close a callback
        </Breadcrumb.Item>
      </Breadcrumb>
    )
  }
}

CallbackCloseBreadcrumb.propTypes = {
  job: PropTypes.object
};

export default CallbackCloseBreadcrumb

