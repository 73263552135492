import { CAN_ASSIGN_ESTIMATOR } from "helpers/Permissions";

const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const currentUser =() => {
  return JSON.parse(atob(getCookie('user')))
};

export const assignedToCurrentUser = (responsibleId) => {
  return currentUser().id === responsibleId;
};

export const estimators = (users) => {
  const filtered = users.filter(user =>  user.estimator);
  if (CAN_ASSIGN_ESTIMATOR) return filtered;
  return filtered.map(user => {
    return {...user, disabled: user.id !== currentUser().id}
  });
};
