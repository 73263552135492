import React from "react";
import { Provider } from "react-redux";
import { Route, HashRouter } from 'react-router-dom'

import configureClientInfoStore from "app/store/configureClientInfoStore";
import ClientInfoComponent from "app/containers/ClientInfoContainer";
import CallForm from 'app/containers/CallFormContainer';

const store = configureClientInfoStore();

class ClientInfo extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <div>
            <Route exact path="/" render={(props)=><ClientInfoComponent {...props} clientFromProps={this.props.client}
                                                                        clientOptions={this.props.client_options}/>} />
            <Route path="/new_job" render={(props)=><CallForm {...props} jobOptions={this.props.job_options}
                                                              clientOptions={this.props.client_options}/>} />
          </div>
        </HashRouter>
      </Provider>
    )
  }
}
export default ClientInfo;
