export const validate = values => {
  const required = 'Required';

  const errors = {};
  if (!values.user_id) {
    errors.user_id = required
  }

  if (!values.due_date) {
    errors.due_date = required
  }

  if (!values.description) {
    errors.description = required
  }

  return errors
};