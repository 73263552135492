import ApiClient from 'api/ApiClient'

class CallbackApi extends ApiClient {
  constructor() {
    super({url:'jobs', dataWrapperKey: 'callback'})
  }
  closeCallback(params) {
    return super.postNestedAction('/close_callback', params)
  }
}

export default CallbackApi
