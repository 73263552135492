import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';

import EstimatesPanel from 'calendar/estimates-panel'
import Calendar from 'calendar/calendar'
import ActivityLogComponent from 'activity-log/activity-log-component';
import TodosComponent from 'todos/todos-component';
import {filterByAppointmentStatus, onlyAssignedEstimates} from 'helpers/Estimates';
import { JOB_STATUS_ASSIGNED } from 'helpers/AppConstants';
import { checkRelevantTime, formatTimeSlotsData } from 'helpers/Calendar';
import { scrollToElement, scrollToTop } from 'helpers/ScreenHelpers';
import { perfomAsync } from 'helpers/Async';
import { currentUser } from 'helpers/User';
import { DATE_TIME_FORMAT, ISO_DATE_TIME_FORMAT } from 'helpers/DateTimeHelpers';

class DashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extremeAppointmentDates: this.extremeAppointmentDates(moment())
    };
    this.unassignEstimator = this.unassignEstimator.bind(this);
    this.setAppointment = this.setAppointment.bind(this);
    this.completeTodo = this.completeTodo.bind(this);
    this.onSelectSlot = this.onSelectSlot.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.fetchActivityLog = this.fetchActivityLog.bind(this);
    this.infiniteActivityLogScroll = this.infiniteActivityLogScroll.bind(this);
    this.shouldFetchActivityLog = this.shouldFetchActivityLog.bind(this);
    this.setExtremeAppointmentDates = this.setExtremeAppointmentDates.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
  }

  componentWillMount() {
    const { fetchTodos, fetchUsers, fetchEstimates, fetchUserNotAvailables } = this.props;
    fetchEstimates({ type: currentUser().id,
                     estimated: true,
                     start_date: this.state.extremeAppointmentDates.startDate,
                     end_date: this.state.extremeAppointmentDates.endDate });
    this.fetchActivityLog();
    fetchTodos();
    fetchUsers();
    fetchUserNotAvailables();
  }

  extremeAppointmentDates(date) {
    return {
      startDate: moment(date).subtract(1,'months').startOf('month').format(ISO_DATE_TIME_FORMAT),
      endDate: moment(date).add(1,'months').endOf('month').format(ISO_DATE_TIME_FORMAT)
    }
  }

  fetchActivityLog() {
    const { fetchActivityLog } = this.props;
    fetchActivityLog();
  }

  unassignEstimator(data, callback) {
    const { unassignEstimator } = this.props;
    const callbacks = () => {
      callback();
      this.fetchActivityLog();
    };
    unassignEstimator(data, callbacks);
  }

  setAppointment(data, callback) {
    const { setAppointment, clearTimeSlotsData , resetSelectedEvent } = this.props;
    const callbacks = () => {
      if (typeof callback === 'function') callback();
      clearTimeSlotsData();
      resetSelectedEvent();
      this.fetchActivityLog();
    };
    setAppointment(data, callbacks);
  }

  completeTodo(data) {
    const { completeTodo } = this.props;
    completeTodo(data, this.fetchActivityLog);
  }

  onSelectSlot(data) {
    const { resetSelectedEstimate, selectTimeSlots, estimates } = this.props;
    let formData = formatTimeSlotsData(data);
    let filteredEstimates = onlyAssignedEstimates(estimates.list);
    if (checkRelevantTime(formData) && filterByAppointmentStatus(filteredEstimates, JOB_STATUS_ASSIGNED).length) {
      scrollToTop();
      resetSelectedEstimate();
      selectTimeSlots(formData);
    }
  }

  onSelectEvent(event) {
    const { selectEstimate, selectEvent } = this.props;
    selectEvent(event);
    selectEstimate(event);
    perfomAsync(() => scrollToElement(`estimate_${event.id}`));
  }

  infiniteActivityLogScroll(page) {
    const { fetchActivityLog, activityLog } = this.props;
    const offsetStartId = this.shouldFetchActivityLog(activityLog);
    if (offsetStartId) fetchActivityLog(offsetStartId);
  }

  shouldFetchActivityLog(activityLog) {
    const logLength = activityLog.list.length;
    if (activityLog.loading || !logLength) return false;
    return activityLog.list[logLength - 1].event_id;
  }

  setExtremeAppointmentDates(date) {
    const extremeAppointmentDates = this.extremeAppointmentDates(date);

    return new Promise((resolve, reject) => {
      this.setState({
        extremeAppointmentDates: {
          startDate: extremeAppointmentDates.startDate,
          endDate: extremeAppointmentDates.endDate
        }
      });
      resolve();
    });
  }

  onNavigate(date) {
    this.props.fetchUserNotAvailables(moment(date).format(DATE_TIME_FORMAT));
    this.setExtremeAppointmentDates(date).then(() => {
      this.props.fetchEstimates({ type: currentUser().id,
                                  estimated: true,
                                  start_date: this.state.extremeAppointmentDates.startDate,
                                  end_date: this.state.extremeAppointmentDates.endDate });
    });
  }

  render() {
    const { activityLog, estimates, userUnavailable, showUnavailableDateModal,
      closeRemoveUnavailableDateModal, openRemoveUnavailableDateModal, event, removeUnavailableDate,
      openJobDetailsModal, showJobDetailsModal, closeJobDetailsModal
    } = this.props;
    const filteredEstimates = onlyAssignedEstimates(estimates.list);
    return (
      <div>
        <Row>
          <Col md={12}>
            <h2 className="col-md-6 page-title">Dashboard</h2>
          </Col>
        </Row>
        <Row className="dashboard-panel-container">
          <Col md={3} className="estimates-container">
            <EstimatesPanel
              {...this.props}
              unassignEstimator={this.unassignEstimator}
              setAppointment={this.setAppointment}
              estimates={filteredEstimates}
              title={'My'}
              selectedEstimate={estimates.selected}
            />
          </Col>
          <Col md={6} className="calendar-container">
            <Calendar
              removeUnavailableDate={removeUnavailableDate}
              openRemoveUnavailableDateModal={openRemoveUnavailableDateModal}
              showUnavailableDateModal={showUnavailableDateModal}
              closeRemoveUnavailableDateModal={closeRemoveUnavailableDateModal}
              openJobDetailsModal={openJobDetailsModal}
              showJobDetailsModal={showJobDetailsModal}
              closeJobDetailsModal={closeJobDetailsModal}
              event={event}
              estimates={filteredEstimates}
              onSelectSlot={this.onSelectSlot}
              onSelectEvent={this.onSelectEvent}
              notAvailableList={userUnavailable.list}
              onNavigate={this.onNavigate}
            />
          </Col>
          <Col md={3} className="todos-container">
            <Tabs defaultActiveKey={3} id="dashboard-tabs" mountOnEnter>
              <Tab eventKey={3} title="Todos">
                <TodosComponent {...this.props} completeTodo={this.completeTodo} />
              </Tab>
              <Tab eventKey={1} title="Activity log">
                <ActivityLogComponent canShowClientLink activityLog={activityLog} onScroll={this.infiniteActivityLogScroll}/>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    )
  }
}

DashboardComponent.propTypes = {
  fetchActivityLog: PropTypes.func.isRequired,
  fetchEstimates: PropTypes.func.isRequired,
  activityLog: PropTypes.object.isRequired,
  todos: PropTypes.object.isRequired,
  estimates: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  focusUnassigned: PropTypes.bool.isRequired,
  timeSlotsData: PropTypes.object.isRequired,
  selectedEvent: PropTypes.object.isRequired
};

export default DashboardComponent;
