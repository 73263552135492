export const validate = values => {
  const required = 'Required';
  const errors = {};
  if (!values.note_type) {
    errors.note_type = required
  }
  if (!values.note) {
    errors.note = required
  }
  return errors
};