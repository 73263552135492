export const validate = values => {
  const required = 'Required';
  const errors = {};
  if (!values.estimator) {
    errors.estimator = required
  }
  if (!values.note) {
    errors.note = required
  }
  return errors
};
