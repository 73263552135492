import { INITIALIZE_SEARCH_FORM, SET_SORT_PARAMS } from "actions/search-actions";

const INITIAL_STATE = { initialParams: {}, sort: {} };

export const SearchReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case INITIALIZE_SEARCH_FORM:
      return {...state, initialParams: action.payload };
    case SET_SORT_PARAMS:
      return {...state, sort: action.payload };

    default:
      return state;
  }
};

